import React, { useState, useEffect } from "react";
import styles from "./ConsentMinorParticipantView.module.css";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import SignatureImageView from "../../../../components/SignatureImageView";
import { useTranslation } from "react-i18next";

const ConsentMinorParticipantView = () => {
  const [t] = useTranslation("common");
  const { participantIlliterate, currentCountry } = useSelector(
    (state) => state.settings
  );
  const enrolmentState = useSelector((state) => state.enrolment);
  const [participantConsentObj, setParticipantConsentObj] = useState({});

  useEffect(() => {
    setParticipantConsentObj({
      participantFirstName: enrolmentState.consent_participant_first_name,
      participantLastName: enrolmentState.consent_participant_last_name,
      consentDate: enrolmentState.consent_participant_date,
      consentTime: enrolmentState.consent_participant_time,
      signature: enrolmentState.consent_participant_signature,
    });
  }, [enrolmentState]);

  const getSouthAfricaContent = () => (
    <>
      <h2 className={styles.intro}>{t("minor_consent_title")}</h2>

      <div className={styles.consentText}>
        <ol>
          <li>{t("minor_assent_b1")}</li>
          <li>{t("minor_assent_b2")}</li>
          <li>{t("minor_assent_b3")}</li>
          <li>{t("minor_assent_b4")}</li>
          <li>{t("minor_assent_b5")}</li>
          <li>{t("minor_assent_b6")}</li>
          <li>{t("minor_assent_b7")}</li>
          <li>{t("minor_assent_b8")}</li>
          <li>{t("minor_assent_b9")}</li>
          <li>{t("minor_assent_b10")}</li>
        </ol>
        <p>{t("minor_assent_b11")}</p>
      </div>
    </>
  );

  const getZambiaContent = () => (
    <>
      <h2 className={styles.intro}>{t("minor_consent_title")}</h2>

      <div className={styles.consentText}>
        <ol>
          <li>{t("minor_assent_b1")}</li>
          <li>{t("minor_assent_b2")}</li>
          <li>{t("minor_assent_b3")}</li>
          <li>{t("minor_assent_b4")}</li>
          <li>{t("minor_assent_b5")}</li>
        </ol>
        <p>
          <strong>{t("minor_assent_p1")}</strong>
        </p>

        <ol>
          <li>
            {t("minor_assent1")}{" "}
            <strong>({enrolmentState.participant_consents.consent1})</strong>
          </li>
          <li>
            {t("minor_assent2")}{" "}
            <strong>({enrolmentState.participant_consents.consent2})</strong>
          </li>
          <li>
            {t("minor_assent3")}{" "}
            <strong>({enrolmentState.participant_consents.consent3})</strong>
          </li>
          <li>
            {t("minor_assent4")}{" "}
            <strong>({enrolmentState.participant_consents.consent4})</strong>
          </li>
          <li>
            {t("minor_assent5")}{" "}
            <strong>({enrolmentState.participant_consents.consent5})</strong>
          </li>
        </ol>

        <p>{t("minor_assent_b6")}</p>
      </div>
    </>
  );

  return (
    <div className={styles.pageViewBox}>
      {currentCountry === "ZA" && getSouthAfricaContent()}
      {currentCountry === "ZM" && getZambiaContent()}

      <div className={styles.consentForm}>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_participant_first_name")} </label>
                <div className={styles.fieldText}>
                  {participantConsentObj.participantFirstName}
                </div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_participant_last_name")} </label>
                <div className={styles.fieldText}>
                  {participantConsentObj.participantLastName}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>
                  {t("label_participant")}{" "}
                  {participantIlliterate
                    ? t("label_acknowledgement")
                    : t("label_signature")}{" "}
                </label>
                <SignatureImageView
                  signature={participantConsentObj.signature}
                />
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_date")}</label>
                <div className={styles.fieldText}>
                  {participantConsentObj.consentDate}
                </div>
              </FormControl>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_time")}</label>
                <div className={styles.fieldText}>
                  {participantConsentObj.consentTime}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default ConsentMinorParticipantView;
