import { API, graphqlOperation } from "aws-amplify";
import {
  createSubStudyFGDA3,
  updateSubStudyFGDA3Participant,
  updateSubStudyFGDA3Guardian,
  updateEnrolmentStatus,
} from "../../graphql/mutations";
import { getSubStudyFGDA3 } from "../../graphql/queries";

export const apiCreateFGDA3 = async (participantId) => {
  let results = { success: true, body: {}, message: "" };

  const enrolmentData = {
    PK: `PARTICIPANT#${participantId}`,
    consent_country: localStorage.getItem("i18nextLng").split("_")[1],
  };

  try {
    const response = await API.graphql(
      graphqlOperation(createSubStudyFGDA3, {
        ConsentWithAudioTaping: enrolmentData,
      })
    );

    results.body = response.data.createSubStudyFGDA3;
  } catch (err) {
    results.success = false;
    results.message = "Error creating new record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateFGDA3Status = async (
  participantId,
  enrolementId,
  recordStatus
) => {
  let results = { success: true, body: {}, message: "" };

  const enrolmentData = {
    PK: `PARTICIPANT#${participantId}`,
    SK: `FGDA3#${enrolementId}`,
    record_status: recordStatus,
    consent_country: localStorage.getItem("i18nextLng").split("_")[1],
  };

  try {
    const response = await API.graphql(
      graphqlOperation(updateEnrolmentStatus, {
        ConsentWithLongStorage: enrolmentData,
      })
    );

    results.body = response.data.updateEnrolmentStatus;
  } catch (err) {
    results.success = false;
    results.message = "Error saving record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateFGDA3Participant = async (participantData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateSubStudyFGDA3Participant, {
        ConsentWithAudioTaping: participantData,
      })
    );

    results.body = response.data.updateSubStudyFGDA3Participant;
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateFGDA3Guardian = async (guardianData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateSubStudyFGDA3Guardian, {
        ConsentWithAudioTaping: guardianData,
      })
    );

    results.body = response.data.updateSubStudyFGDA3Guardian;
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiGetFGDA3 = async (partcipantId, enrolmentId) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyFGDA3, {
        PK: `PARTICIPANT#${partcipantId}`,
        SK: `ENROLMENT#${enrolmentId}`,
      })
    );

    results.body = response.data.getSubStudyFGDA3;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};
