import React from "react";
import styles from "./ConsentImpartialWitnessView.module.css";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

const ConsentImpartialWitnessView = ({ impartialWitnessObj, pageName }) => {
  const [t] = useTranslation("common");

  return (
    <>
      <div className={styles.pageViewBox}>
        {pageName === "MINOR_PARENT_CONSENT" && (
          <>
            <h3 className={styles.intro}>
              {t("minor_parent_impartial_witness_title")}
            </h3>
            <p>{t("minor_parent_impartial_witness_p1")}</p>
          </>
        )}
        {pageName === "MINOR_PARENT_LONG_STORAGE" && (
          <>
            <h3 className={styles.intro}>
              {t("minor_parent_impartial_witness_title")}
            </h3>
            <p>{t("minor_parent_impartial_witness_p1")}</p>
          </>
        )}
        {pageName === "MINOR_PARENT_GEN_TEST" && (
          <>
            <h3 className={styles.intro}>
              {t("minor_parent_impartial_witness_title")}
            </h3>
            <p>{t("GT_minor_parent_impartial_witness")}</p>
          </>
        )}
        {pageName === "MINOR_PARTICIPANT_CONSENT" && (
          <>
            <h3 className={styles.intro}>
              {t("minor_impartial_witness_title")}
            </h3>
            <p>{t("minor_impartial_witness_p1")}</p>
          </>
        )}

        {pageName === "MINOR_PARTICIPANT_LONG_STORAGE" && (
          <>
            <h3 className={styles.intro}>
              {t("minor_impartial_witness_title")}
            </h3>
            <p>{t("minor_impartial_witness_p1")}</p>
          </>
        )}

        {pageName === "MINOR_PARTICIPANT_GEN_TEST" && (
          <>
            <h3 className={styles.intro}>
              {t("minor_impartial_witness_title")}
            </h3>
            <p>{t("GT_minor_participant_impartial_witness")}</p>
          </>
        )}

        {(pageName === "EMANCIPATED_MINOR_CONSENT" ||
          pageName === "ADULT_CONSENT") && (
          <>
            <h3 className={styles.intro}>
              {t("participant_impartial_witness_title")}
            </h3>
            <p>{t("participant_impartial_witness_p1")}</p>
          </>
        )}

        {(pageName === "EMANCIPATED_MINOR_LONG_STORAGE" ||
          pageName === "ADULT_LONG_STORAGE") && (
          <>
            <h3 className={styles.intro}>
              {t("participant_impartial_witness_title")}
            </h3>
            <p>{t("participant_impartial_witness_p1")}</p>
          </>
        )}

        {(pageName === "EMANCIPATED_MINOR_GEN_TEST" ||
          pageName === "ADULT_GEN_TEST") && (
          <>
            <h3 className={styles.intro}>
              {t("participant_impartial_witness_title")}
            </h3>
            <p>{t("GT_adult_impartial_witness")}</p>
          </>
        )}

        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_impartial_witness_first_name")} </label>
                <div className={styles.fieldText}>
                  {impartialWitnessObj.witnessFirstName}
                </div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_impartial_witness_last_name")}</label>
                <div className={styles.fieldText}>
                  {impartialWitnessObj.witnessLastName}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_impartial_witness_signature")} </label>
                <div className={styles.signatureImageBox}>
                  <img
                    width="100%"
                    src={impartialWitnessObj.witnessSignature}
                    alt="Signature box"
                  />
                </div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_date")}</label>
                <div className={styles.fieldText}>
                  {impartialWitnessObj.consentDate}
                </div>
              </FormControl>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_date")}</label>
                <div className={styles.fieldText}>
                  {impartialWitnessObj.consentTime}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default ConsentImpartialWitnessView;
