import React, { useState, useEffect, useMemo } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import styles from "./NewEnrolment.module.css";
import PreLoader from "../../components/common/PreLoader";
import SelectAgeCategory from "./SelectAgeCategory";
import ConsentForms from "./ConsentForms";
import { useTranslation } from "react-i18next";
import SelectLanguage from "./SelectLanguage";
import CheckParentLiteracy from "./CheckParentLiteracy";
import ParticipantLiteracy from "./ParticipantLiteracy";
import FormControlPage from "./FormControlPage";
import { setEnrolmentForms } from "../../store/slices/settingsSlice";
import { mainStudyFormsData } from "../../data/main_study_consent_forms";
import { useDispatch } from "react-redux";
import {
  setAppTitle,
  setAgeCatsList,
  setStudyName,
} from "../../store/slices/settingsSlice";
import { format } from "date-fns";
import AssessmentOU from "./AssessmentOU";
import { setEnrolmentDateTime } from "../../store/slices/enrolmentSlice";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { mainStudyAgeCatsList } from "../../data/age_cats_data";

const NewEnrolment = () => {
  const [t] = useTranslation("common");
  const [Loading] = useState(false);
  const { loggedInUser, isReconsent } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const history = useHistory();
  /**
   * 1 - Enrolment - Select Age Category
   */
  const [enrolmentStep, setEnrolmentStep] = useState(1);

  const changeEnrolementStep = (step) => {
    setEnrolmentStep(step);
  };

  useMemo(() => {
    if (loggedInUser === null) {
      history.push({ pathname: "/login" });
    }
  }, []);

  useEffect(() => {
    dispatch(setEnrolmentForms(mainStudyFormsData));
    dispatch(setAgeCatsList(mainStudyAgeCatsList));
    dispatch(setStudyName("MAIN_STUDY"));
    dispatch(setAppTitle(isReconsent ? t("reconsent") : t("new_enrolment")));
    dispatch(
      setEnrolmentDateTime({
        created_date: format(new Date(), "dd/MM/yyyy"),
        created_time: format(new Date(), "H:mm"),
      })
    );
  }, []);

  return (
    <>
      <AppHeader isGuestMode={false} heading={t("new_enrolment")} />
      {enrolmentStep === 1 && (
        <div className={styles.mainMenu}>
          <SelectLanguage changeEnrolementStep={changeEnrolementStep} />
        </div>
      )}

      {(enrolmentStep === 2 || enrolmentStep === 2) && (
        <div className={styles.mainMenu}>
          <SelectAgeCategory changeEnrolementStep={changeEnrolementStep} />
        </div>
      )}

      {enrolmentStep === 3 && (
        <CheckParentLiteracy changeEnrolementStep={changeEnrolementStep} />
      )}

      {enrolmentStep === 4 && (
        <ParticipantLiteracy changeEnrolementStep={changeEnrolementStep} />
      )}

      {enrolmentStep === 5 && (
        <FormControlPage changeEnrolementStep={changeEnrolementStep} />
      )}

      {enrolmentStep === 6 && (
        <ConsentForms changeEnrolementStep={changeEnrolementStep} />
      )}

      {enrolmentStep === 7 && (
        <AssessmentOU changeEnrolementStep={changeEnrolementStep} />
      )}

      <PreLoader show={Loading} />
    </>
  );
};

export default NewEnrolment;
