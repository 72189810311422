import React, { useEffect, useState } from "react";
import styles from "./AssessmentOU.module.css";
import PreLoader from "../../../components/common/PreLoader";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useDispatch, useSelector } from "react-redux";
import { setAppTitle } from "../../../store/slices/settingsSlice";
import {
  setQuestions,
  setCurrentQuestionIndex,
  setAssessmentCompleted,
  setNoOfAttempts,
  setAssessmentStartTime,
} from "../../../store/slices/aouSlice";
import assessmentOUData from "../../../data/assessment_ou_data";
import ProgressBar from "../../../components/common/ProgressBar";
import AouIncorrectAnswerBox from "./AouIncorrectAnswerBox";
import AouResults from "./AouResults";
import { useTranslation } from "react-i18next";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { StepButton } from "@mui/material";
import StepIntroduction from "../StepIntroduction";
import { setAuditInfo } from "../../../store/slices/settingsSlice";

// eslint-disable-next-line no-unused-vars
const AssessmentOU = ({ changeEnrolementStep }) => {
  const [steps] = useState([
    "Primary study information",
    "Long storage information",
    "Genetic testing information",
    "Assessment of understanding",
  ]);
  const [activeStep, setActiveStep] = useState(0);
  const [completed] = useState({});
  const [t] = useTranslation("common");
  const [Loading] = useState(false);
  const [resettedForMinor, setResettedForMinor] = useState(false);
  // const [activeStep] = useState(1);
  const dispatch = useDispatch();
  const { questions, noOfAttempts, assessmentCompleted, currentQuestionIndex } =
    useSelector((state) => state.aou);
  const { currentFormIndex, participantType, auditInfo } = useSelector(
    (state) => state.settings
  );
  const noOfQuestions = assessmentOUData.questions.length;

  const handleEndOfAssessment = () => {
    dispatch(setNoOfAttempts(noOfAttempts + 1));
    dispatch(setAssessmentCompleted(true));
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 0);
  };

  const continueNext = () => {
    let _questions = JSON.parse(JSON.stringify(questions));

    if (_questions[currentQuestionIndex].id <= noOfQuestions) {
      _questions[currentQuestionIndex].answeredCorrectly = false;
      _questions[currentQuestionIndex].isCurrent = false;
      _questions[currentQuestionIndex].isDone = true;

      if (_questions[currentQuestionIndex].id < noOfQuestions) {
        dispatch(setCurrentQuestionIndex(currentQuestionIndex + 1));
        _questions[currentQuestionIndex + 1].isCurrent = true;
      }

      dispatch(setQuestions(_questions));

      if (_questions[currentQuestionIndex].id === noOfQuestions) {
        handleEndOfAssessment();
      }
    }
  };

  const setAnswer = (userAnswer) => {
    const _questions = JSON.parse(JSON.stringify(questions));

    if (_questions[currentQuestionIndex].id <= noOfQuestions) {
      if (_questions[currentQuestionIndex].answer === userAnswer) {
        _questions[currentQuestionIndex].answeredCorrectly = true;
        _questions[currentQuestionIndex].isCurrent = false;
        _questions[currentQuestionIndex].isDone = true;

        if (_questions[currentQuestionIndex].id < noOfQuestions) {
          dispatch(setCurrentQuestionIndex(currentQuestionIndex + 1));
          _questions[currentQuestionIndex + 1].isCurrent = true;
        }
      } else {
        _questions[currentQuestionIndex].answeredCorrectly = false;
      }

      dispatch(setQuestions(_questions));
      if (_questions[currentQuestionIndex].id === noOfQuestions) {
        handleEndOfAssessment();
      }
    }
  };

  const resetQuestions = () => {
    let _questions = JSON.parse(
      JSON.stringify(assessmentOUData.questions)
    ).sort(() => Math.random() - 0.5);

    _questions.forEach((element, index) => {
      _questions[index].id = index + 1;
    });

    dispatch(setQuestions(_questions));
    dispatch(setCurrentQuestionIndex(0));
    dispatch(setAssessmentCompleted(false));

    if (
      currentFormIndex === 4 &&
      participantType === "MINOR_PARTICIPANT" &&
      resettedForMinor === false
    ) {
      setResettedForMinor(true);
      dispatch(setNoOfAttempts(0));
    }
  };

  useEffect(() => {
    dispatch(setAppTitle(t("assessment_of_understanding_test_title")));
    resetQuestions();
    dispatch(setAssessmentStartTime(new Date().toUTCString()));

    const _auditInfo = [...auditInfo];
    _auditInfo.push({
      user: "Participant",
      dateTime: new Date().toISOString(),
      info: "Entered assessment of understanding test."
    });
    dispatch(setAuditInfo(_auditInfo));
  }, []);

  const isTrueAnswerIncorrect =
    questions !== null &&
    questions[currentQuestionIndex].answer === false &&
    questions[currentQuestionIndex].answeredCorrectly === false;

  const isFalseAnswerIncorrect =
    questions !== null &&
    questions[currentQuestionIndex].answer === true &&
    questions[currentQuestionIndex].answeredCorrectly === false;

  const isIncorrectAnswer =
    questions !== null &&
    questions[currentQuestionIndex].answeredCorrectly === false;

  const getQuestionText = () => {
    const questionName =
      questions !== null ? questions[currentQuestionIndex].question : "";
    const _question_text = questions !== null ? t(questionName) : "";

    return _question_text;
  };

  return (
    <>
      <Container maxWidth="md">
        <>
          <div>
            <Box sx={{ width: "100%" }}>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{ margin: "0 0rem 0 0rem" }}
              >
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <div>
                {activeStep === 0 && (
                  <StepIntroduction
                    changeEnrolementStep={changeEnrolementStep}
                    handleNext={handleNext}
                    pageType={"MAIN_STUDY"}
                  />
                )}

                {activeStep === 1 && (
                  <StepIntroduction
                    changeEnrolementStep={changeEnrolementStep}
                    handleNext={handleNext}
                    pageType={"LONG_STORAGE"}
                  />
                )}

                {activeStep === 2 && (
                  <StepIntroduction
                    changeEnrolementStep={changeEnrolementStep}
                    handleNext={handleNext}
                    pageType={"GEN_TEST"}
                  />
                )}

                {activeStep === 3 && (
                  <>
                    {!assessmentCompleted && (
                      <>
                        <h2 className={styles.aouQuestionTitle}>
                          {t("label_question")} {currentQuestionIndex + 1}{" "}
                          {t("label_of")} {noOfQuestions}
                        </h2>
                        <ProgressBar value={(currentQuestionIndex + 1) * 10} />

                        <div className={styles.pageBox}>
                          <p className={styles.aouQuestion}>
                            {getQuestionText()}
                          </p>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              disabled={isIncorrectAnswer}
                              variant="outlined"
                              onClick={() => {
                                setAnswer(true);
                              }}
                              sx={{
                                mr: 1,
                                width: "130px",
                                fontWeight: "bold",
                                backgroundColor: isTrueAnswerIncorrect
                                  ? "#EF0000 !important"
                                  : null,
                                color: isTrueAnswerIncorrect
                                  ? "white !important"
                                  : null,
                              }}
                            >
                              {t("label_true")}
                            </Button>
                            <Button
                              disabled={isIncorrectAnswer}
                              variant="outlined"
                              onClick={() => {
                                setAnswer(false);
                              }}
                              sx={{
                                mr: 1,
                                width: "130px",
                                fontWeight: "bold",
                                backgroundColor: isFalseAnswerIncorrect
                                  ? "#EF0000 !important"
                                  : null,
                                color: isFalseAnswerIncorrect
                                  ? "white !important"
                                  : null,
                              }}
                              data-testid="go_next"
                            >
                              {t("label_false")}
                            </Button>
                          </Box>
                          {isIncorrectAnswer && (
                            <h2 className={styles.aouIncorrectAnswerTitle}>
                              {t("label_incorrect_answer")}
                            </h2>
                          )}
                        </div>

                        {isIncorrectAnswer && (
                          <AouIncorrectAnswerBox
                            correctAnswerText={
                              questions !== null
                                ? questions[currentQuestionIndex].correctAnswerText : ""
                            }
                            continueNext={continueNext}
                          />
                        )}
                      </>
                    )}

                    {assessmentCompleted && (
                      <AouResults
                        changeEnrolementStep={changeEnrolementStep}
                        resetQuestions={resetQuestions}
                      />
                    )}
                  </>
                )}
              </div>
            </Box>
          </div>
        </>
      </Container>
      <PreLoader show={Loading} />
    </>
  );
};

AssessmentOU.propTypes = {
  changeEnrolementStep: PropTypes.func,
};

export default AssessmentOU;
