import React, { useState, useEffect } from "react";
import styles from "./FormControlPage.module.css";
import PreLoader from "../../../components/common/PreLoader";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import FormItem from "./FormItem";
import { useSelector, useDispatch } from "react-redux";
import {
  setEnrolmentForms,
  setAppTitle,
  setParticipantType,
} from "../../../store/slices/settingsSlice";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { apiCreateEnrolment } from "../../../services/api/enrolmentApi.service";
import { apiCreateParticipant } from "../../../services/api/participantApi.service";
import {
  setParticipantId,
  setEnrolmentId,
} from "../../../store/slices/enrolmentSlice";
import { getUserSiteId } from "../../../services/users.service";
import { useTranslation } from "react-i18next";

const FormControlPage = ({ changeEnrolementStep }) => {
  const [t] = useTranslation("common");
  const [Loading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const enrolmentState = useSelector((state) => state.enrolment);
  const { isDeviceOnline, isReconsent } = useSelector(
    (state) => state.settings
  );
  const { enrolmentForms, ageCat, currentFormIndex } = useSelector(
    (state) => state.settings
  );

  let _pageType = "";

  if (enrolmentForms) {
    _pageType =
      currentFormIndex === 0
        ? enrolmentForms[currentFormIndex].type
        : enrolmentForms[currentFormIndex - 1].type;
  }

  const saveAndReview = async () => {
    history.push({ pathname: "/review-and-submit" });
  };

  const onFormStart = () => {
    let _enrolmentForms = JSON.parse(JSON.stringify(enrolmentForms));
    const _pageName = _enrolmentForms[currentFormIndex].name;
    const _participantType = _enrolmentForms[currentFormIndex].type;

    _enrolmentForms[currentFormIndex].isStarted = true;

    dispatch(setEnrolmentForms(_enrolmentForms));
    dispatch(setParticipantType(_participantType));

    if (_pageName.indexOf("_AOU") > 0) {
      changeEnrolementStep(7);
    } else {
      changeEnrolementStep(6);
    }
  };

  const completedCount = (formType) => {
    if (enrolmentForms) {
      return enrolmentForms.filter(
        (item) => item.type === formType && item.isDone === true
      ).length;
    } else {
      return 0;
    }
  };

  const noOfMinorParentCompleted = completedCount("MINOR_PARENT");
  const noOfMinorParticipantCompleted = completedCount("MINOR_PARTICIPANT");
  const noOfEmancipatedMinorCompleted = completedCount("EMANCIPATED_MINOR");
  const noOfAdultCompleted = completedCount("ADULT_18_PLUS");

  const createNewEnrolment = async (participantId) => {
    const results = await apiCreateEnrolment(participantId);
    if (results.success) {
      const enrolementId = results.body;
      dispatch(setEnrolmentId(enrolementId));
    }
  };

  const createNewParticipant = async () => {
    const siteId = await getUserSiteId();
    const results = await apiCreateParticipant(siteId);

    if (results.success) {
      const participantId = results.body;
      dispatch(setParticipantId(participantId));
      createNewEnrolment(participantId);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (
      enrolmentState.participant_id === null &&
      isDeviceOnline &&
      isReconsent === false
    ) {
      createNewParticipant();
    } else if (isReconsent) {
      createNewEnrolment(enrolmentState.participant_id);
    }
    const _enrolmentLabel = t("new_enrolment");
    dispatch(setAppTitle(_enrolmentLabel));
  }, []);

  const btnReviewSubmit = (isDisabled) => {
    return (
      <Button
        disabled={isDisabled}
        variant="contained"
        sx={{ minWidth: "300px" }}
        onClick={saveAndReview}
      >
        {t("label_review_and_submit")}
      </Button>
    );
  };

  return (
    <>
      <Container maxWidth="md">
        {ageCat === 1 && (
          <>
            <div className={styles.pageBody}>
              <div>
                <h2>{t("minor_parent_consent_list_title")}</h2>
              </div>
              <div>
                {enrolmentForms
                  .filter((item) => item.type === "MINOR_PARENT")
                  .map((form, index) => (
                    <FormItem
                      key={`formItem_${form.id}`}
                      isActive={form.isCurrent}
                      isCompleted={form.isDone}
                      formTitle={form.formName}
                      isAOU={form.name === "MINOR_PARENT_AOU"}
                      onStart={() => {
                        onFormStart();
                      }}
                      onEdit={() => {
                        onFormStart();
                      }}
                      count={index + 1}
                    />
                  ))}

                <div className={styles.formSummary}>
                  <div className={styles.formSummaryCompleted}>
                    {t("label_completed")}: {noOfMinorParentCompleted}
                  </div>
                  <div className={styles.formSummaryPending}>
                    {t("consent_list_pending_label")}:{" "}
                    {4 - noOfMinorParentCompleted}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.pageBody}>
              <div>
                <h2>{t("participant_assent_list_title")}</h2>
              </div>
              <div>
                {enrolmentForms
                  .filter((item) => item.type === "MINOR_PARTICIPANT")
                  .map((form, index) => (
                    <FormItem
                      key={`formItem_${form.id}`}
                      isActive={form.isCurrent}
                      isCompleted={form.isDone}
                      formTitle={form.formName}
                      isAOU={form.name === "MINOR_PARTICIPANT_AOU"}
                      onStart={() => {
                        onFormStart();
                      }}
                      onEdit={() => {
                        onFormStart();
                      }}
                      count={index + 1}
                    />
                  ))}
                <div className={styles.formSummary}>
                  <div className={styles.formSummaryCompleted}>
                    {t("label_completed")}: {noOfMinorParticipantCompleted}
                  </div>
                  <div className={styles.formSummaryPending}>
                    {t("consent_list_pending_label")}:{" "}
                    {4 - noOfMinorParticipantCompleted}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {ageCat === 2 && (
          <div className={styles.pageBody}>
            <div>
              <h2>{t("participant_consent_list_title")}</h2>
            </div>
            <div>
              {enrolmentForms
                .filter((item) => item.type === "EMANCIPATED_MINOR")
                .map((form, index) => (
                  <FormItem
                    key={`formItem_${form.id}`}
                    isActive={form.isCurrent}
                    isCompleted={form.isDone}
                    formTitle={form.formName}
                    isAOU={form.name === "EMANCIPATED_MINOR_AOU"}
                    onStart={() => {
                      onFormStart(index + 4);
                    }}
                    onEdit={() => {
                      onFormStart(index + 4);
                    }}
                    count={index + 1}
                  />
                ))}
              <div className={styles.formSummary}>
                <div className={styles.formSummaryCompleted}>
                  {t("label_completed")}: {noOfMinorParticipantCompleted}
                </div>
                <div className={styles.formSummaryPending}>
                  {t("consent_list_pending_label")}:{" "}
                  {3 - noOfMinorParticipantCompleted}
                </div>
              </div>
            </div>
          </div>
        )}

        {ageCat === 3 && (
          <div className={styles.pageBody}>
            <div>
              <h2>{t("participant_consent_list_title")}</h2>
            </div>
            <div>
              {enrolmentForms
                .filter((item) => item.type === "ADULT_18_PLUS")
                .map((form, index) => (
                  <FormItem
                    key={`formItem_${form.id}`}
                    isActive={form.isCurrent}
                    isCompleted={form.isDone}
                    formTitle={form.formName}
                    isAOU={form.name === "ADULT_AOU"}
                    onStart={() => {
                      onFormStart(index + 1);
                    }}
                    onEdit={() => {
                      onFormStart(index + 1);
                    }}
                    count={index + 1}
                  />
                ))}
              <div className={styles.formSummary}>
                <div className={styles.formSummaryCompleted}>
                  {t("label_completed")}: {noOfAdultCompleted}
                </div>
                <div className={styles.formSummaryPending}>
                  {t("consent_list_pending_label")}: {4 - noOfAdultCompleted}
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          style={{ textAlign: "center", width: "100%", marginBottom: "50px" }}
        >
          {(_pageType === "MINOR_PARENT" ||
            _pageType === "MINOR_PARTICIPANT") &&
            btnReviewSubmit(
              noOfMinorParentCompleted + noOfMinorParticipantCompleted !== 8
            )}
          {_pageType === "EMANCIPATED_MINOR" &&
            btnReviewSubmit(noOfEmancipatedMinorCompleted !== 4)}
          {_pageType === "ADULT_18_PLUS" &&
            btnReviewSubmit(noOfAdultCompleted !== 4)}
        </div>
      </Container>
      <PreLoader show={Loading} />
    </>
  );
};

FormControlPage.propTypes = {
  changeEnrolementStep: PropTypes.func,
};

export default FormControlPage;
