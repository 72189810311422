import { API, graphqlOperation } from "aws-amplify";
import {
  createSubStudyIL,
  updateSubStudyILParticipant,
  updateSubStudyILGuardian,
  updateEnrolmentStatus,
} from "../../graphql/mutations";

export const apiCreateIL = async (participantId) => {
  let results = { success: true, body: {}, message: "" };

  const enrolmentData = {
    PK: `PARTICIPANT#${participantId}`,
    consent_country: localStorage.getItem("i18nextLng").split("_")[1],
  };

  try {
    const response = await API.graphql(
      graphqlOperation(createSubStudyIL, {
        ConsentWithAudioTaping: enrolmentData,
      })
    );

    results.body = response.data.createSubStudyIL;
  } catch (err) {
    results.success = false;
    results.message = "Error creating new record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateILStatus = async (
  participantId,
  enrolementId,
  recordStatus
) => {
  let results = { success: true, body: {}, message: "" };

  const enrolmentData = {
    PK: `PARTICIPANT#${participantId}`,
    SK: `IL#${enrolementId}`,
    record_status: recordStatus,
    consent_country: localStorage.getItem("i18nextLng").split("_")[1],
  };

  try {
    const response = await API.graphql(
      graphqlOperation(updateEnrolmentStatus, {
        ConsentWithLongStorage: enrolmentData,
      })
    );

    results.body = response.data.updateEnrolmentStatus;
  } catch (err) {
    results.success = false;
    results.message = "Error saving record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateILParticipant = async (participantData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateSubStudyILParticipant, {
        ConsentWithAudioTaping: participantData,
      })
    );

    results.body = response.data.updateSubStudyILParticipant;
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateILGuardian = async (guardianData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateSubStudyILGuardian, {
        ConsentWithAudioTaping: guardianData,
      })
    );

    results.body = response.data.updateSubStudyILGuardian;
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};


