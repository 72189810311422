export const icf_zambia_minor_parent = {
  icf_document_name: "05 Nov 2022 - MAGY_ Main Informed Assent Form_V1.0 english.pdf",
  icf_type_version_no: "1.0",
  icf_version_date: "05/11/2022",
  icf_signed_date: "15/11/2022",
};

export const icf_zambia_minor_participant = {
  icf_document_name: "05 Nov 2022 - MAGY_ Main Parental Consent Form_V1.0 english",
  icf_type_version_no: "1.0",
  icf_version_date: "05/11/2022",
  icf_signed_date: "15/11/2022",
};

export const icf_zambia_adult_participant = {
  icf_document_name: "05 Nov 2022 - MAGY_ Main Informed Consent Form_V1.0 english.pdf",
  icf_type_version_no: "1.0",
  icf_version_date: "05/11/2022",
  icf_signed_date: "15/11/2022",
};


