/* eslint-disable no-unused-vars */
import React from "react";
import styles from "./FormItem.module.css";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { tickDoneIconBase64 } from "../../../../data/image_assets";
import { useTranslation } from "react-i18next";

const FormItem = ({
  count,
  isActive = false,
  isCompleted = false,
  formTitle,
  onStart,
  onEdit,
  isAOU,
}) => {
  const [t] = useTranslation("common");
  return (
    <>
      <div className={styles.formItemRow}>
        <div style={{ display: "flex" }}>
          <div>
            <div
              className={styles.formItemcounter}
              style={{
                border: isCompleted ? "5px solid #00A100" : "5px solid red",
              }}
            >
              {isCompleted ? (
                <img src={tickDoneIconBase64} alt="Completed" />
              ) : (
                count
              )}
            </div>
          </div>
          <div className={styles.formItemTitle}>{t(formTitle)}</div>
        </div>

        <div className={styles.formItemBtn}>
          {!isCompleted && (
            <Button
              disabled={!isActive}
              variant="contained"
              sx={{ minWidth: "100px" }}
              onClick={onStart}
            >
              {t("label_start")}
            </Button>
          )}
          {isCompleted && (
            <Button
              disabled={isCompleted}
              variant="contained"
              sx={{ minWidth: "100px", background: "#00a100 !important" }}
              onClick={onEdit}
            >
              {t("label_completed")}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

FormItem.propTypes = {
  count: PropTypes.string,
  isActive: PropTypes.bool,
  isCompleted: PropTypes.bool,
  formTitle: PropTypes.string,
  onStart: PropTypes.func,
  onEdit: PropTypes.func,
  isAOU: PropTypes.bool,
};

export default FormItem;
