import React, { useEffect, useState } from "react";
import styles from "./GenTestMinorParentIntro.module.css";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import DisplayIntroSection from "../../../../components/common/DisplayIntroSection";

const GenTestMinorParentIntro = ({ printMode = false }) => {
  const [userFullName, setUserFullName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [t] = useTranslation("common");

  const getCurrentUser = async () => {
    let user = null;
    try {
      user = await Auth.currentAuthenticatedUser();
      const firstName = user.attributes["custom:first_name"];
      const lastName = user.attributes["custom:last_name"];
      setUserRole(
        user.signInUserSession.accessToken.payload["cognito:groups"][0]
      );
      setUserFullName(`${firstName} ${lastName}`);
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <>
      <h2 className={styles.intro}>CONSENT FOR GENETIC TESTING</h2>
      <p>
        {t("GT_minor_parent_intro_p1")
          .replace("#userFullName#", userFullName)
          .replace("#userRole#", userRole)
          .replace("SiteUsers", "Counsellor")}
      </p>
      <div>
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("GT_minor_parent_you_should_know_gt_title")}
            </h2>
          }
          contentElements={
            <>
              <ul>
                <li>{t("GT_minor_parent_you_should_know_gt_b1")}</li>
                <li>{t("GT_minor_parent_you_should_know_gt_b2")}</li>
                <li>{t("GT_minor_parent_you_should_know_gt_b3")}</li>
              </ul>
            </>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("GT_minor_parent_gt_being_done_title")}
            </h2>
          }
          contentElements={<>{t("GT_minor_parent_gt_being_done_p1")}</>}
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("GT_minor_parent_risks_gt_title")}
            </h2>
          }
          contentElements={<>{t("GT_minor_parent_risks_gt_p1")}</>}
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("GT_minor_parent_benefits_gt_title")}
            </h2>
          }
          contentElements={
            <>
              {t("GT_minor_parent_benefits_gt_p1")}
              <br />
              <br />
              {t("GT_minor_parent_benefits_gt_p2")}
            </>
          }
        />
      </div>
    </>
  );
};

GenTestMinorParentIntro.propTypes = {
  printMode: PropTypes.bool,
};

export default GenTestMinorParentIntro;
