import React from "react";
import styles from "./OptionButtons.module.css";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";

const OptionButtons = ({
  onClicked,
  isDisabled = false,
  fullWidth = false,
  buttons,
  width = "80px",
}) => {
  const btnClicked = (index) => {
    const _btns = [...buttons];
    _btns[0].isSelected = false;
    _btns[1].isSelected = false;
    _btns[index].isSelected = true;
    onClicked([..._btns]);
  };

  return (
    <div className="buttonGroup">
      {buttons.map((button, index) => (
        <Button
          key={`option_button_${button.id}`}
          variant="contained"
          size="small"
          sx={{ mt: 3, mb: 2, width: width }}
          fullWidth={fullWidth}
          disabled={isDisabled}
          onClick={() => {
            btnClicked(index);
          }}
          className={button.isSelected ? styles.selected : styles.normal}
        >
          {button.label}
        </Button>
      ))}
    </div>
  );
};

OptionButtons.propTypes = {
  onClicked: PropTypes.func,
  isDisabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  buttons: PropTypes.array,
  width: PropTypes.string,
};

export default OptionButtons;
