import { API, graphqlOperation } from "aws-amplify";
import {
  createSubStudyIC,
  updateSubStudyICParticipant,
  updateSubStudyICGuardian,
  updateEnrolmentStatus,
} from "../../graphql/mutations";

export const apiCreateIC = async (participantId) => {
  let results = { success: true, body: {}, message: "" };

  const enrolmentData = {
    PK: `PARTICIPANT#${participantId}`,
    consent_country: localStorage.getItem("i18nextLng").split("_")[1],
  };

  try {
    const response = await API.graphql(
      graphqlOperation(createSubStudyIC, {
        ConsentWithAudioTaping: enrolmentData,
      })
    );

    results.body = response.data.createSubStudyIC;
  } catch (err) {
    results.success = false;
    results.message = "Error creating new record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateICStatus = async (
  participantId,
  enrolementId,
  recordStatus
) => {
  let results = { success: true, body: {}, message: "" };

  const enrolmentData = {
    PK: `PARTICIPANT#${participantId}`,
    SK: `IC#${enrolementId}`,
    record_status: recordStatus,
    consent_country: localStorage.getItem("i18nextLng").split("_")[1],
  };

  try {
    const response = await API.graphql(
      graphqlOperation(updateEnrolmentStatus, {
        ConsentWithLongStorage: enrolmentData,
      })
    );

    results.body = response.data.updateEnrolmentStatus;
  } catch (err) {
    results.success = false;
    results.message = "Error saving record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateICParticipant = async (participantData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateSubStudyICParticipant, {
        ConsentWithAudioTaping: participantData,
      })
    );

    results.body = response.data.updateSubStudyICParticipant;
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateICGuardian = async (guardianData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateSubStudyICGuardian, {
        ConsentWithAudioTaping: guardianData,
      })
    );

    results.body = response.data.updateSubStudyICGuardian;
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};


