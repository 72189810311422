import React, { useState, useEffect } from "react";
import styles from "./FormControlPage.module.css";
import PreLoader from "../../../components/common/PreLoader";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import FormItem from "../../NewEnrolment/FormControlPage/FormItem";
import { useSelector, useDispatch } from "react-redux";
import {
  setAppTitle,
  setParticipantType,
} from "../../../store/slices/settingsSlice";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { apiCreateFGDA1 } from "../../../services/api/subStudyFGDA1Api.service";
import { apiCreateParticipant } from "../../../services/api/participantApi.service";
import {
  setParticipantId,
  setEnrolmentId,
  setMinorParentConsent,
  setMinorParticipantConsent,
} from "../../../store/slices/enrolmentSlice";
import { getUserSiteId } from "../../../services/users.service";
import { useTranslation } from "react-i18next";
import FindParticipant from "../../../components/FindParticipant";

const FormControlPage = ({ changeEnrolementStep }) => {
  const [t] = useTranslation("common");
  const [Loading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const enrolmentState = useSelector((state) => state.enrolment);
  const [showFindParticipant, setShowFindParticipant] = useState(false);
  const { isDeviceOnline, isReconsent } = useSelector(
    (state) => state.settings
  );
  const { enrolmentForms, ageCat, currentFormIndex } = useSelector(
    (state) => state.settings
  );

  let _pageType = "";

  if (enrolmentForms) {
    _pageType = enrolmentForms[currentFormIndex]?.type;
  }

  const saveAndReview = async () => {
    history.push({ pathname: "/fgda1-review-and-submit" });
  };

  const onFormStart = () => {
    let _enrolmentForms = JSON.parse(JSON.stringify(enrolmentForms));
    const _pageName = _enrolmentForms[currentFormIndex].name;
    const _participantType = _enrolmentForms[currentFormIndex].type;

    _enrolmentForms[currentFormIndex].isStarted = true;

    dispatch(setParticipantType(_participantType));

    if (_pageName.indexOf("_AOU") > 0) {
      changeEnrolementStep(7);
    } else {
      changeEnrolementStep(6);
    }
  };

  const completedCount = (formType) => {
    if (enrolmentForms) {
      return enrolmentForms.filter(
        (item) => item.type === formType && item.isDone === true
      ).length;
    } else {
      return 0;
    }
  };

  const [noOfMinorParentCompleted, setNoOfMinorParentCompleted] = useState(
    completedCount("MINOR_PARENT")
  );
  const [noOfMinorParticipantCompleted, setNoOfMinorParticipantCompleted] =
    useState(completedCount("MINOR_PARTICIPANT"));
  const [noOfEmancipatedMinorCompleted, setNoOfEmancipatedMinorCompleted] =
    useState(completedCount("EMANCIPATED_MINOR"));
  const [noOfAdultCompleted, setNoOfAdultCompleted] = useState(
    completedCount("ADULT_18_PLUS")
  );
  const [noOfParentParticipantCompleted, setNoOfParentParticipantCompleted] =
    useState(completedCount("PARENT_PARTICIPANT"));
  const [noOfCommunityMembersCompleted, setNoOfCommunityMembersCompleted] =
    useState(completedCount("COMMUNITY_MEMBERS"));

  useEffect(() => {
    setNoOfMinorParentCompleted(completedCount("MINOR_PARENT"));
    setNoOfMinorParticipantCompleted(completedCount("MINOR_PARTICIPANT"));
    setNoOfEmancipatedMinorCompleted(completedCount("EMANCIPATED_MINOR"));
    setNoOfAdultCompleted(completedCount("ADULT_18_PLUS"));
    setNoOfParentParticipantCompleted(completedCount("PARENT_PARTICIPANT"));
    setNoOfCommunityMembersCompleted(completedCount("COMMUNITY_MEMBERS"));
  }, [enrolmentForms]);

  const createNewEnrolment = async (participantId) => {
    const results = await apiCreateFGDA1(participantId);
    if (results.success) {
      const enrolementId = results.body;
      dispatch(setEnrolmentId(enrolementId));
    }
  };

  const createNewParticipant = async () => {
    const siteId = await getUserSiteId();
    const results = await apiCreateParticipant(siteId);

    if (results.success) {
      const participantId = results.body;
      dispatch(setParticipantId(participantId));
      createNewEnrolment(participantId);
    }
  };

  const handleParticipantSelect = (participant) => {
    dispatch(setParticipantId(participant.participant_id));
    createNewEnrolment(participant.participant_id);
    setShowFindParticipant(false);

    dispatch(
      setMinorParentConsent({
        childFirstName: participant.participant_first_name,
        childLastName: participant.participant_last_name,
        parentFirstName: participant.guardian_first_name,
        parentLastName: participant.guardian_last_name,
        parentSignature: null,
        isSigned: null,
        consentDate: null,
        consentTime: null,
      })
    );

    dispatch(
      setMinorParticipantConsent({
        participantFirstName: participant.participant_first_name,
        participantLastName: participant.participant_last_name,
        participantSignature: null,
        consentDate: null,
        consentTime: null,
      })
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (
      _pageType === "PARENT_PARTICIPANT" ||
      _pageType === "COMMUNITY_MEMBERS"
    ) {
      if (
        enrolmentState.participant_id === null &&
        isDeviceOnline &&
        isReconsent === false
      ) {
        createNewParticipant();
      } else if (isReconsent) {
        createNewEnrolment(enrolmentState.participant_id);
      }
    } else {
      let _enrolmentForms = JSON.parse(JSON.stringify(enrolmentForms));
      const _participantType = _enrolmentForms[currentFormIndex].type;

      if (
        _participantType !== "MINOR_PARTICIPANT" &&
        _enrolmentForms[currentFormIndex].isDone === false
      ) {
        setShowFindParticipant(true);
      } else {
        setShowFindParticipant(false);
      }
    }

    dispatch(setAppTitle("Focus group discussions - Aim 1"));
  }, []);

  const btnReviewSubmit = (isDisabled) => {
    return (
      <Button
        disabled={isDisabled}
        variant="contained"
        sx={{ minWidth: "300px" }}
        onClick={saveAndReview}
      >
        {t("label_review_and_submit")}
      </Button>
    );
  };

  return (
    <>
      <Container maxWidth="md">
        {showFindParticipant && (
          <>
            <FindParticipant
              handleParticipantSelect={handleParticipantSelect}
            />
          </>
        )}
        {!showFindParticipant && (
          <>
            {ageCat === 1 && (
              <>
                <div className={styles.pageBody}>
                  <div>
                    <h2>{t("minor_parent_consent_list_title")}</h2>
                  </div>
                  <div>
                    {enrolmentForms
                      .filter((item) => item.type === "MINOR_PARENT")
                      .map((form, index) => (
                        <FormItem
                          key={`formItem_${form.id}`}
                          isActive={form.isCurrent}
                          isCompleted={form.isDone}
                          formTitle={form.formName}
                          onStart={() => {
                            onFormStart();
                          }}
                          onEdit={() => {
                            onFormStart();
                          }}
                          count={index + 1}
                        />
                      ))}

                    <div className={styles.formSummary}>
                      <div className={styles.formSummaryCompleted}>
                        {t("label_completed")}: {noOfMinorParentCompleted}
                      </div>
                      <div className={styles.formSummaryPending}>
                        {t("consent_list_pending_label")}:{" "}
                        {1 - noOfMinorParentCompleted}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.pageBody}>
                  <div>
                    <h2>{t("participant_assent_list_title")}</h2>
                  </div>
                  <div>
                    {enrolmentForms
                      .filter((item) => item.type === "MINOR_PARTICIPANT")
                      .map((form, index) => (
                        <FormItem
                          key={`formItem_${form.id}`}
                          isActive={form.isCurrent}
                          isCompleted={form.isDone}
                          formTitle={form.formName}
                          onStart={() => {
                            onFormStart();
                          }}
                          onEdit={() => {
                            onFormStart();
                          }}
                          count={index + 1}
                        />
                      ))}
                    <div className={styles.formSummary}>
                      <div className={styles.formSummaryCompleted}>
                        {t("label_completed")}: {noOfMinorParticipantCompleted}
                      </div>
                      <div className={styles.formSummaryPending}>
                        {t("consent_list_pending_label")}:{" "}
                        {1 - noOfMinorParticipantCompleted}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {ageCat === 2 && (
              <div className={styles.pageBody}>
                <div>
                  <h2>{t("participant_consent_list_title")}</h2>
                </div>
                <div>
                  {enrolmentForms
                    .filter((item) => item.type === "EMANCIPATED_MINOR")
                    .map((form, index) => (
                      <FormItem
                        key={`formItem_${form.id}`}
                        isActive={form.isCurrent}
                        isCompleted={form.isDone}
                        formTitle={form.formName}
                        onStart={() => {
                          onFormStart(index + 4);
                        }}
                        onEdit={() => {
                          onFormStart(index + 4);
                        }}
                        count={index + 1}
                      />
                    ))}
                  <div className={styles.formSummary}>
                    <div className={styles.formSummaryCompleted}>
                      {t("label_completed")}: {noOfMinorParticipantCompleted}
                    </div>
                    <div className={styles.formSummaryPending}>
                      {t("consent_list_pending_label")}:{" "}
                      {1 - noOfMinorParticipantCompleted}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {ageCat === 3 && (
              <div className={styles.pageBody}>
                <div>
                  <h2>{t("participant_consent_list_title")}</h2>
                </div>
                <div>
                  {enrolmentForms
                    .filter((item) => item.type === "ADULT_18_PLUS")
                    .map((form, index) => (
                      <FormItem
                        key={`formItem_${form.id}`}
                        isActive={form.isCurrent}
                        isCompleted={form.isDone}
                        formTitle={form.formName}
                        onStart={() => {
                          onFormStart(index + 1);
                        }}
                        onEdit={() => {
                          onFormStart(index + 1);
                        }}
                        count={index + 1}
                      />
                    ))}
                  <div className={styles.formSummary}>
                    <div className={styles.formSummaryCompleted}>
                      {t("label_completed")}: {noOfAdultCompleted}
                    </div>
                    <div className={styles.formSummaryPending}>
                      {t("consent_list_pending_label")}:{" "}
                      {1 - noOfAdultCompleted}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {ageCat === 4 && (
              <div className={styles.pageBody}>
                <div>
                  <h2>{t("participant_consent_list_title")}</h2>
                </div>
                <div>
                  {enrolmentForms
                    .filter((item) => item.type === "PARENT_PARTICIPANT")
                    .map((form, index) => (
                      <FormItem
                        key={`formItem_${form.id}`}
                        isActive={form.isCurrent}
                        isCompleted={form.isDone}
                        formTitle={form.formName}
                        onStart={() => {
                          onFormStart(index + 1);
                        }}
                        onEdit={() => {
                          onFormStart(index + 1);
                        }}
                        count={index + 1}
                      />
                    ))}
                  <div className={styles.formSummary}>
                    <div className={styles.formSummaryCompleted}>
                      {t("label_completed")}: {noOfParentParticipantCompleted}
                    </div>
                    <div className={styles.formSummaryPending}>
                      {t("consent_list_pending_label")}:{" "}
                      {1 - noOfParentParticipantCompleted}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {ageCat === 5 && (
              <div className={styles.pageBody}>
                <div>
                  <h2>{t("participant_consent_list_title")}</h2>
                </div>
                <div>
                  {enrolmentForms
                    .filter((item) => item.type === "COMMUNITY_MEMBERS")
                    .map((form, index) => (
                      <FormItem
                        key={`formItem_${form.id}`}
                        isActive={form.isCurrent}
                        isCompleted={form.isDone}
                        formTitle={form.formName}
                        onStart={() => {
                          onFormStart(index + 1);
                        }}
                        onEdit={() => {
                          onFormStart(index + 1);
                        }}
                        count={index + 1}
                      />
                    ))}
                  <div className={styles.formSummary}>
                    <div className={styles.formSummaryCompleted}>
                      {t("label_completed")}: {noOfCommunityMembersCompleted}
                    </div>
                    <div className={styles.formSummaryPending}>
                      {t("consent_list_pending_label")}:{" "}
                      {1 - noOfCommunityMembersCompleted}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                textAlign: "center",
                width: "100%",
                marginBottom: "50px",
              }}
            >
              {(_pageType === "MINOR_PARENT" ||
                _pageType === "MINOR_PARTICIPANT") &&
                btnReviewSubmit(
                  noOfMinorParentCompleted + noOfMinorParticipantCompleted !== 2
                )}
              {_pageType === "EMANCIPATED_MINOR" &&
                btnReviewSubmit(noOfEmancipatedMinorCompleted !== 1)}
              {_pageType === "ADULT_18_PLUS" &&
                btnReviewSubmit(noOfAdultCompleted !== 1)}
              {_pageType === "PARENT_PARTICIPANT" &&
                btnReviewSubmit(noOfParentParticipantCompleted !== 1)}
              {_pageType === "COMMUNITY_MEMBERS" &&
                btnReviewSubmit(noOfCommunityMembersCompleted !== 1)}
            </div>
            {/* {noOfMinorParentCompleted} {noOfMinorParticipantCompleted}{" "}
            {noOfParentParticipantCompleted}{" "}
            {enrolmentForms[currentFormIndex]?.type} {currentFormIndex} */}
          </>
        )}
      </Container>
      <PreLoader show={Loading} />
    </>
  );
};

FormControlPage.propTypes = {
  changeEnrolementStep: PropTypes.func,
};

export default FormControlPage;
