import React, { useState } from "react";
import styles from "./StepReview.module.css";
import PreLoader from "../../../components/common/PreLoader";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ConsentImpartialWitnessView from "./ConsentImpartialWitnessView";
import ConsentPersonObtainingView from "./ConsentPersonObtainingView";
import { useSelector, useDispatch } from "react-redux";
import {
  setEnrolmentForms,
} from "../../../store/slices/settingsSlice";
import {
  setParticipantWitness,
  setParticipantPersonObtaining,
} from "../../../store/slices/enrolmentSlice";
import { resetImpartialWitnessForm } from "../../../store/slices/impartialWitnessFormSlice";
import { resetPersonObtainingForm } from "../../../store/slices/personObtainingFormSlice";
import ConsentAdultParticipantView from "./ConsentAdultParticipantView";

// eslint-disable-next-line no-unused-vars
const StepReview = ({ handleNext, handleBack, goMainPage }) => {
  const [Loading] = useState(false);
  const [activeStep] = useState(1);
  const dispatch = useDispatch();

  const {
    enrolmentForms,
    currentFormIndex,
    participantIlliterate,
  } = useSelector((state) => state.settings);

  const impartialWitnessObj = useSelector(
    (state) => state.impartialWitnessForm.witnessForm
  );

  const personObtainingObj = useSelector(
    (state) => state.personObtainingForm.formData
  );

  const pageName = enrolmentForms ? enrolmentForms[currentFormIndex].name : "";
  const pageType = enrolmentForms ? enrolmentForms[currentFormIndex].type : "";

  const saveFormAndContinue = () => {
    let _enrolmentForms = JSON.parse(JSON.stringify(enrolmentForms));
    _enrolmentForms[currentFormIndex].isDone = true;
    _enrolmentForms[currentFormIndex].isCurrent = true;


    dispatch(setEnrolmentForms(_enrolmentForms));

    if (
      pageName === "KEY_INFORMANT_CONSENT"
    ) {
      dispatch(setParticipantWitness(impartialWitnessObj));
      dispatch(setParticipantPersonObtaining(personObtainingObj));
    }

    dispatch(resetImpartialWitnessForm());
    dispatch(resetPersonObtainingForm());
    goMainPage();
  };

  return (
    <>
      <div className={styles.pageBox}>
        
        {(pageName === "KEY_INFORMANT_CONSENT") && (
          <ConsentAdultParticipantView />
        )}

        {(pageType === "KEY_INFORMANT_CONSENT") &&
          participantIlliterate && (
          <ConsentImpartialWitnessView
            pageName={pageName}
            impartialWitnessObj={impartialWitnessObj}
          />
        )}

        <ConsentPersonObtainingView personObtainingObj={personObtainingObj} />
      </div>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          variant="contained"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1, width: "100px" }}
        >
          Back
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          variant="contained"
          sx={{ mr: 1, width: "100px" }}
          data-testid="go_next"
          onClick={saveFormAndContinue}
        >
          Continue
        </Button>
      </Box>
      <PreLoader show={Loading} />
    </>
  );
};

StepReview.propTypes = {
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  goMainPage: PropTypes.func,
};

export default StepReview;
