import { uploadS3File } from "./api/fileuploadApi.service";
import {
  apiUpdateFGDA1Participant,
  apiUpdateFGDA1Guardian,
  apiUpdateFGDA1Status,
  apiGetFGDA1,
} from "./api/subStudyFGDA1Api.service";
import {
  apiUpdateParticipant,
  apiUpdateParticipantStatus,
} from "./api/participantApi.service";

const getCreatedDateFormat = (date) => {
  const dtArray = date.split("/");
  const _date = dtArray[2] + "-" + dtArray[1] + "-" + dtArray[0];
  return _date;
};

export const getFGDA1ParticipantData = async (enrolmentObj, settingsObj) => {
  const selectedLang = localStorage.getItem("i18nextLng");
  const consentCountry = selectedLang.split("_")[1];

  const participantData = {
    PK: `PARTICIPANT#${enrolmentObj.participant_id}`,
    SK: `FGDA1#${enrolmentObj.enrolment_id}`,
    participant_id: enrolmentObj.participant_id,
    metadata: "",
    chosen_language: selectedLang,
    age_category: settingsObj.ageCat,
    created_date: getCreatedDateFormat(enrolmentObj.created_date),
    created_time: enrolmentObj.created_time,
    site_id:
      settingsObj.loggedInUser !== null
        ? settingsObj.loggedInUser.siteId
        : null,
    screen_times: "",
    participant_illiterate: settingsObj.participantIlliterate,
    consent_participant_first_name: enrolmentObj.consent_participant_first_name,
    consent_participant_last_name: enrolmentObj.consent_participant_last_name,
    consent_participant_signature:
      enrolmentObj.consent_participant_signature !== null
        ? `${consentCountry}_${settingsObj.studyName}_p${enrolmentObj.participant_id}_e${enrolmentObj.enrolment_id}_consent_participant_signature.txt`
        : "",
    consent_participant_date: enrolmentObj.consent_participant_date,
    consent_participant_time: enrolmentObj.consent_participant_time,
    consent_participant_witness_first_name:
      enrolmentObj.consent_participant_witness_first_name,
    consent_participant_witness_last_name:
      enrolmentObj.consent_participant_witness_last_name,
    consent_participant_witness_signature:
      enrolmentObj.consent_participant_witness_signature !== null
        ? `${consentCountry}_${settingsObj.studyName}_p${enrolmentObj.participant_id}_e${enrolmentObj.enrolment_id}_consent_participant_witness_signature.txt`
        : "",
    consent_participant_witness_date:
      enrolmentObj.consent_participant_witness_date,
    consent_participant_witness_time:
      enrolmentObj.consent_participant_witness_time,
    consent_participant_staff_first_name:
      enrolmentObj.consent_participant_staff_first_name,
    consent_participant_staff_last_name:
      enrolmentObj.consent_participant_staff_last_name,
    consent_participant_staff_signature:
      enrolmentObj.consent_participant_staff_signature
        ? `${consentCountry}_${settingsObj.studyName}_p${enrolmentObj.participant_id}_e${enrolmentObj.enrolment_id}_consent_participant_staff_signature.txt`
        : "",
    consent_participant_staff_date: enrolmentObj.consent_participant_staff_date,
    consent_participant_staff_time: enrolmentObj.consent_participant_staff_time,
    audio_tape_participant_consent_1:
      enrolmentObj.lng_stor_participant_consent_1,
    audio_tape_participant_consent_2:
      enrolmentObj.lng_stor_participant_consent_2,
    audio_tape_participant_first_name:
      enrolmentObj.lng_stor_participant_first_name,
    audio_tape_participant_last_name:
      enrolmentObj.lng_stor_participant_last_name,
    audio_tape_participant_signature:
      enrolmentObj.lng_stor_participant_signature
        ? `${consentCountry}_${settingsObj.studyName}_p${enrolmentObj.participant_id}_e${enrolmentObj.enrolment_id}_lng_stor_participant_signature.txt`
        : "",
    audio_tape_participant_date: enrolmentObj.lng_stor_participant_date,
    audio_tape_participant_time: enrolmentObj.lng_stor_participant_time,
    audio_tape_participant_witness_first_name:
      enrolmentObj.lng_stor_participant_witness_first_name,
    audio_tape_participant_witness_last_name:
      enrolmentObj.lng_stor_participant_witness_last_name,
    audio_tape_participant_witness_signature:
      enrolmentObj.lng_stor_participant_witness_signature
        ? `${consentCountry}_${settingsObj.studyName}_p${enrolmentObj.participant_id}_e${enrolmentObj.enrolment_id}_lng_stor_participant_witness_signature.txt`
        : "",
    audio_tape_participant_witness_date:
      enrolmentObj.lng_stor_participant_witness_date,
    audio_tape_participant_witness_time:
      enrolmentObj.lng_stor_participant_witness_time,
    audio_tape_participant_staff_first_name:
      enrolmentObj.lng_stor_participant_staff_first_name,
    audio_tape_participant_staff_last_name:
      enrolmentObj.lng_stor_participant_staff_last_name,
    audio_tape_participant_staff_signature:
      enrolmentObj.lng_stor_participant_staff_signature
        ? `${consentCountry}_${settingsObj.studyName}_p${enrolmentObj.participant_id}_e${enrolmentObj.enrolment_id}_lng_stor_participant_staff_signature.txt`
        : "",
    audio_tape_participant_staff_date:
      enrolmentObj.lng_stor_participant_staff_date,
    audio_tape_participant_staff_time:
      enrolmentObj.lng_stor_participant_staff_time,
    consent_country: consentCountry,
    participant_consents: JSON.stringify(enrolmentObj.participant_consents),
    sub_study_name: "FGDA1",
  };

  return participantData;
};

export const getFGDA1GuardianData = async (enrolmentObj, settingsObj) => {
  const selectedLang = localStorage.getItem("i18nextLng");
  const consentCountry = selectedLang.split("_")[1];

  const guardianData = {
    PK: `PARTICIPANT#${enrolmentObj.participant_id}`,
    SK: `FGDA1#${enrolmentObj.enrolment_id}`,
    participant_id: enrolmentObj.participant_id,
    metadata: "",
    chosen_language: selectedLang,
    age_category: settingsObj.ageCat,
    created_date: getCreatedDateFormat(enrolmentObj.created_date),
    created_time: enrolmentObj.created_time,
    site_id:
      settingsObj.loggedInUser !== null
        ? settingsObj.loggedInUser.siteId
        : null,
    screen_times: "",
    guardian_illiterate: settingsObj.parentIlliterate,
    consent_guardian_child_first_name:
      enrolmentObj.consent_guardian_child_first_name,
    consent_guardian_child_last_name:
      enrolmentObj.consent_guardian_child_last_name,
    consent_guardian_first_name: enrolmentObj.consent_guardian_first_name,
    consent_guardian_last_name: enrolmentObj.consent_guardian_last_name,
    consent_guardian_signature: enrolmentObj.consent_guardian_signature
      ? `${consentCountry}_${settingsObj.studyName}_p${enrolmentObj.participant_id}_e${enrolmentObj.enrolment_id}_consent_guardian_signature.txt`
      : "",
    consent_guardian_date: enrolmentObj.consent_guardian_date,
    consent_guardian_time: enrolmentObj.consent_guardian_time,
    consent_guardian_witness_first_name:
      enrolmentObj.consent_guardian_witness_first_name,
    consent_guardian_witness_last_name:
      enrolmentObj.consent_guardian_witness_last_name,
    consent_guardian_witness_signature:
      enrolmentObj.consent_guardian_witness_signature
        ? `${consentCountry}_${settingsObj.studyName}_p${enrolmentObj.participant_id}_e${enrolmentObj.enrolment_id}_consent_guardian_witness_signature.txt`
        : "",
    consent_guardian_witness_date: enrolmentObj.consent_guardian_witness_date,
    consent_guardian_witness_time: enrolmentObj.consent_guardian_witness_time,
    consent_guardian_staff_first_name:
      enrolmentObj.consent_guardian_staff_first_name,
    consent_guardian_staff_last_name:
      enrolmentObj.consent_guardian_staff_last_name,
    consent_guardian_staff_signature:
      enrolmentObj.consent_guardian_staff_signature
        ? `${consentCountry}_${settingsObj.studyName}_p${enrolmentObj.participant_id}_e${enrolmentObj.enrolment_id}_consent_guardian_staff_signature.txt`
        : "",
    consent_guardian_staff_date: enrolmentObj.consent_guardian_staff_date,
    consent_guardian_staff_time: enrolmentObj.consent_guardian_staff_time,
    audio_tape_guardian_consent_1: enrolmentObj.lng_stor_guardian_consent_1,
    audio_tape_guardian_consent_2: enrolmentObj.lng_stor_guardian_consent_2,

    audio_tape_guardian_first_name: enrolmentObj.lng_stor_guardian_first_name,
    audio_tape_guardian_last_name: enrolmentObj.lng_stor_guardian_last_name,
    audio_tape_guardian_signature: enrolmentObj.lng_stor_guardian_signature
      ? `${consentCountry}_${settingsObj.studyName}_p${enrolmentObj.participant_id}_e${enrolmentObj.enrolment_id}_lng_stor_guardian_signature.txt`
      : "",
    audio_tape_guardian_date: enrolmentObj.lng_stor_guardian_date,
    audio_tape_guardian_time: enrolmentObj.lng_stor_guardian_time,
    audio_tape_guardian_witness_first_name:
      enrolmentObj.lng_stor_guardian_witness_first_name,
    audio_tape_guardian_witness_last_name:
      enrolmentObj.lng_stor_guardian_witness_last_name,
    audio_tape_guardian_witness_signature:
      enrolmentObj.lng_stor_guardian_witness_signature
        ? `${consentCountry}_${settingsObj.studyName}_p${enrolmentObj.participant_id}_e${enrolmentObj.enrolment_id}_lng_stor_guardian_witness_signature.txt`
        : "",
    audio_tape_guardian_witness_date:
      enrolmentObj.lng_stor_guardian_witness_date,
    audio_tape_guardian_witness_time:
      enrolmentObj.lng_stor_guardian_witness_time,
    audio_tape_guardian_staff_first_name:
      enrolmentObj.lng_stor_guardian_staff_first_name,
    audio_tape_guardian_staff_last_name:
      enrolmentObj.lng_stor_guardian_staff_last_name,
    audio_tape_guardian_staff_signature:
      enrolmentObj.lng_stor_guardian_staff_signature
        ? `${consentCountry}_${settingsObj.studyName}_p${enrolmentObj.participant_id}_e${enrolmentObj.enrolment_id}_lng_stor_guardian_staff_signature.txt`
        : "",
    audio_tape_guardian_staff_date: enrolmentObj.lng_stor_guardian_staff_date,
    audio_tape_guardian_staff_time: enrolmentObj.lng_stor_guardian_staff_time,
    consent_country: consentCountry,
    guardian_consents: JSON.stringify(enrolmentObj.guardian_consents),
    sub_study_name: "FGDA1",
  };

  return guardianData;
};

export const saveFGDA1ParticipantData = async (
  enrolmentState,
  settingsState
) => {
  const _participantData = await getFGDA1ParticipantData(
    enrolmentState,
    settingsState
  );

  if (enrolmentState.consent_participant_signature !== null) {
    await uploadS3File(
      `PARTICIPANT#${enrolmentState.participant_id}`,
      enrolmentState.consent_participant_signature,
      _participantData.consent_participant_signature
    );
  }

  if (
    settingsState.participantIlliterate &&
    enrolmentState.consent_participant_witness_signature !== null
  ) {
    await uploadS3File(
      `PARTICIPANT#${enrolmentState.participant_id}`,
      enrolmentState.consent_participant_witness_signature,
      _participantData.consent_participant_witness_signature
    );
  }

  if (enrolmentState.consent_participant_staff_signature !== null) {
    await uploadS3File(
      `PARTICIPANT#${enrolmentState.participant_id}`,
      enrolmentState.consent_participant_staff_signature,
      _participantData.consent_participant_staff_signature
    );
  }

  const results = await apiUpdateFGDA1Participant(_participantData);

  return results.success;
};

export const saveFGDA1GuardianData = async (
  enrolmentState,
  settingsState,
  guardianResults
) => {
  const _guardianData = await getFGDA1GuardianData(
    enrolmentState,
    settingsState,
    guardianResults
  );

  if (enrolmentState.consent_guardian_signature !== null) {
    await uploadS3File(
      `PARTICIPANT#${enrolmentState.participant_id}`,
      enrolmentState.consent_guardian_signature,
      _guardianData.consent_guardian_signature
    );
  }

  if (
    settingsState.parentIlliterate &&
    enrolmentState.consent_guardian_witness_signature !== null
  ) {
    await uploadS3File(
      `PARTICIPANT#${enrolmentState.participant_id}`,
      enrolmentState.consent_guardian_witness_signature,
      _guardianData.consent_guardian_witness_signature
    );
  }

  if (enrolmentState.consent_guardian_staff_signature !== null) {
    await uploadS3File(
      `PARTICIPANT#${enrolmentState.participant_id}`,
      enrolmentState.consent_guardian_staff_signature,
      _guardianData.consent_guardian_staff_signature
    );
  }

  const results = await apiUpdateFGDA1Guardian(_guardianData);
  return results.success;
};

export const saveFGDA1ParticipantUserData = async (
  enrolmentState,
  settingsState
) => {
  const _lang = localStorage.getItem("i18nextLng");
  const _country = _lang.split("_")[1];
  let _participantConsents = [...settingsState.participantConsents];
  _participantConsents.push("FGDA1");

  const results = await apiUpdateParticipant({
    PK: `PARTICIPANT#${enrolmentState.participant_id}`,
    SK: enrolmentState.participant_id,
    participant_first_name: enrolmentState.consent_participant_first_name,
    participant_last_name: enrolmentState.consent_participant_last_name,
    participant_signature: "",
    participant_illiterate: settingsState.participantIlliterate,
    guardian_consent: "",
    guardian_first_name: enrolmentState.consent_guardian_first_name,
    guardian_last_name: enrolmentState.consent_guardian_last_name,
    guardian_signature: "",
    guardian_illiterate: settingsState.parentIlliterate,
    chosen_language: _lang,
    age_category: settingsState.ageCat,
    created_date: getCreatedDateFormat(enrolmentState.created_date),
    created_time: enrolmentState.created_time,
    participant_country: _country,
    participant_consent_list: _participantConsents,
    participant_id: enrolmentState.participant_id,
    site_id:
      settingsState.loggedInUser !== null
        ? settingsState.loggedInUser.siteId
        : null,
  });

  const statusResults = await apiUpdateParticipantStatus({
    PK: `PARTICIPANT#${enrolmentState.participant_id}`,
    SK: enrolmentState.participant_id,
    participant_status: "UPDATED",
    consent_country: localStorage.getItem("i18nextLng").split("_")[1],
  });

  return results.success && statusResults.success;
};

export const saveFGDA1Status = async (
  participantId,
  enrolementId,
  recordStatus
) => {
  const results = await apiUpdateFGDA1Status(
    participantId,
    enrolementId,
    recordStatus
  );

  return results.success;
};

export const getFGDA1 = async (participantId, enrolmentId) => {
  const results = await apiGetFGDA1({
    PK: `PARTICIPANT#${participantId}`,
    SK: `FGDA1#${enrolmentId}`,
  });

  return results.success;
};
