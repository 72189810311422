import { API, graphqlOperation } from "aws-amplify";
import {
  createSubStudyKIIA3,
  updateSubStudyKIIA3Participant,
  updateSubStudyKIIA3Guardian,
  updateEnrolmentStatus,
} from "../../graphql/mutations";

export const apiCreateKIIA3 = async (participantId) => {
  let results = { success: true, body: {}, message: "" };

  const enrolmentData = {
    PK: `PARTICIPANT#${participantId}`,
    consent_country: localStorage.getItem("i18nextLng").split("_")[1],
  };

  try {
    const response = await API.graphql(
      graphqlOperation(createSubStudyKIIA3, {
        ConsentWithAudioTaping: enrolmentData,
      })
    );

    results.body = response.data.createSubStudyKIIA3;
  } catch (err) {
    results.success = false;
    results.message = "Error creating new record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateKIIA3Status = async (
  participantId,
  enrolementId,
  recordStatus
) => {
  let results = { success: true, body: {}, message: "" };

  const enrolmentData = {
    PK: `PARTICIPANT#${participantId}`,
    SK: `KIIA3#${enrolementId}`,
    record_status: recordStatus,
    consent_country: localStorage.getItem("i18nextLng").split("_")[1],
  };

  try {
    const response = await API.graphql(
      graphqlOperation(updateEnrolmentStatus, {
        ConsentWithLongStorage: enrolmentData,
      })
    );

    results.body = response.data.updateEnrolmentStatus;
  } catch (err) {
    results.success = false;
    results.message = "Error saving record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateKIIA3Participant = async (participantData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateSubStudyKIIA3Participant, {
        ConsentWithAudioTaping: participantData,
      })
    );

    results.body = response.data.updateSubStudyKIIA3Participant;
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateKIIA3Guardian = async (guardianData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateSubStudyKIIA3Guardian, {
        ConsentWithAudioTaping: guardianData,
      })
    );

    results.body = response.data.updateSubStudyKIIA3Guardian;
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};


