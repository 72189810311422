import React, { useState, useEffect } from "react";
import styles from "./FormControlPage.module.css";
import PreLoader from "../../../components/common/PreLoader";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import FormItem from "../../NewEnrolment/FormControlPage/FormItem";
import { useSelector, useDispatch } from "react-redux";
import {
  setAppTitle,
  setParticipantType,
} from "../../../store/slices/settingsSlice";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { apiCreateKIIA1 } from "../../../services/api/subStudyKIIA1Api.service";
import { apiCreateParticipant } from "../../../services/api/participantApi.service";
import {
  setParticipantId,
  setEnrolmentId,
} from "../../../store/slices/enrolmentSlice";
import { getUserSiteId } from "../../../services/users.service";
import { useTranslation } from "react-i18next";

const FormControlPage = ({ changeEnrolementStep }) => {
  const [t] = useTranslation("common");
  const [Loading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const enrolmentState = useSelector((state) => state.enrolment);
  const { isDeviceOnline, isReconsent } = useSelector(
    (state) => state.settings
  );
  const { enrolmentForms, ageCat, currentFormIndex } = useSelector(
    (state) => state.settings
  );

  let _pageType = "";

  if (enrolmentForms) {
    _pageType = enrolmentForms[currentFormIndex]?.type;
  }

  const saveAndReview = async () => {
    history.push({ pathname: "/kiia1-review-and-submit" });
  };

  const onFormStart = () => {
    let _enrolmentForms = JSON.parse(JSON.stringify(enrolmentForms));
    const _pageName = _enrolmentForms[currentFormIndex].name;
    const _participantType = _enrolmentForms[currentFormIndex].type;

    _enrolmentForms[currentFormIndex].isStarted = true;

    dispatch(setParticipantType(_participantType));

    if (_pageName.indexOf("_AOU") > 0) {
      changeEnrolementStep(7);
    } else {
      changeEnrolementStep(6);
    }
  };

  const completedCount = (formType) => {
    if (enrolmentForms) {
      return enrolmentForms.filter(
        (item) => item.type === formType && item.isDone === true
      ).length;
    } else {
      return 0;
    }
  };

  const [noOfKeyInformantCompleted, setNoOfKeyInformantCompleted] = useState(
    completedCount("KEY_INFORMANT")
  );

  useEffect(() => {
    setNoOfKeyInformantCompleted(completedCount("KEY_INFORMANT"));
  }, [enrolmentForms]);

  const createNewEnrolment = async (participantId) => {
    const results = await apiCreateKIIA1(participantId);
    if (results.success) {
      const enrolementId = results.body;
      dispatch(setEnrolmentId(enrolementId));
    }
  };

  const createNewParticipant = async () => {
    const siteId = await getUserSiteId();
    const results = await apiCreateParticipant(siteId);

    if (results.success) {
      const participantId = results.body;
      dispatch(setParticipantId(participantId));
      createNewEnrolment(participantId);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (_pageType === "KEY_INFORMANT") {
      if (
        enrolmentState.participant_id === null &&
        isDeviceOnline &&
        isReconsent === false
      ) {
        createNewParticipant();
      } else if (isReconsent) {
        createNewEnrolment(enrolmentState.participant_id);
      }
    }

    dispatch(setAppTitle("KEY-INFORMANT INTERVIEW - AIM 1"));
  }, []);

  const btnReviewSubmit = (isDisabled) => {
    return (
      <Button
        disabled={isDisabled}
        variant="contained"
        sx={{ minWidth: "300px" }}
        onClick={saveAndReview}
      >
        {t("label_review_and_submit")}
      </Button>
    );
  };

  return (
    <>
      <Container maxWidth="md">
        <>
          {ageCat === 6 && (
            <>
              <div className={styles.pageBody}>
                <div>
                  <h2>{t("participant_consent_list_title")}</h2>
                </div>
                <div>
                  {enrolmentForms
                    .filter((item) => item.type === "KEY_INFORMANT")
                    .map((form, index) => (
                      <FormItem
                        key={`formItem_${form.id}`}
                        isActive={form.isCurrent}
                        isCompleted={form.isDone}
                        formTitle={form.formName}
                        onStart={() => {
                          onFormStart();
                        }}
                        onEdit={() => {
                          onFormStart();
                        }}
                        count={index + 1}
                      />
                    ))}
                  <div className={styles.formSummary}>
                    <div className={styles.formSummaryCompleted}>
                      {t("label_completed")}: {noOfKeyInformantCompleted}
                    </div>
                    <div className={styles.formSummaryPending}>
                      {t("consent_list_pending_label")}:{" "}
                      {1 - noOfKeyInformantCompleted}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div
            style={{
              textAlign: "center",
              width: "100%",
              marginBottom: "50px",
            }}
          >
            {_pageType === "KEY_INFORMANT" &&
              btnReviewSubmit(noOfKeyInformantCompleted !== 1)}
          </div>
        </>
      </Container>
      <PreLoader show={Loading} />
    </>
  );
};

FormControlPage.propTypes = {
  changeEnrolementStep: PropTypes.func,
};

export default FormControlPage;
