import React, { useState } from "react";
import styles from "./StepIntroduction.module.css";
import PreLoader from "../../../components/common/PreLoader";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ConsentMinorParentIntro from "./ConsentMinorParentIntro";
import { useSelector, useDispatch } from "react-redux";
import ConsentMinorParticipantIntro from "./ConsentMinorParticipantIntro";
import ConsentAdultParticipantIntro from "./ConsentAdultParticipantIntro";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTranslation } from "react-i18next";
import ConsentCommunityParticipantIntro from "./ConsentCommunityParticipantIntro";
import { setAuditInfo } from "../../../store/slices/settingsSlice";

const StepIntroduction = ({ changeEnrolementStep, handleNext }) => {
  const [t] = useTranslation("common");
  const [Loading] = useState(false);
  const [activeStep] = useState(1);
  const [readAndAccepted, setReadAndAccepted] = useState(false);
  const enrolmentForms = useSelector((state) => state.settings.enrolmentForms);
  const { currentFormIndex, auditInfo } = useSelector(
    (state) => state.settings
  );
  const dispatch = useDispatch();

  const pageName = enrolmentForms ? enrolmentForms[currentFormIndex].name : "";

  const handleBack = () => {
    changeEnrolementStep(5);
  };

  const onReadAndAccept = (event) => {
    const _auditInfo = [...auditInfo];
    let _info = event.target.checked ? "Acknowledged" : "Unacknowledged";
    let _user = "";

    if (pageName === "MINOR_PARENT_CONSENT") {
      _user = "Minor parent";
      _info = `${_info} focus group discussions - aim 1 consent introduction text.`;
    }

    if (pageName === "MINOR_PARTICIPANT_CONSENT") {
      _user = "Minor participant";
      _info = `${_info} focus group discussions - aim 1 consent introduction text.`;
    }

    if (pageName === "EMANCIPATED_MINOR_CONSENT") {
      _user = "Emancipated minor participant";
      _info = `${_info} focus group discussions - aim 1 consent introduction text.`;
    }

    if (pageName === "ADULT_CONSENT") {
      _user = "Emancipated minor participant";
      _info = `${_info} focus group discussions - aim 1 consent introduction text.`;
    }

    if (pageName === "PARENT_PARTICIPANT_CONSENT") {
      _user = "Emancipated minor participant";
      _info = `${_info} focus group discussions - aim 1 consent introduction text.`;
    }

    if (pageName === "COMMUNITY_MEMBER_CONSENT") {
      _user = "Emancipated minor participant";
      _info = `${_info} focus group discussions - aim 1 consent introduction text.`;
    }

    _auditInfo.push({
      user: _user,
      dateTime: new Date().toISOString(),
      info: _info,
    });

    dispatch(setAuditInfo(_auditInfo));

    setReadAndAccepted(event.target.checked);
  };

  return (
    <>
      <div className={styles.pageBox}>
        {pageName === "MINOR_PARENT_CONSENT" && <ConsentMinorParentIntro />}

        {pageName === "MINOR_PARTICIPANT_CONSENT" && (
          <ConsentMinorParticipantIntro />
        )}

        {(pageName === "EMANCIPATED_MINOR_CONSENT" ||
          pageName === "ADULT_CONSENT" ||
          pageName === "PARENT_PARTICIPANT_CONSENT") && (
          <ConsentAdultParticipantIntro />
        )}

        {pageName === "COMMUNITY_MEMBER_CONSENT" && (
          <ConsentCommunityParticipantIntro />
        )}

        <br />
        <FormControlLabel
          style={{ color: "var(--accepted-green-color)" }}
          control={
            <Checkbox checked={readAndAccepted} onChange={onReadAndAccept} />
          }
          label={t("intro_acknowledge_read_and_understood")}
        />
        <br />
      </div>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          variant="contained"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1, width: "140px" }}
        >
          {t("back")}
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          disabled={!readAndAccepted}
          variant="contained"
          sx={{ mr: 1, width: "140px" }}
          data-testid="go_next"
          onClick={handleNext}
        >
          {t("Continue")}
        </Button>
      </Box>
      <PreLoader show={Loading} />
    </>
  );
};

StepIntroduction.propTypes = {
  changeEnrolementStep: PropTypes.func,
  handleNext: PropTypes.func,
};

export default StepIntroduction;
