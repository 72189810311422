import { Link } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import React from "react";
import styles from "./AppFooter.module.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppFooter = () => {
  const [open, setOpen] = React.useState(false);
  const _v = "MAGY e-consent v1.173";
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={styles.appFooter}>
        <Link onClick={handleClickOpen}>{_v}</Link>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          maxWidth={"md"}
        >
          <DialogTitle>{_v}</DialogTitle>
          <DialogContent>
            <div className={styles.AppFooterContent}>
              <h3>Primary Study - Adult and emancipated minor</h3>
              <ul>
                <li>
                  <strong>Main Study</strong>: 005_MAGY_Main Informed Consent
                  Form_Master_v1.0 dd 05 Nov 22 Clean_final.docx
                </li>
                <li>
                  <strong>Long Term Storage, Genetic & Future Testing</strong>:
                  005_MAGY_Long Term Storage, Genetic & Future Testing Informed
                  Consent Form v1.0 dd 05 Nov 22_Master_Clean_final.docx
                </li>
                <li>
                  <strong>Assessment of Understanding</strong>:009_Assessment of
                  Understanding_Clean.docx
                </li>
              </ul>

              <h3>Primary Study - Age 15 to 17 - Parent/Legal Guardian</h3>
              <ul>
                <li>
                  <strong>Main Study</strong>: 007_MAGY_Main Parental Consent
                  Form v1.0 dd 05 Nov 22_Clean_final.docx
                </li>
                <li>
                  <strong>Long Term Storage, Genetic & Future Testing</strong>:
                  007_MAGY_Long Term Storage, Genetic & Future Testing Parental
                  Consent Form v1.0 dd 05 Nov 22_Master_Clean_final.docx
                </li>
                <li>
                  <strong>Assessment of Understanding</strong>: 009_Assessment
                  of Understanding_Clean.docx
                </li>
              </ul>
              <h3>Primary Study - Age 15 to 17 - Participant</h3>
              <ul>
                <li>
                  <strong>Main Study</strong>: 006_MAGY_Main Informed Assent
                  Form v1.0 dd 05 Nov 22_Clean_final.docx
                </li>
                <li>
                  <strong>Long Term Storage, Genetic & Future Testing</strong>:
                  006_MAGY_Long Term Storage, Genetic & Future Testing Informed
                  Assent Form v1.0 dd 05 Nov 22_Master_Clean_final.docx
                </li>
                <li>
                  <strong>Assessment of Understanding</strong>: 009_Assessment
                  of Understanding_Clean.docx
                </li>
              </ul>

              <h3>Primary Study - Emancipated Minor / Adult 18+</h3>
              <ul>
                <li>
                  <strong>Main Study</strong>: 005_MAGY_Main Informed Consent
                  Form_Master_v1.0 dd 05 Nov 22 Clean_final.docx
                </li>
                <li>
                  <strong>Long Term Storage, Genetic & Future Testing</strong>:
                  005_MAGY_Long Term Storage, Genetic & Future Testing Informed
                  Consent Form v1.0 dd 05 Nov 22_Master_Clean_final.docx
                </li>
                <li>
                  <strong>Assessment of Understanding</strong>: 009_Assessment
                  of Understanding_Clean.docx
                </li>
              </ul>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={handleClose}>Agree</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default AppFooter;
