import React, { useState, useEffect } from "react";
import styles from "./GenTestMinorParticipantView.module.css";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import SignatureImageView from "../../../../components/SignatureImageView";
import { useTranslation } from "react-i18next";

const GenTestMinorParticipantView = () => {
  const [t] = useTranslation("common");
  const { participantIlliterate } = useSelector((state) => state.settings);
  const enrolmentState = useSelector((state) => state.enrolment);
  const [participantConsentObj, setParticipantConsentObj] = useState({});

  useEffect(() => {
    setParticipantConsentObj({
      participantFirstName: enrolmentState.gen_test_participant_first_name,
      participantLastName: enrolmentState.gen_test_participant_last_name,
      consentDate: enrolmentState.gen_test_participant_date,
      consentTime: enrolmentState.gen_test_participant_time,
      signature: enrolmentState.gen_test_participant_signature,
    });
  }, [enrolmentState]);

  return (
    <div className={styles.pageViewBox}>
      <h2 className={styles.intro}>ASSENT FORM FOR GENETIC TESTING</h2>

      <div className={styles.consentText}>
        <ol>
          <li>
            {t("GT_minor_participant_giving_consent_1")}{" "}
            <strong>
              ({enrolmentState.gen_test_participant_consents.consent1})
            </strong>
          </li>
          <li>
            {t("GT_minor_participant_giving_consent_2")}{" "}
            <strong>
              ({enrolmentState.gen_test_participant_consents.consent2})
            </strong>
          </li>
        </ol>
        <p>{t("GT_minor_participant_giving_consent_p1")}</p>
      </div>

      <div className={styles.consentForm}>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_participant_first_name")} </label>
                <div className={styles.fieldText}>
                  {participantConsentObj.participantFirstName}
                </div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_participant_last_name")} </label>
                <div className={styles.fieldText}>
                  {participantConsentObj.participantLastName}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>
                  {t("label_participant")}{" "}
                  {participantIlliterate ? "acknowledgement" : "Signature"}
                </label>
                <SignatureImageView
                  signature={participantConsentObj.signature}
                />
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_date")}</label>
                <div className={styles.fieldText}>
                  {participantConsentObj.consentDate}
                </div>
              </FormControl>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_time")}</label>
                <div className={styles.fieldText}>
                  {participantConsentObj.consentTime}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default GenTestMinorParticipantView;
