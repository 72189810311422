import React from "react";
import PropTypes from "prop-types";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

const ProgressBar = ({ value }) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 17,
    borderRadius: 20,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#00A100" : "#308fe8",
    },
  }));

  return <BorderLinearProgress variant="determinate"  value={value} />;
};

ProgressBar.propTypes = {
  value: PropTypes.number,
};

export default ProgressBar;
