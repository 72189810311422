import React, { useEffect, useState } from "react";
import styles from "./CheckParentLiteracy.module.css";
import PreLoader from "../../../components/common/PreLoader";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import OptionButtons from "../../../components/common/OptionButtons";
import { useDispatch } from "react-redux";
import { setParentIlliterate } from "../../../store/slices/settingsSlice";

const CheckParentLiteracy = ({ changeEnrolementStep }) => {
  const [Loading] = useState(false);
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const [btns, setBtns] = useState([
    {
      id: 1,
      label: "Yes",
      isSelected: false,
    },
    {
      id: 2,
      label: "No",
      isSelected: false,
    },
  ]);

  useEffect(() => {
    if (btns[0].isSelected || btns[1].isSelected) {
      changeEnrolementStep(4);
      dispatch(setParentIlliterate(btns[0].isSelected));
    }
  }, [btns]);

  return (
    <>
      <div className={styles.pageBody}>
        <div>
          <h2>{t("parent_guardian_illiterate_question")}</h2>
        </div>
        <div>
          <OptionButtons buttons={btns} onClicked={setBtns} width="100px" />
        </div>
      </div>
      <PreLoader show={Loading} />
    </>
  );
};

CheckParentLiteracy.propTypes = {
  changeEnrolementStep: PropTypes.func,
};

export default CheckParentLiteracy;
