/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import styles from "./FindParticipant.module.css";
import PreLoader from "../../components/common/PreLoader";
import { useTranslation } from "react-i18next";
import { setEnrolmentForms } from "../../store/slices/settingsSlice";
import { mainStudyFormsData } from "../../data/main_study_consent_forms";
import { useDispatch } from "react-redux";
import { setAppTitle, setIsReconsent } from "../../store/slices/settingsSlice";
import { format } from "date-fns";
import { setEnrolmentDateTime } from "../../store/slices/enrolmentSlice";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { useFormik } from "formik";
import { findParticipant } from "../../services/participant.service";
import { useSnackbar } from "notistack";
import { setParticipantId } from "../../store/slices/enrolmentSlice";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { red } from "@mui/material/colors";
import { FGDA1AgeCatsList } from "../../data/age_cats_data";
import {
  setParticipantIlliterate,
  setParentIlliterate,
  setParticipantConsents,
} from "../../store/slices/settingsSlice";
import { ageCatsArray } from "../../data/age_cats_data";

const FindParticipant = ({ handleParticipantSelect }) => {
  const [t] = useTranslation("common");
  const [Loading, setLoading] = useState(false);
  const { loggedInUser, ageCat, studyName } = useSelector(
    (state) => state.settings
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [participantsData, setParticipantsData] = useState([]);

  const handleSubmit = async (values) => {
    setLoading(true);

    const _results = await findParticipant({
      participantId: values.participantId.toString().toUpperCase(),
      participantFirstName: values.firstName,
      participantLastName: values.lastName,
      guadianFirstName: "",
      guadianLastName: "",
      ageCat: ageCat.toString(),
      siteId: loggedInUser.siteId,
    });

    setLoading(false);
    if (_results.success) {
      setParticipantsData(_results.body);
    } else {
      enqueueSnackbar("No records found matching your search criteria.", {
        variant: "info",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      participantId: "",
      ageCat: ageCat,
      firstName: "",
      lastName: "",
      siteId: loggedInUser.siteId,
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useMemo(() => {
    if (loggedInUser === null) {
      history.push({ pathname: "/login" });
    }
  }, []);

  useEffect(() => {
    // dispatch(setAppTitle(t("Find Participant")));
    dispatch(
      setEnrolmentDateTime({
        created_date: format(new Date(), "dd/MM/yyyy"),
        created_time: format(new Date(), "H:mm"),
      })
    );
  }, []);

  const selectParticipant = (participant) => {
    dispatch(setParentIlliterate(participant.guardian_illiterate === "true"));
    dispatch(
      setParticipantIlliterate(participant.participant_illiterate === "true")
    );
    dispatch(setParticipantConsents(participant.participant_consent_list));

    handleParticipantSelect(participant);
  };

  return (
    <>
      <>
        <div className={styles.findParticipantBox}>
          <div className={styles.pageTitleBox}>
            <h3 style={{ marginBottom: "0px" }}>Find Participant By</h3>
            <p style={{ marginTop: "6px" }}>
              Please enter (Participant ID And/Or First Name And/Or Last Name)
              below to search{" "}
            </p>
          </div>
          <div className={styles.loginBody}>
            <form onSubmit={formik.handleSubmit} style={{ marginTop: "0px" }}>
              <Grid container spacing={2}>
                <Grid item md={4} lg={4} xs={6}>
                  <div className="form-field">
                    <label>Participant ID</label>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="participantId"
                      name="participantId"
                      autoFocus
                      placeholder={"Participant ID"}
                      value={formik.values.participantId}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.participantId &&
                        Boolean(formik.errors.participantId)
                      }
                      helperText={
                        formik.touched.participantId &&
                        formik.errors.participantId
                      }
                    />
                  </div>
                </Grid>
                <Grid item md={4} lg={4} xs={6}>
                  <div className="form-field">
                    <label>First Name</label>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="firstName"
                      name="firstName"
                      autoFocus
                      placeholder={"First Name"}
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </div>
                </Grid>
                <Grid item md={4} lg={4} xs={6}>
                  <div className="form-field">
                    <label>Last Name</label>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="lastName"
                      name="lastName"
                      autoFocus
                      placeholder={"Last Name"}
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </div>
                </Grid>

                <Grid item md={4} lg={4} xs={6}>
                  <div className="form-field">
                    <label>Site ID</label>
                    <TextField
                      disabled
                      margin="normal"
                      fullWidth
                      id="siteId"
                      name="siteId"
                      autoFocus
                      placeholder={"Site ID"}
                      value={formik.values.siteId}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.siteId && Boolean(formik.errors.siteId)
                      }
                      helperText={formik.touched.siteId && formik.errors.siteId}
                    />
                  </div>
                </Grid>
                <Grid item md={4} lg={4} xs={6}>
                  <div className="form-field">
                    <label>Age Category</label>
                    <TextField
                      disabled
                      margin="normal"
                      fullWidth
                      id="ageCat"
                      name="ageCat"
                      autoFocus
                      placeholder={"Age Category"}
                      value={t(
                        FGDA1AgeCatsList[parseInt(formik.values.ageCat) - 1]
                          .title
                      )}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.ageCat && Boolean(formik.errors.ageCat)
                      }
                      helperText={formik.touched.ageCat && formik.errors.ageCat}
                    />
                  </div>
                </Grid>

                <Grid item md={4} lg={4} xs={6}>
                  <div style={{ marginTop: "25px" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      disabled={!formik.dirty}
                    >
                      Search
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
        {participantsData.length > 0 && (
          <div className={styles.findParticipantResults}>
            <div style={{ marginBottom: "20px" }}>
              <h2>Search Results</h2>
              <p>
                Total of {participantsData.length} Participant records found.
              </p>
            </div>
            <Grid container spacing={4}>
              {participantsData.length > 0 &&
                participantsData.map((participant) => (
                  <>
                    <Grid
                      item
                      xs={4}
                      key={`participant_${participant.participant_id}`}
                    >
                      <Card elevation={3} style={{ borderRadius: "20px" }}>
                        <CardHeader
                          avatar={
                            <Avatar
                              sx={{
                                bgcolor: red[500],
                                textTransform: "uppercase",
                              }}
                              aria-label="recipe"
                            >
                              {participant.participant_first_name !== null &&
                                participant.participant_first_name[0]}
                              {participant.participant_last_name !== null &&
                                participant.participant_last_name[0]}
                            </Avatar>
                          }
                          title={`${participant.participant_first_name} ${participant.participant_last_name}`}
                          subheader={participant.participant_id}
                        />
                        <CardContent
                          style={{ paddingTop: "0px", paddingBottom: "0px" }}
                        >
                          <p>
                            <strong>Site ID:</strong> {participant.site_id}
                          </p>
                          <p>
                            <strong>Name:</strong>{" "}
                            {participant.participant_first_name}{" "}
                            {participant.participant_last_name} (
                            {participant.participant_illiterate ? "Yes" : "No"})
                          </p>

                          {participant.age_category === 1 && (
                            <p>
                              <strong>Parent:</strong>{" "}
                              {`${participant.guardian_first_name} ${participant.guardian_last_name}`}{" "}
                              ({participant.guardian_illiterate ? "Yes" : "No"})
                            </p>
                          )}

                          <p>
                            <strong>Age Category:</strong>{" "}
                            {t(ageCatsArray[participant.age_category])}
                          </p>
                        </CardContent>
                        <div
                          style={{
                            textAlign: "center",
                            paddingTop: "10px",
                            color: "var(--primary-color)",
                            fontWeight: "bold",
                          }}
                        >
                          {participant.participant_consent_list.indexOf(
                            studyName
                          ) > 0 && <>Already consented.</>}
                        </div>
                        <CardActions>
                          <Button
                            disabled={
                              participant.participant_consent_list.indexOf(
                                studyName
                              ) > 0
                            }
                            variant="outlined"
                            fullWidth
                            size="small"
                            sx={{ ml: 1, mr: 1, color: "grey" }}
                            onClick={() => selectParticipant(participant)}
                          >
                            Select Participant
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  </>
                ))}
            </Grid>
          </div>
        )}
      </>

      <PreLoader show={Loading} />
    </>
  );
};

export default FindParticipant;
