const LanguageList = [
  {
    country: "Zambia",
    countryCode: "ZM",
    languageName: "English",
    languageCode: "en_ZM",
  },
  {
    country: "South Africa",
    countryCode: "ZA",
    languageName: "English",
    languageCode: "en_ZA",
  },
  {
    country: "South Africa",
    countryCode: "ZA",
    languageName: "Setswana",
    languageCode: "tn_ZA",
  },
  {
    country: "South Africa",
    countryCode: "ZA",
    languageName: "Xhosa",
    languageCode: "xh_ZA",
  },
  {
    country: "Zambia",
    countryCode: "ZM",
    languageName: "Bemba",
    languageCode: "bem_ZM",
  },
  {
    country: "Zambia",
    countryCode: "ZM",
    languageName: "Nyanja",
    languageCode: "ny_ZM",
  },
  {
    country: "Uganda",
    countryCode: "UG",
    languageName: "English",
    languageCode: "en_UG",
  },
  {
    country: "Uganda",
    countryCode: "UG",
    languageName: "Luganda",
    languageCode: "lg_UG",
  },
];

export default LanguageList;
