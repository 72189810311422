import React from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import Link from "@mui/material/Link";
import styles from "./UnauthorisedPage.module.css";

const UnauthorisedPage = () => {

  return (
    <>
      <AppHeader isGuestMode={true} />
      <div className={styles.pageBox}>
        <div className={styles.pageTitleBox}>
          <h1 style={{ color: "red" }}>Unauthorised Access</h1>
        </div>
        <div className={styles.pageBoxBody}>
          <div style={{ textAlign: "center" }}>
            <p>
              Your account does not have the access to this page. Please speak
              to your administrator.{" "}
            </p>
            <Link href="/login" variant="body2">
              Back to log in.
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnauthorisedPage;
