import React, { useEffect, useState } from "react";
import PreLoader from "../../../components/common/PreLoader";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import {
  saveParticipantUserData,
  saveEnrolmentParticipantData,
  saveEnrolmentGuardianData,
  saveEnrolmentStatus,
} from "../../../services/enrolment.service";
import {
  saveFGDA1ParticipantData,
  saveFGDA1GuardianData,
  saveFGDA1Status,
  saveFGDA1ParticipantUserData,
} from "../../../services/fgda1.service";
import {
  saveFGDA3ParticipantData,
  saveFGDA3GuardianData,
  saveFGDA3Status,
  saveFGDA3ParticipantUserData,
} from "../../../services/fgda3.service";
import { setTriggerEvent } from "../../../store/slices/settingsSlice";
import { setOfflineEnrolments } from "../../../store/slices/offlineDataSlice";
import { useTranslation } from "react-i18next";
import {
  apiUpdateAuditInfo,
  apiUpdateGuardianICFInfo,
  apiUpdateParticipantICFInfo,
} from "../../../services/api/enrolmentApi.service";
import {
  icf_zambia_adult_participant,
  icf_zambia_minor_parent,
  icf_zambia_minor_participant,
} from "../../../data/ICF_info";
import {
  saveIDIA1ParticipantData,
  saveIDIA1GuardianData,
  saveIDIA1Status,
  saveIDIA1ParticipantUserData,
} from "../../../services/idia1.service";
import {
  saveIDIA3ParticipantData,
  saveIDIA3GuardianData,
  saveIDIA3Status,
  saveIDIA3ParticipantUserData,
} from "../../../services/idia3.service";
import {
  saveKIIA1ParticipantData,
  saveKIIA1Status,
  saveKIIA1ParticipantUserData,
} from "../../../services/kiia1.service";
import {
  saveKIIA3ParticipantData,
  saveKIIA3Status,
  saveKIIA3ParticipantUserData,
} from "../../../services/kiia3.service";
import {
  saveICParticipantData,
  saveICGuardianData,
  saveICStatus,
  saveICParticipantUserData,
} from "../../../services/ic.service";
import {
  saveILParticipantData,
  saveILGuardianData,
  saveILStatus,
  saveILParticipantUserData,
} from "../../../services/il.service";

const HandleSaveEnrolment = () => {
  const [t] = useTranslation("common");
  const [Loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    enrolmentForms,
    currentFormIndex,
    triggerEvent,
    recordStatus,
    isDeviceOnline,
  } = useSelector((state) => state.settings);

  const enrolmentState = useSelector((state) => state.enrolment);
  const settingsState = useSelector((state) => state.settings);
  const { offlineEnrolments } = useSelector((state) => state.offlineData);
  const { guardianResults, participantResults } = useSelector(
    (state) => state.aou
  );

  const showSnackBar = (data) => {
    enqueueSnackbar(data.message, {
      variant: data.variant,
    });
  };

  const _showSuccessMessage = (success) => {
    showSnackBar({
      message: success
        ? t("label_enrolment_updated_success")
        : t("label_system_error"),
      variant: "success",
    });
  };

  const saveEnrolment = async (status) => {
    if (currentFormIndex === null) {
      history.push("/home");
    }

    const _pageType =
      currentFormIndex === 0
        ? enrolmentForms[currentFormIndex].type
        : enrolmentForms[currentFormIndex - 1].type;

    setLoading(true);

    const participantUpdateResults = await saveParticipantUserData(
      enrolmentState,
      settingsState,
      isDeviceOnline
    );

    const saveEnrolmentStatusSuccess = await saveEnrolmentStatus(
      enrolmentState.participant_id,
      enrolmentState.enrolment_id,
      recordStatus
    );

    if (_pageType === "MINOR_PARENT" || _pageType === "MINOR_PARTICIPANT") {
      const updateGuardianSuccess = await saveEnrolmentGuardianData(
        enrolmentState,
        settingsState,
        guardianResults,
        isDeviceOnline
      );
      const updateParticipantSuccess = await saveEnrolmentParticipantData(
        enrolmentState,
        settingsState,
        participantResults,
        isDeviceOnline
      );

      await apiUpdateGuardianICFInfo({
        SK: `ENROLMENT#${enrolmentState.enrolment_id}`,
        consent_country: localStorage.getItem("i18nextLng").split("_")[1],
        guardian_icf_signed_date: icf_zambia_minor_parent.icf_signed_date,
        guardian_icf_type_version_no:
          icf_zambia_minor_parent.icf_type_version_no,
        guardian_icf_version_date: icf_zambia_minor_parent.icf_version_date,
        participant_id: enrolmentState.participant_id,
      });

      await apiUpdateParticipantICFInfo({
        SK: `ENROLMENT#${enrolmentState.enrolment_id}`,
        consent_country: localStorage.getItem("i18nextLng").split("_")[1],
        participant_icf_signed_date:
          icf_zambia_minor_participant.icf_signed_date,
        participant_icf_type_version_no:
          icf_zambia_minor_participant.icf_type_version_no,
        participant_icf_version_date:
          icf_zambia_minor_participant.icf_version_date,
        participant_id: enrolmentState.participant_id,
      });

      setLoading(false);

      _showSuccessMessage(
        updateGuardianSuccess &&
          updateParticipantSuccess &&
          participantUpdateResults &&
          saveEnrolmentStatusSuccess
      );
    }

    if (_pageType === "EMANCIPATED_MINOR" || _pageType === "ADULT_18_PLUS") {
      const updateParticipantSuccess = await saveEnrolmentParticipantData(
        enrolmentState,
        settingsState,
        participantResults,
        isDeviceOnline
      );

      await apiUpdateParticipantICFInfo({
        SK: `ENROLMENT#${enrolmentState.enrolment_id}`,
        consent_country: localStorage.getItem("i18nextLng").split("_")[1],
        participant_icf_signed_date:
          icf_zambia_adult_participant.icf_signed_date,
        participant_icf_type_version_no:
          icf_zambia_adult_participant.icf_type_version_no,
        participant_icf_version_date:
          icf_zambia_adult_participant.icf_version_date,
        participant_id: enrolmentState.participant_id,
      });

      setLoading(false);

      _showSuccessMessage(
        updateParticipantSuccess &&
          participantUpdateResults &&
          saveEnrolmentStatusSuccess
      );
    }

    updateAuditInfoAndRedirect("ENROLMENT", status);
  };

  const saveEnrolmentOffline = async (status) => {
    if (currentFormIndex === null) {
      history.push("/home");
    } else {
      setLoading(true);

      const _enrolmentState = JSON.parse(JSON.stringify(enrolmentState));
      const _settingsState = JSON.parse(JSON.stringify(settingsState));
      const _guardianResults = JSON.parse(JSON.stringify(guardianResults));
      const _participantResults = JSON.parse(
        JSON.stringify(participantResults)
      );
      const _enrolmentData = {
        enrolment: _enrolmentState,
        settings: _settingsState,
        aouGuardianResults: _guardianResults,
        aouParticipantResults: _participantResults,
      };

      const _offlineEnrolments = JSON.parse(JSON.stringify(offlineEnrolments));
      _offlineEnrolments.push(_enrolmentData);
      dispatch(setOfflineEnrolments(_offlineEnrolments));

      dispatch(setTriggerEvent(""));
      _showSuccessMessage(true);

      setTimeout(() => {
        if (
          status === "SAVE_ENROLMENT_PASSED" ||
          status === "SAVE_ENROLMENT_FAILED"
        ) {
          setLoading(false);
          history.push("/home");
        }
      }, 1000);
    }
  };

  const saveFGDA1 = async (status) => {
    if (currentFormIndex === null) {
      history.push("/home");
    }

    const _pageType =
      currentFormIndex === 0
        ? enrolmentForms[currentFormIndex].type
        : enrolmentForms[currentFormIndex - 1].type;

    setLoading(true);

    const participantUpdateResults = await saveFGDA1ParticipantUserData(
      enrolmentState,
      settingsState,
      isDeviceOnline
    );

    const saveFGDA1StatusSuccess = await saveFGDA1Status(
      enrolmentState.participant_id,
      enrolmentState.enrolment_id,
      recordStatus
    );

    if (_pageType === "MINOR_PARENT" || _pageType === "MINOR_PARTICIPANT") {
      const updateGuardianSuccess = await saveFGDA1GuardianData(
        enrolmentState,
        settingsState,
        isDeviceOnline
      );
      const updateParticipantSuccess = await saveFGDA1ParticipantData(
        enrolmentState,
        settingsState,
        isDeviceOnline
      );
      setLoading(false);

      _showSuccessMessage(
        updateGuardianSuccess &&
          updateParticipantSuccess &&
          participantUpdateResults &&
          saveFGDA1StatusSuccess
      );
    }

    if (
      _pageType === "EMANCIPATED_MINOR" ||
      _pageType === "ADULT_18_PLUS" ||
      _pageType === "COMMUNITY_MEMBERS" ||
      _pageType === "PARENT_PARTICIPANT"
    ) {
      const updateParticipantSuccess = await saveFGDA1ParticipantData(
        enrolmentState,
        settingsState,
        isDeviceOnline
      );
      setLoading(false);

      _showSuccessMessage(
        updateParticipantSuccess &&
          participantUpdateResults &&
          saveFGDA1StatusSuccess
      );
    }

    updateAuditInfoAndRedirect("FGDA1", status);
  };

  const saveFGDA3 = async (status) => {
    if (currentFormIndex === null) {
      history.push("/home");
    }

    const _pageType =
      currentFormIndex === 0
        ? enrolmentForms[currentFormIndex].type
        : enrolmentForms[currentFormIndex - 1].type;

    setLoading(true);

    const participantUpdateResults = await saveFGDA3ParticipantUserData(
      enrolmentState,
      settingsState,
      isDeviceOnline
    );

    const saveFGDA3StatusSuccess = await saveFGDA3Status(
      enrolmentState.participant_id,
      enrolmentState.enrolment_id,
      recordStatus
    );

    if (_pageType === "MINOR_PARENT" || _pageType === "MINOR_PARTICIPANT") {
      const updateGuardianSuccess = await saveFGDA3GuardianData(
        enrolmentState,
        settingsState,
        isDeviceOnline
      );
      const updateParticipantSuccess = await saveFGDA3ParticipantData(
        enrolmentState,
        settingsState,
        isDeviceOnline
      );
      setLoading(false);

      _showSuccessMessage(
        updateGuardianSuccess &&
          updateParticipantSuccess &&
          participantUpdateResults &&
          saveFGDA3StatusSuccess
      );
    }

    if (
      _pageType === "EMANCIPATED_MINOR" ||
      _pageType === "ADULT_18_PLUS" ||
      _pageType === "COMMUNITY_MEMBERS" ||
      _pageType === "PARENT_PARTICIPANT"
    ) {
      const updateParticipantSuccess = await saveFGDA3ParticipantData(
        enrolmentState,
        settingsState,
        isDeviceOnline
      );
      setLoading(false);

      _showSuccessMessage(
        updateParticipantSuccess &&
          participantUpdateResults &&
          saveFGDA3StatusSuccess
      );
    }

    updateAuditInfoAndRedirect("FGDA3", status);
  };

  const saveIDIA1 = async (status) => {
    if (currentFormIndex === null) {
      history.push("/home");
    }

    const _pageType =
      currentFormIndex === 0
        ? enrolmentForms[currentFormIndex].type
        : enrolmentForms[currentFormIndex - 1].type;

    setLoading(true);

    const participantUpdateResults = await saveIDIA1ParticipantUserData(
      enrolmentState,
      settingsState,
      isDeviceOnline
    );

    const saveIDIA1StatusSuccess = await saveIDIA1Status(
      enrolmentState.participant_id,
      enrolmentState.enrolment_id,
      recordStatus
    );

    if (_pageType === "MINOR_PARENT" || _pageType === "MINOR_PARTICIPANT") {
      const updateGuardianSuccess = await saveIDIA1GuardianData(
        enrolmentState,
        settingsState,
        isDeviceOnline
      );
      const updateParticipantSuccess = await saveIDIA1ParticipantData(
        enrolmentState,
        settingsState,
        isDeviceOnline
      );
      setLoading(false);

      _showSuccessMessage(
        updateGuardianSuccess &&
          updateParticipantSuccess &&
          participantUpdateResults &&
          saveIDIA1StatusSuccess
      );
    }

    if (
      _pageType === "EMANCIPATED_MINOR" ||
      _pageType === "ADULT_18_PLUS" ||
      _pageType === "COMMUNITY_MEMBERS" ||
      _pageType === "PARENT_PARTICIPANT"
    ) {
      const updateParticipantSuccess = await saveIDIA1ParticipantData(
        enrolmentState,
        settingsState,
        isDeviceOnline
      );
      setLoading(false);

      _showSuccessMessage(
        updateParticipantSuccess &&
          participantUpdateResults &&
          saveIDIA1StatusSuccess
      );
    }

    updateAuditInfoAndRedirect("IDIA1", status);
  };

  const saveIDIA3 = async (status) => {
    if (currentFormIndex === null) {
      history.push("/home");
    }

    const _pageType =
      currentFormIndex === 0
        ? enrolmentForms[currentFormIndex].type
        : enrolmentForms[currentFormIndex - 1].type;

    setLoading(true);

    const participantUpdateResults = await saveIDIA3ParticipantUserData(
      enrolmentState,
      settingsState,
      isDeviceOnline
    );

    const saveIDIA3StatusSuccess = await saveIDIA3Status(
      enrolmentState.participant_id,
      enrolmentState.enrolment_id,
      recordStatus
    );

    if (_pageType === "MINOR_PARENT" || _pageType === "MINOR_PARTICIPANT") {
      const updateGuardianSuccess = await saveIDIA3GuardianData(
        enrolmentState,
        settingsState,
        isDeviceOnline
      );
      const updateParticipantSuccess = await saveIDIA3ParticipantData(
        enrolmentState,
        settingsState,
        isDeviceOnline
      );
      setLoading(false);

      _showSuccessMessage(
        updateGuardianSuccess &&
          updateParticipantSuccess &&
          participantUpdateResults &&
          saveIDIA3StatusSuccess
      );
    }

    if (
      _pageType === "EMANCIPATED_MINOR" ||
      _pageType === "ADULT_18_PLUS" ||
      _pageType === "COMMUNITY_MEMBERS" ||
      _pageType === "PARENT_PARTICIPANT"
    ) {
      const updateParticipantSuccess = await saveIDIA3ParticipantData(
        enrolmentState,
        settingsState,
        isDeviceOnline
      );
      setLoading(false);

      _showSuccessMessage(
        updateParticipantSuccess &&
          participantUpdateResults &&
          saveIDIA3StatusSuccess
      );
    }

    updateAuditInfoAndRedirect("IDIA3", status);
  };

  const saveKIIA1 = async (status) => {
    if (currentFormIndex === null) {
      history.push("/home");
    }

    const _pageType =
      currentFormIndex === 0
        ? enrolmentForms[currentFormIndex].type
        : enrolmentForms[currentFormIndex - 1].type;

    setLoading(true);

    const participantUpdateResults = await saveKIIA1ParticipantUserData(
      enrolmentState,
      settingsState,
      isDeviceOnline
    );

    const saveKIIA1StatusSuccess = await saveKIIA1Status(
      enrolmentState.participant_id,
      enrolmentState.enrolment_id,
      recordStatus
    );

    if (_pageType === "KEY_INFORMANT") {
      const updateParticipantSuccess = await saveKIIA1ParticipantData(
        enrolmentState,
        settingsState,
        isDeviceOnline
      );
      setLoading(false);

      _showSuccessMessage(
        updateParticipantSuccess &&
          participantUpdateResults &&
          saveKIIA1StatusSuccess
      );
    }

    updateAuditInfoAndRedirect("KIIA1", status);
  };

  const saveKIIA3 = async (status) => {
    if (currentFormIndex === null) {
      history.push("/home");
    }

    const _pageType =
      currentFormIndex === 0
        ? enrolmentForms[currentFormIndex].type
        : enrolmentForms[currentFormIndex - 1].type;

    setLoading(true);

    const participantUpdateResults = await saveKIIA3ParticipantUserData(
      enrolmentState,
      settingsState,
      isDeviceOnline
    );

    const saveKIIA3StatusSuccess = await saveKIIA3Status(
      enrolmentState.participant_id,
      enrolmentState.enrolment_id,
      recordStatus
    );

    if (_pageType === "KEY_INFORMANT") {
      const updateParticipantSuccess = await saveKIIA3ParticipantData(
        enrolmentState,
        settingsState,
        isDeviceOnline
      );
      setLoading(false);

      _showSuccessMessage(
        updateParticipantSuccess &&
          participantUpdateResults &&
          saveKIIA3StatusSuccess
      );
    }

    updateAuditInfoAndRedirect("KIIA3", status);
  };

  const saveIC = async (status) => {
    if (currentFormIndex === null) {
      history.push("/home");
    }

    const _pageType =
      currentFormIndex === 0
        ? enrolmentForms[currentFormIndex].type
        : enrolmentForms[currentFormIndex - 1].type;

    setLoading(true);

    const participantUpdateResults = await saveICParticipantUserData(
      enrolmentState,
      settingsState,
      isDeviceOnline
    );

    const saveICStatusSuccess = await saveICStatus(
      enrolmentState.participant_id,
      enrolmentState.enrolment_id,
      recordStatus
    );

    if (_pageType === "MINOR_PARENT" || _pageType === "MINOR_PARTICIPANT") {
      const updateGuardianSuccess = await saveICGuardianData(
        enrolmentState,
        settingsState,
        isDeviceOnline
      );
      const updateParticipantSuccess = await saveICParticipantData(
        enrolmentState,
        settingsState,
        isDeviceOnline
      );
      setLoading(false);

      _showSuccessMessage(
        updateGuardianSuccess &&
          updateParticipantSuccess &&
          participantUpdateResults &&
          saveICStatusSuccess
      );
    }

    if (_pageType === "EMANCIPATED_MINOR" || _pageType === "ADULT_18_PLUS") {
      const updateParticipantSuccess = await saveICParticipantData(
        enrolmentState,
        settingsState,
        isDeviceOnline
      );
      setLoading(false);

      _showSuccessMessage(
        updateParticipantSuccess &&
          participantUpdateResults &&
          saveICStatusSuccess
      );
    }

    updateAuditInfoAndRedirect("IC", status);
  };

  const saveIL = async (status) => {
    if (currentFormIndex === null) {
      history.push("/home");
    }

    const _pageType =
      currentFormIndex === 0
        ? enrolmentForms[currentFormIndex].type
        : enrolmentForms[currentFormIndex - 1].type;

    setLoading(true);

    const participantUpdateResults = await saveILParticipantUserData(
      enrolmentState,
      settingsState,
      isDeviceOnline
    );

    const saveILStatusSuccess = await saveILStatus(
      enrolmentState.participant_id,
      enrolmentState.enrolment_id,
      recordStatus
    );

    if (_pageType === "MINOR_PARENT" || _pageType === "MINOR_PARTICIPANT") {
      const updateGuardianSuccess = await saveILGuardianData(
        enrolmentState,
        settingsState,
        isDeviceOnline
      );
      const updateParticipantSuccess = await saveILParticipantData(
        enrolmentState,
        settingsState,
        isDeviceOnline
      );
      setLoading(false);

      _showSuccessMessage(
        updateGuardianSuccess &&
          updateParticipantSuccess &&
          participantUpdateResults &&
          saveILStatusSuccess
      );
    }

    if (_pageType === "EMANCIPATED_MINOR" || _pageType === "ADULT_18_PLUS") {
      const updateParticipantSuccess = await saveILParticipantData(
        enrolmentState,
        settingsState,
        isDeviceOnline
      );
      setLoading(false);

      _showSuccessMessage(
        updateParticipantSuccess &&
          participantUpdateResults &&
          saveILStatusSuccess
      );
    }

    updateAuditInfoAndRedirect("IL", status);
  };

  const updateAuditInfoAndRedirect = async (SK_CODE, status) => {
    const updateAuditInfoSuccess = await apiUpdateAuditInfo({
      participant_id: enrolmentState.participant_id,
      SK: `${SK_CODE}#${enrolmentState.enrolment_id}`,
      audit_info: JSON.stringify(settingsState.auditInfo),
      consent_country: localStorage.getItem("i18nextLng").split("_")[1],
    });

    if (updateAuditInfoSuccess) {
      dispatch(setTriggerEvent(""));

      setTimeout(() => {
        if (
          status === "SAVE_ENROLMENT_PASSED" ||
          status === "SAVE_ENROLMENT_FAILED"
        ) {
          history.push("/home");
        }
      }, 1000);
    }
  };

  useEffect(() => {
    if (
      triggerEvent === "SAVE_ENROLMENT_PASSED" ||
      triggerEvent === "SAVE_ENROLMENT_FAILED"
    ) {
      if (isDeviceOnline) {
        if (settingsState.studyName === "MAIN_STUDY") {
          saveEnrolment(triggerEvent);
        } else if (settingsState.studyName === "FGDA1") {
          saveFGDA1(triggerEvent);
        } else if (settingsState.studyName === "FGDA3") {
          saveFGDA3(triggerEvent);
        } else if (settingsState.studyName === "IDIA1") {
          saveIDIA1(triggerEvent);
        } else if (settingsState.studyName === "IDIA3") {
          saveIDIA3(triggerEvent);
        } else if (settingsState.studyName === "KIIA1") {
          saveKIIA1(triggerEvent);
        } else if (settingsState.studyName === "KIIA3") {
          saveKIIA3(triggerEvent);
        } else if (settingsState.studyName === "IC") {
          saveIC(triggerEvent);
        } else if (settingsState.studyName === "IL") {
          saveIL(triggerEvent);
        }
      } else {
        if (settingsState.studyName === "MAIN_STUDY") {
          saveEnrolmentOffline(triggerEvent);
        }
      }
    }
  }, [triggerEvent]);

  return <PreLoader show={Loading} />;
};

export default HandleSaveEnrolment;
