import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "./aws-config";
import LoginPage from "./pages/Login";
import ForgotPasswordPage from "./pages/ForgotPassword";
import ForgotPasswordConfirm from "./pages/ForgotPasswordConfirm";
import HomePage from "./pages/HomePage";
import { setIsDeviceOnline } from "./store/slices/settingsSlice";
import NewEnrolment from "./pages/NewEnrolment";
import EndOfEnrolment from "./pages/EndOfEnrolment";
import useNavigatorOnLine from "./hooks/get_online_status";
import { useDispatch } from "react-redux";
import ManageOfflineData from "./pages/ManageOfflineData";
import StepFinalReview from "./pages/NewEnrolment/StepFinalReview";
import AppFooter from "./components/AppFooter/AppFooter";
import Reconsent from "./pages/Reconsent";
import FGDA1 from "./pages/FGDA1";
import FGDA1FinalReview from "./pages/FGDA1/FGDA1FinalReview";
import FGDA3 from "./pages/FGDA3";
import FGDA3FinalReview from "./pages/FGDA3/FGDA3FinalReview";
import IDIA1 from "./pages/IDIA1";
import IDIA1FinalReview from "./pages/IDIA1/IDIA1FinalReview";
import IDIA3 from "./pages/IDIA3";
import IDIA3FinalReview from "./pages/IDIA3/IDIA3FinalReview";
import UnauthorisedPage from "./pages/UnauthorisedPage";
import KIIA1 from "./pages/KIIA1";
import KIIA1FinalReview from "./pages/KIIA1/KIIA1FinalReview";
import KIIA3 from "./pages/KIIA3";
import KIIA3FinalReview from "./pages/KIIA3/KIIA3FinalReview";
import IC from "./pages/IC";
import ICFinalReview from "./pages/IC/ICFinalReview";
import IL from "./pages/IL";
import ILFinalReview from "./pages/IL/ICFinalReview";

Amplify.configure(awsconfig);

function App() {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const isOnline = useNavigatorOnLine();
  const dispatch = useDispatch();

  useEffect(() => {
    Auth.currentSession()
      .then(() => {
        setIsUserAuthenticated(true);
        console.log("isUserAuthenticated");
      })
      .catch(() => {
        if (isOnline) {
          setIsUserAuthenticated(false);
        } else {
          setIsUserAuthenticated(true);
        }
      });
  }, []);

  useEffect(() => {
    dispatch(setIsDeviceOnline(isOnline));
  }, [isOnline]);

  return (
    <>
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return isUserAuthenticated === true ? (
                <Redirect to="/home" />
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/forgot-password">
            <ForgotPasswordPage />
          </Route>
          <Route path="/confirm-forgot-password">
            <ForgotPasswordConfirm />
          </Route>
          <Route path="/home">
            <HomePage />
          </Route>
          <Route path="/new-enrolment">
            <NewEnrolment />
          </Route>
          <Route path="/reconsent-process">
            <NewEnrolment />
          </Route>
          <Route path="/focus-discussions-group-aim-1">
            <FGDA1 />
          </Route>
          <Route path="/focus-discussions-group-aim-3">
            <FGDA3 />
          </Route>
          <Route path="/in-depth-interview-aim-1">
            <IDIA1 />
          </Route>
          <Route path="/in-depth-interview-aim-3">
            <IDIA3 />
          </Route>
          <Route path="/reconsent">
            <Reconsent />
          </Route>
          <Route path="/end-of-enrolment">
            <EndOfEnrolment />
          </Route>
          <Route path="/manage-offline-data">
            <ManageOfflineData />
          </Route>
          <Route path="/review-and-submit">
            <StepFinalReview />
          </Route>
          <Route path="/fgda1-review-and-submit">
            <FGDA1FinalReview />
          </Route>
          <Route path="/fgda3-review-and-submit">
            <FGDA3FinalReview />
          </Route>
          <Route path="/idia1-review-and-submit">
            <IDIA1FinalReview />
          </Route>
          <Route path="/idia3-review-and-submit">
            <IDIA3FinalReview />
          </Route>
          <Route path="/unauthorised">
            <UnauthorisedPage />
          </Route>
          <Route path="/kiia1-review-and-submit">
            <KIIA1FinalReview />
          </Route>
          <Route path="/key-informant-interview-aim-1">
            <KIIA1 />
          </Route>
          <Route path="/key-informant-interview-aim-3">
            <KIIA3 />
          </Route>
          <Route path="/kiia3-review-and-submit">
            <KIIA3FinalReview />
          </Route>
          <Route path="/immune-cell-sub-study">
            <IC />
          </Route>
          <Route path="/immune-cell-review-and-submit">
            <ICFinalReview />
          </Route>
          <Route path="/intensive-longitudinal-sub-study">
            <IL />
          </Route>
          <Route path="/intensive-longitudinal-review-and-submit">
            <ILFinalReview />
          </Route>
        </Switch>
      </Router>
      <AppFooter />
    </>
  );
}

export default App;
