import React, { useState, useEffect } from "react";
import styles from "./StepPersonObtaining.module.css";
import PreLoader from "../../../components/common/PreLoader";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TodayDateBox from "../../../components/common/TodayDateBox";
import TodayTimeBox from "../../../components/common/TodayTimeBox";
import SignatureBox from "../../../components/SignatureBox";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { setPersonObtainingForm } from "../../../store/slices/personObtainingFormSlice";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { setAuditInfo } from "../../../store/slices/settingsSlice";

const StepPersonObtaining = ({ handleNext, handleBack }) => {
  const [t] = useTranslation("common");
  const [Loading] = useState(false);
  const [activeStep] = useState(1);
  const [todayDate, setTodayDate] = useState("");
  const [todayTime, setTodayTime] = useState("");
  const [isSigned, setIsSigned] = useState(false);
  const personObtainingObj = useSelector(
    (state) => state.personObtainingForm.formData
  );
  const [personObtainingSignature, setPersonObtainingSignature] = useState(
    personObtainingObj.personObtainingSignature
  );

  const { auditInfo } = useSelector((state) => state.settings);

  const dispatch = useDispatch();

  const validationSchema = yup.object({
    personObtainingFirstName: yup
      .string("Please enter first name.")
      .required("First name is required"),
    personObtainingLastName: yup
      .string("Please enter last name.")
      .required("Last name is required"),
  });

  const continueForm = (values) => {
    dispatch(
      setPersonObtainingForm({
        personObtainingFirstName: values.personObtainingFirstName,
        personObtainingLastName: values.personObtainingLastName,
        personObtainingSignature: personObtainingSignature,
        isSigned: isSigned,
        consentDate: todayDate,
        consentTime: todayTime,
      })
    );

    const _auditInfo = [...auditInfo];
    _auditInfo.push({
      user: `${values.personObtainingFirstName} ${values.personObtainingLastName}`,
      dateTime: new Date().toISOString(),
      info: "Completed and signed person obtaining consent form.",
    });
    dispatch(setAuditInfo(_auditInfo));

    handleNext();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      personObtainingFirstName: personObtainingObj.personObtainingFirstName
        ? personObtainingObj.personObtainingFirstName
        : "",
      personObtainingLastName: personObtainingObj.personObtainingLastName
        ? personObtainingObj.personObtainingLastName
        : "",
    },
    validationSchema: validationSchema,
    validate: () => {
      let errors = {};
      if (!isSigned && personObtainingSignature === null) {
        errors.signatureRequired = true;
      }
      return errors;
    },
    onSubmit: (values) => {
      continueForm(values);
    },
  });

  const getCurrentUser = async () => {
    let user = null;
    try {
      user = await Auth.currentAuthenticatedUser();
      formik.setFieldValue(
        "personObtainingFirstName",
        user.attributes["custom:first_name"]
      );
      formik.setFieldValue(
        "personObtainingLastName",
        user.attributes["custom:last_name"]
      );
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.pageBox}>
          <h3 className={styles.intro}>
            {t("person_obtaining_consent_title")}
          </h3>
          <p>{t("FGDA_A1_A3_minor_participant_person_obtaining_consent_p1")}</p>

          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid xs={6}>
                <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                  <label>
                    {t("person_obtaining_consent_first_name")}
                    <span className="redStar">*</span>
                  </label>
                  <TextField
                    disabled
                    margin="normal"
                    fullWidth
                    id="personObtainingFirstName"
                    name="personObtainingFirstName"
                    autoFocus
                    placeholder=""
                    value={formik.values.personObtainingFirstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.personObtainingFirstName &&
                      Boolean(formik.errors.personObtainingFirstName)
                    }
                    helperText={
                      formik.touched.personObtainingFirstName &&
                      formik.errors.personObtainingFirstName
                    }
                  />
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                  <label>
                    {t("person_obtaining_consent_last_name")}
                    <span className="redStar">*</span>
                  </label>
                  <TextField
                    disabled
                    margin="normal"
                    fullWidth
                    id="personObtainingLastName"
                    name="personObtainingLastName"
                    placeholder=""
                    value={formik.values.personObtainingLastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.personObtainingLastName &&
                      Boolean(formik.errors.personObtainingLastName)
                    }
                    helperText={
                      formik.touched.personObtainingLastName &&
                      formik.errors.personObtainingLastName
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid xs={6}>
                <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                  <label>
                    {t("person_obtaining_consent_signature")}{" "}
                    <span className="redStar">*</span>
                  </label>
                  <SignatureBox
                    switchable={false}
                    literate={true}
                    sigData={personObtainingSignature}
                    setSigData={(sig) => {
                      if (sig !== null) {
                        setPersonObtainingSignature(sig);
                        setIsSigned(true);
                      } else {
                        setPersonObtainingSignature(null);
                        setIsSigned(false);
                      }
                    }}
                    isRequired={formik.errors.signatureRequired}
                  />
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                  <label>{t("label_date")}</label>
                  <TodayDateBox label="" setDate={setTodayDate} />
                </FormControl>
                <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                  <label>{t("label_time")}</label>
                  <TodayTimeBox label="" setTime={setTodayTime} />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </div>

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            variant="contained"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1, width: "100px" }}
          >
            {t("back")}
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            type="submit"
            variant="contained"
            sx={{ mr: 1, width: "100px" }}
            data-testid="go_next"
          >
            {t("Continue")}
          </Button>
        </Box>
      </form>
      <PreLoader show={Loading} />
    </>
  );
};

StepPersonObtaining.propTypes = {
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
};

export default StepPersonObtaining;
