import React from "react";
import styles from "./AouResults.module.css";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import {
  setAssessmentCompleted,
  setCurrentQuestionIndex,
  setGuardianResults,
  setParticipantResults,
} from "../../../../store/slices/aouSlice";
import {
  setEnrolmentForms,
  setCurrentFormIndex,
  setRecordStatus,
} from "../../../../store/slices/settingsSlice";
import { setTriggerEvent } from "../../../../store/slices/settingsSlice";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

const AouResults = ({ changeEnrolementStep, resetQuestions }) => {
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const {
    questions,
    noOfAttempts,
    assessmentStartTime,
    guardianResults,
    participantResults,
  } = useSelector((state) => state.aou);
  const noOfQuestions = questions ? questions.length : 0;
  const noOfCorrectAnswers = questions
    ? questions.filter((question) => question.answeredCorrectly).length
    : 0;
  const { enrolmentForms, currentFormIndex, participantType } = useSelector(
    (state) => state.settings
  );

  const tryAgain = async () => {
    await saveAouResults();
    resetQuestions();
    dispatch(setCurrentQuestionIndex(0));
    dispatch(setAssessmentCompleted(false));
  };

  const saveAouResults = async () => {
    let aouResults = null;
    if (participantType === "MINOR_PARENT") {
      aouResults = JSON.parse(JSON.stringify(guardianResults));
    } else {
      aouResults = JSON.parse(JSON.stringify(participantResults));
    }

    const _questions = questions.map((item) => {
      return {
        id: item.id,
        question: item.question,
        answer: item.answer,
        userAnswer: item.answeredCorrectly,
      };
    });

    const _results = {
      questions: _questions,
      assessmentDate: format(new Date(), "dd/MM/yyyy"),
      assessmentStartTime: assessmentStartTime,
      assessmentEndTime: new Date().toUTCString(),
      noOfQuestions: noOfQuestions,
      noOfCorrectAnswers: noOfCorrectAnswers,
    };

    aouResults.push(_results);

    if (participantType === "MINOR_PARENT") {
      dispatch(setGuardianResults(aouResults));
    } else {
      dispatch(setParticipantResults(aouResults));
    }
  };

  const handleContinue = async () => {
    let _enrolmentForms = JSON.parse(JSON.stringify(enrolmentForms));
    _enrolmentForms[currentFormIndex].isDone = true;
    _enrolmentForms[currentFormIndex].isCurrent = false;

    if (currentFormIndex < 15) {
      _enrolmentForms[currentFormIndex + 1].isCurrent = true;
    }

    await saveAouResults();

    dispatch(setEnrolmentForms(_enrolmentForms));
    dispatch(setCurrentFormIndex(currentFormIndex + 1));

    changeEnrolementStep(5);
  };

  const handleClose = async () => {
    let _enrolmentForms = JSON.parse(JSON.stringify(enrolmentForms));
    _enrolmentForms[currentFormIndex].isDone = true;
    _enrolmentForms[currentFormIndex].isCurrent = false;

    if (currentFormIndex < 11) {
      _enrolmentForms[currentFormIndex + 1].isCurrent = true;
    }

    await saveAouResults();

    dispatch(setEnrolmentForms(_enrolmentForms));
    dispatch(setCurrentFormIndex(currentFormIndex + 1));
    dispatch(setRecordStatus("FAILED - AOU"));
    dispatch(setTriggerEvent("SAVE_ENROLMENT_FAILED"));
  };

  return (
    <div className={styles.resultsBox}>
      <h2
        className={
          noOfCorrectAnswers < 8 ? styles.aouFailTitle : styles.aouPassTitle
        }
      >
        {t("label_you_scored")} {noOfCorrectAnswers} {t("label_out_of")}{" "}
        {noOfQuestions}.
      </h2>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {noOfAttempts < 2 && noOfCorrectAnswers < 8 && (
          <Button
            variant="contained"
            onClick={tryAgain}
            sx={{
              mr: 1,
              width: "130px",
              fontWeight: "bold",
            }}
          >
            {t("label_try_again")}
          </Button>
        )}

        {noOfAttempts >= 1 && noOfCorrectAnswers >= 8 && (
          <Button
            variant="contained"
            onClick={handleContinue}
            sx={{
              mr: 1,
              width: "130px",
              fontWeight: "bold",
            }}
          >
            {t("form.continue")}
          </Button>
        )}
      </Box>
      {noOfAttempts === 2 && noOfCorrectAnswers < 8 && (
        <>
          <div style={{ width: "100%", textAlign: "center" }}>
            <p>
              {t("label_you_scored_less")}
              <br />
              <br />
              {t("label_you_scored_less_p1")}
            </p>
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                width: "130px",
                fontWeight: "bold",
              }}
            >
              {t("close")}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

AouResults.propTypes = {
  changeEnrolementStep: PropTypes.func,
  resetQuestions: PropTypes.func,
};

export default AouResults;
