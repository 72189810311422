import React, { useState } from "react";
import styles from "./StepImpartialWitness.module.css";
import PreLoader from "../../../components/common/PreLoader";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TodayDateBox from "../../../components/common/TodayDateBox";
import TodayTimeBox from "../../../components/common/TodayTimeBox";
import SignatureBox from "../../../components/SignatureBox";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { setImpartialWitnessForm } from "../../../store/slices/impartialWitnessFormSlice";
import { useTranslation } from "react-i18next";
import { setAuditInfo } from "../../../store/slices/settingsSlice";

// eslint-disable-next-line no-unused-vars
const StepImpartialWitness = ({ handleNext, handleBack }) => {
  const [t] = useTranslation("common");
  const [Loading] = useState(false);
  const [activeStep] = useState(1);
  const [todayDate, setTodayDate] = useState("");
  const [todayTime, setTodayTime] = useState("");
  const [impartialWitnessObj] = useState(
    useSelector((state) => state.impartialWitnessForm.witnessForm)
  );
  const [isSigned, setIsSigned] = useState(false);

  const [witnessSignature, setWitnessSignature] = useState(
    impartialWitnessObj.witnessSignature
  );
  const dispatch = useDispatch();

  const enrolmentForms = useSelector((state) => state.settings.enrolmentForms);
  const { auditInfo, currentFormIndex } = useSelector(
    (state) => state.settings
  );

  const pageName = enrolmentForms ? enrolmentForms[currentFormIndex].name : "";

  const validationSchema = yup.object({
    witnessFirstName: yup
      .string(t("impartial_witness_enter_first_name"))
      .required(t("impartial_witness_first_name_required")),
    witnessLastName: yup
      .string(t("impartial_witness_enter_last_name"))
      .required(t("impartial_witness_last_name_required")),
  });

  const continueForm = (values) => {
    dispatch(
      setImpartialWitnessForm({
        witnessFirstName: values.witnessFirstName,
        witnessLastName: values.witnessLastName,
        witnessSignature: witnessSignature,
        isSigned: isSigned,
        consentDate: todayDate,
        consentTime: todayTime,
      })
    );

    const _auditInfo = [...auditInfo];
    _auditInfo.push({
      user: `${values.witnessFirstName} ${values.witnessLastName}`,
      dateTime: new Date().toISOString(),
      info: "Completed and signed impartial witness form.",
    });
    dispatch(setAuditInfo(_auditInfo));

    handleNext();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      witnessFirstName: impartialWitnessObj.witnessFirstName
        ? impartialWitnessObj.witnessFirstName
        : "",
      witnessLastName: impartialWitnessObj.witnessLastName
        ? impartialWitnessObj.witnessLastName
        : "",
    },
    validationSchema: validationSchema,
    validate: () => {
      let errors = {};
      if (!isSigned && witnessSignature === null) {
        errors.signatureRequired = true;
      }
      return errors;
    },
    onSubmit: (values) => {
      continueForm(values);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.pageBox}>
          {pageName === "MINOR_PARENT_CONSENT" && (
            <>
              <h3 className={styles.intro}>
                {t("minor_parent_impartial_witness_title")}
              </h3>
              <p>{t("FGDA_A1_A3_minor_parent_impartial_witness_p1")}</p>
            </>
          )}

          {pageName === "MINOR_PARTICIPANT_CONSENT" && (
            <>
              <h3 className={styles.intro}>
                {t("minor_impartial_witness_title")}
              </h3>
              <p>{t("IC_minor_parent_impartial_witness")}</p>
            </>
          )}

          {(pageName === "EMANCIPATED_MINOR_CONSENT" ||
            pageName === "ADULT_CONSENT") && (
            <>
              <h3 className={styles.intro}>
                {t("participant_impartial_witness_title")}
              </h3>
              <p>{t("participant_impartial_witness_p1")}</p>
            </>
          )}

          <Box sx={{ mt: 4 }}>
            <Grid container spacing={2}>
              <Grid xs={6}>
                <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                  <label>
                    {t("label_impartial_witness_first_name")}{" "}
                    <span className="redStar">*</span>
                  </label>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="witnessFirstName"
                    name="witnessFirstName"
                    autoFocus
                    placeholder=""
                    value={formik.values.witnessFirstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.witnessFirstName &&
                      Boolean(formik.errors.witnessFirstName)
                    }
                    helperText={
                      formik.touched.witnessFirstName &&
                      formik.errors.witnessFirstName
                    }
                  />
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                  <label>
                    {t("label_impartial_witness_last_name")}{" "}
                    <span className="redStar">*</span>
                  </label>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="witnessLastName"
                    name="witnessLastName"
                    placeholder=""
                    value={formik.values.witnessLastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.witnessLastName &&
                      Boolean(formik.errors.witnessLastName)
                    }
                    helperText={
                      formik.touched.witnessLastName &&
                      formik.errors.witnessLastName
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid xs={6}>
                <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                  <label>
                    {t("label_impartial_witness_signature")}{" "}
                    <span className="redStar">*</span>
                  </label>
                  <SignatureBox
                    switchable={false}
                    literate={true}
                    sigData={witnessSignature}
                    setSigData={(sig) => {
                      if (sig !== null) {
                        setWitnessSignature(sig);
                        setIsSigned(true);
                      } else {
                        setWitnessSignature(null);
                        setIsSigned(false);
                      }
                    }}
                    isRequired={formik.errors.signatureRequired}
                  />
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                  <label>{t("label_date")}</label>
                  <TodayDateBox label="" setDate={setTodayDate} />
                </FormControl>
                <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                  <label>{t("label_date")}</label>
                  <TodayTimeBox label="" setTime={setTodayTime} />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </div>

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            variant="contained"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1, width: "140px" }}
          >
            {t("back")}
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            type="submit"
            variant="contained"
            sx={{ mr: 1, width: "140px" }}
            data-testid="go_next"
          >
            {t("Continue")}
          </Button>
        </Box>
      </form>
      <PreLoader show={Loading} />
    </>
  );
};

StepImpartialWitness.propTypes = {
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
};

export default StepImpartialWitness;
