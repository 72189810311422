import React, { useEffect, useState } from "react";
import styles from "./ConsentMinorParentIntro.module.css";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import DisplayIntroSection from "../../../../components/common/DisplayIntroSection";

const ConsentMinorParentIntro = ({ printMode = false }) => {
  const [userFullName, setUserFullName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [t] = useTranslation("common");

  const getCurrentUser = async () => {
    let user = null;
    try {
      user = await Auth.currentAuthenticatedUser();
      const firstName = user.attributes["custom:first_name"];
      const lastName = user.attributes["custom:last_name"];
      setUserRole(
        user.signInUserSession.accessToken.payload["cognito:groups"][0]
      );
      setUserFullName(`${firstName} ${lastName}`);
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <>
      <h2 className={styles.intro}>{t("IC_minor_parent_consent_title")}</h2>
      <p>
        {t("IC_minor_parent_intro_p1")
          .replace("#userFullName#", userFullName)
          .replace("#userRole#", userRole)
          .replace("SiteUsers", "Counsellor")}
      </p>
      <p>{t("IC_minor_parent_intro_p2")}</p>
      <div>
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("IC_minor_parent_what_asked_your_child_title")}
            </h2>
          }
          contentElements={
            <>
              {t("IC_minor_parent_what_asked_your_child_p1")}
              <ul>
                <li>{t("IC_minor_parent_what_asked_your_child_p1_b1")}</li>
                <li>{t("IC_minor_parent_what_asked_your_child_p1_b2")}</li>
              </ul>
            </>
          }
        />
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("IC_minor_parent_what_samples_title")}
            </h2>
          }
          contentElements={
            <>
              <p>{t("IC_minor_parent_what_samples_p1")}</p>

              <p>
                <strong>
                  {t("IC_minor_parent_what_samples_p1_sub1_title")}
                </strong>
                <br />
                {t("IC_minor_parent_what_samples_p1_sub1_text")}
              </p>

              <p>
                <strong>
                  {t("IC_minor_parent_what_samples_p1_sub2_title")}
                </strong>
                <br />
                {t("IC_minor_parent_what_samples_p1_sub2_text")}
              </p>

              <p>
                <strong>
                  {t("IC_minor_parent_what_samples_p1_sub3_title")}
                </strong>
                <br />
                {t("IC_minor_parent_what_samples_p1_sub3_text")}
              </p>
            </>
          }
        />
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("IC_minor_parent_risks_sampling_title")}
            </h2>
          }
          contentElements={
            <>
              <p>
                <strong>
                  {t("IC_minor_parent_risks_sampling_p1_sub1_title")}
                </strong>
                <br />
                {t("IC_minor_parent_risks_sampling_p1_sub1_text")}
              </p>
              <p>
                <strong>
                  {t("IC_minor_parent_risks_sampling_p1_sub2_title")}
                </strong>
                <br />
                {t("IC_minor_parent_risks_sampling_p1_sub2_text")}
              </p>
              <p>
                <strong>
                  {t("IC_minor_parent_risks_sampling_p1_sub3_title")}
                </strong>
                <br />
                {t("IC_minor_parent_risks_sampling_p1_sub3_text")}
              </p>
            </>
          }
        />
      </div>
    </>
  );
};

ConsentMinorParentIntro.propTypes = {
  printMode: PropTypes.bool,
};

export default ConsentMinorParentIntro;
