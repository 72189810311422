import React, { useEffect, useMemo } from "react";
import styles from "./IDIA3FinalReview.module.css";
import { useSelector, useDispatch } from "react-redux";
import AppHeader from "../../../components/AppHeader/AppHeader";
import ConsentMinorParentView from "../StepReview/ConsentMinorParentView";
import ConsentMinorParticipantView from "../StepReview/ConsentMinorParticipantView";
import ConsentAdultParticipantView from "../StepReview/ConsentAdultParticipantView";
import ConsentImpartialWitnessView from "../StepReview/ConsentImpartialWitnessView";
import ConsentPersonObtainingView from "../StepReview/ConsentPersonObtainingView";
import { Container } from "@mui/material";
import {
  setAppTitle,
  setRecordStatus,
} from "../../../store/slices/settingsSlice";
import Button from "@mui/material/Button";
import { setTriggerEvent } from "../../../store/slices/settingsSlice";
import ConsentMinorParentIntro from "../StepIntroduction/ConsentMinorParentIntro";
import ConsentAdultParticipantIntro from "../StepIntroduction/ConsentAdultParticipantIntro";
import ConsentMinorParticipantIntro from "../StepIntroduction/ConsentMinorParticipantIntro";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const IDIA3FinalReview = () => {
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();
  const { ageCat, parentIlliterate, participantIlliterate, loggedInUser } =
    useSelector((state) => state.settings);

  useMemo(() => {
    if (loggedInUser === null) {
      history.push({ pathname: "/login" });
    }
  }, []);

  const {
    consent_participant_witness_first_name,
    consent_participant_witness_last_name,
    consent_participant_witness_signature,
    consent_participant_witness_date,
    consent_participant_witness_time,
    consent_guardian_witness_first_name,
    consent_guardian_witness_last_name,
    consent_guardian_witness_signature,
    consent_guardian_witness_date,
    consent_guardian_witness_time,
    consent_guardian_staff_first_name,
    consent_guardian_staff_last_name,
    consent_guardian_staff_signature,
    consent_guardian_staff_date,
    consent_guardian_staff_time,
    consent_participant_staff_first_name,
    consent_participant_staff_last_name,
    consent_participant_staff_signature,
    consent_participant_staff_date,
    consent_participant_staff_time,
  } = useSelector((state) => state.enrolment);

  const minorGuardianConsentWitness = {
    witnessFirstName: consent_guardian_witness_first_name,
    witnessLastName: consent_guardian_witness_last_name,
    witnessSignature: consent_guardian_witness_signature,
    consentDate: consent_guardian_witness_date,
    consentTime: consent_guardian_witness_time,
  };

  const minorGuardianConsentStaff = {
    personObtainingFirstName: consent_guardian_staff_first_name,
    personObtainingLastName: consent_guardian_staff_last_name,
    personObtainingSignature: consent_guardian_staff_signature,
    consentDate: consent_guardian_staff_date,
    consentTime: consent_guardian_staff_time,
  };

  //sss
  const participantConsentWitness = {
    witnessFirstName: consent_participant_witness_first_name,
    witnessLastName: consent_participant_witness_last_name,
    witnessSignature: consent_participant_witness_signature,
    consentDate: consent_participant_witness_date,
    consentTime: consent_participant_witness_time,
  };

  const participantConsentStaff = {
    personObtainingFirstName: consent_participant_staff_first_name,
    personObtainingLastName: consent_participant_staff_last_name,
    personObtainingSignature: consent_participant_staff_signature,
    consentDate: consent_participant_staff_date,
    consentTime: consent_participant_staff_time,
  };

  const handleSubmit = async () => {
    dispatch(setRecordStatus("PASSED"));
    dispatch(setTriggerEvent("SAVE_ENROLMENT_PASSED"));
  };

  const btnSubmit = (isDisabled) => {
    return (
      <Button
        disabled={isDisabled}
        variant="contained"
        sx={{ minWidth: "300px" }}
        onClick={handleSubmit}
      >
        {t("label_submit")}
      </Button>
    );
  };

  useEffect(() => {
    dispatch(setAppTitle("E-CONSENT REVIEW AND SUBMIT"));
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AppHeader
        isGuestMode={false}
        isPrintPreviewMode={true}
        heading={"CONSENT REVIEW AND SUBMIT"}
      />
      <Container maxWidth="md">
        {ageCat === 1 && (
          <>
            <div className={styles.pageBox}>
              <div className="printOnly">
                <ConsentMinorParentIntro printMode={true} />{" "}
                <div className="pagebreak"> </div>
              </div>
              <ConsentMinorParentView />
              {parentIlliterate && (
                <ConsentImpartialWitnessView
                  pageName={"MINOR_PARENT_CONSENT"}
                  impartialWitnessObj={minorGuardianConsentWitness}
                />
              )}
              <ConsentPersonObtainingView
                personObtainingObj={minorGuardianConsentStaff}
              />
            </div>

            <div className={styles.pageBox}>
              <div className="printOnly">
                <div className="pagebreak"> </div>

                <ConsentMinorParticipantIntro printMode={true} />
              </div>
              <div className="pagebreak"> </div>

              <ConsentMinorParticipantView />
              {participantIlliterate && (
                <ConsentImpartialWitnessView
                  pageName={"MINOR_PARTICIPANT_CONSENT"}
                  impartialWitnessObj={participantConsentWitness}
                />
              )}
              <ConsentPersonObtainingView
                personObtainingObj={participantConsentStaff}
              />
            </div>
          </>
        )}

        {ageCat === 2 && (
          <>
            <div className={styles.pageBox}>
              <div className="printOnly">
                <ConsentAdultParticipantIntro printMode={true} />
              </div>
              <div className="pagebreak"> </div>
              <ConsentAdultParticipantView />
              {participantIlliterate && (
                <ConsentImpartialWitnessView
                  pageName={"EMANCIPATED_MINOR_CONSENT"}
                  impartialWitnessObj={participantConsentWitness}
                />
              )}
              <ConsentPersonObtainingView
                personObtainingObj={participantConsentStaff}
              />
            </div>
          </>
        )}

        {ageCat === 3 && (
          <>
            <div className={styles.pageBox}>
              <div className="printOnly">
                <ConsentAdultParticipantIntro printMode={true} />
              </div>
              <div className="pagebreak"> </div>
              <ConsentAdultParticipantView />
              {participantIlliterate && (
                <ConsentImpartialWitnessView
                  pageName={"ADULT_CONSENT"}
                  impartialWitnessObj={participantConsentWitness}
                />
              )}
              <ConsentPersonObtainingView
                personObtainingObj={participantConsentStaff}
              />
            </div>
          </>
        )}

        {ageCat === 4 && (
          <>
            <div className={styles.pageBox}>
              <div className="printOnly">
                <ConsentAdultParticipantIntro printMode={true} />
              </div>
              <div className="pagebreak"> </div>
              <ConsentAdultParticipantView />
              {participantIlliterate && (
                <ConsentImpartialWitnessView
                  pageName={"PARENT_PARTICIPANT"}
                  impartialWitnessObj={participantConsentWitness}
                />
              )}
              <ConsentPersonObtainingView
                personObtainingObj={participantConsentStaff}
              />
            </div>
          </>
        )}

        {ageCat === 5 && (
          <>
            <div className={styles.pageBox}>
              <div className="printOnly">
                <ConsentAdultParticipantIntro printMode={true} />
              </div>
              <div className="pagebreak"> </div>
              <ConsentAdultParticipantView />
              {participantIlliterate && (
                <ConsentImpartialWitnessView
                  pageName={"COMMUNITY_MEMBERS"}
                  impartialWitnessObj={participantConsentWitness}
                />
              )}
              <ConsentPersonObtainingView
                personObtainingObj={participantConsentStaff}
              />
            </div>
          </>
        )}

        <div className={styles.reviewAndSubmitButton} style={{}}>
          {btnSubmit(false)}
        </div>
      </Container>
    </>
  );
};

export default IDIA3FinalReview;
