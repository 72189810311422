import React, { useEffect, useState } from "react";
import styles from "./ParticipantLiteracy.module.css";
import PreLoader from "../../../components/common/PreLoader";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import OptionButtons from "../../../components/common/OptionButtons";
import { useDispatch } from "react-redux";
import { setParticipantIlliterate } from "../../../store/slices/settingsSlice";

const ParticipantLiteracy = ({ changeEnrolementStep }) => {
  const [Loading] = useState(false);
  const dispatch = useDispatch();
  const [t] = useTranslation("common");
  const [btns, setBtns] = useState([
    {
      id: 1,
      label: t("label_yes"),
      isSelected: false,
    },
    {
      id: 2,
      label: t("label_no"),
      isSelected: false,
    },
  ]);

  useEffect(() => {
    if (btns[0].isSelected || btns[1].isSelected) {
      changeEnrolementStep(5);
      dispatch(setParticipantIlliterate(btns[0].isSelected));
    }
  }, [btns]);

  return (
    <>
      <div className={styles.pageBody}>
        <div>
          <h2>{t("participant_illiterate_question")}</h2>
        </div>
        <div>
          <OptionButtons buttons={btns} onClicked={setBtns} width="100px" />
        </div>
      </div>
      <PreLoader show={Loading} />
    </>
  );
};

ParticipantLiteracy.propTypes = {
  changeEnrolementStep: PropTypes.func,
};

export default ParticipantLiteracy;
