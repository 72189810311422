import React, { useEffect, useMemo } from "react";
import styles from "./KIIA1FinalReview.module.css";
import { useSelector, useDispatch } from "react-redux";
import AppHeader from "../../../components/AppHeader/AppHeader";
import ConsentAdultParticipantView from "../StepReview/ConsentAdultParticipantView";
import ConsentImpartialWitnessView from "../StepReview/ConsentImpartialWitnessView";
import ConsentPersonObtainingView from "../StepReview/ConsentPersonObtainingView";
import { Container } from "@mui/material";
import {
  setAppTitle,
  setRecordStatus,
} from "../../../store/slices/settingsSlice";
import Button from "@mui/material/Button";
import { setTriggerEvent } from "../../../store/slices/settingsSlice";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ConsentKeyInformantIntro from "../StepIntroduction/ConsentKeyInformantIntro";

const KIIA1FinalReview = () => {
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();
  const { ageCat, participantIlliterate, loggedInUser } = useSelector(
    (state) => state.settings
  );

  useMemo(() => {
    if (loggedInUser === null) {
      history.push({ pathname: "/login" });
    }
  }, []);

  const {
    consent_participant_witness_first_name,
    consent_participant_witness_last_name,
    consent_participant_witness_signature,
    consent_participant_witness_date,
    consent_participant_witness_time,
    consent_participant_staff_first_name,
    consent_participant_staff_last_name,
    consent_participant_staff_signature,
    consent_participant_staff_date,
    consent_participant_staff_time,
  } = useSelector((state) => state.enrolment);

  const participantConsentWitness = {
    witnessFirstName: consent_participant_witness_first_name,
    witnessLastName: consent_participant_witness_last_name,
    witnessSignature: consent_participant_witness_signature,
    consentDate: consent_participant_witness_date,
    consentTime: consent_participant_witness_time,
  };

  const participantConsentStaff = {
    personObtainingFirstName: consent_participant_staff_first_name,
    personObtainingLastName: consent_participant_staff_last_name,
    personObtainingSignature: consent_participant_staff_signature,
    consentDate: consent_participant_staff_date,
    consentTime: consent_participant_staff_time,
  };

  const handleSubmit = async () => {
    dispatch(setRecordStatus("PASSED"));
    dispatch(setTriggerEvent("SAVE_ENROLMENT_PASSED"));
  };

  const btnSubmit = (isDisabled) => {
    return (
      <Button
        disabled={isDisabled}
        variant="contained"
        sx={{ minWidth: "300px" }}
        onClick={handleSubmit}
      >
        {t("label_submit")}
      </Button>
    );
  };

  useEffect(() => {
    dispatch(setAppTitle("E-CONSENT REVIEW AND SUBMIT"));
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AppHeader
        isGuestMode={false}
        isPrintPreviewMode={true}
        heading={"CONSENT REVIEW AND SUBMIT"}
      />
      <Container maxWidth="md">
        {ageCat === 6 && (
          <>
            <div className={styles.pageBox}>
              <div className="printOnly">
                <ConsentKeyInformantIntro printMode={true} />{" "}
                <div className="pagebreak"> </div>
              </div>
              <ConsentAdultParticipantView />
              {participantIlliterate && (
                <ConsentImpartialWitnessView
                  pageName={"KEY_INFORMANT_CONSENT"}
                  impartialWitnessObj={participantConsentWitness}
                />
              )}
              <ConsentPersonObtainingView
                personObtainingObj={participantConsentStaff}
              />
            </div>
          </>
        )}

        <div className={styles.reviewAndSubmitButton} style={{}}>
          {btnSubmit(false)}
        </div>
      </Container>
    </>
  );
};

export default KIIA1FinalReview;
