export const mainStudyFormsData = [
  {
    id: 1,
    type: "MINOR_PARENT",
    name: "MINOR_PARENT_AOU",
    formName: "assessment_of_understanding_test_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 2,
    type: "MINOR_PARENT",
    name: "MINOR_PARENT_CONSENT",
    formName: "minor_parent_consent_main_study_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 3,
    type: "MINOR_PARENT",
    name: "MINOR_PARENT_LONG_STORAGE",
    formName: "minor_parent_lstore_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 4,
    type: "MINOR_PARENT",
    name: "MINOR_PARENT_GEN_TEST",
    formName: "minor_parent_gen_test_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 5,
    type: "MINOR_PARTICIPANT",
    name: "MINOR_PARTICIPANT_AOU",
    formName: "assessment_of_understanding_test_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 6,
    type: "MINOR_PARTICIPANT",
    name: "MINOR_PARTICIPANT_CONSENT",
    formName: "assent_form_main_study_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 7,
    type: "MINOR_PARTICIPANT",
    name: "MINOR_PARTICIPANT_LONG_STORAGE",
    formName: "assent_form_lng_store_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 8,
    type: "MINOR_PARTICIPANT",
    name: "MINOR_PARTICIPANT_GEN_TEST",
    formName: "assent_form_gen_test_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 9,
    type: "EMANCIPATED_MINOR",
    name: "EMANCIPATED_MINOR_AOU",
    formName: "assessment_of_understanding_test_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 10,
    type: "EMANCIPATED_MINOR",
    name: "EMANCIPATED_MINOR_CONSENT",
    formName: "minor_parent_consent_main_study_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 11,
    type: "EMANCIPATED_MINOR",
    name: "EMANCIPATED_MINOR_LONG_STORAGE",
    formName: "minor_parent_lstore_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 12,
    type: "EMANCIPATED_MINOR",
    name: "EMANCIPATED_MINOR_GEN_TEST",
    formName: "minor_parent_gen_test_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 13,
    type: "ADULT_18_PLUS",
    name: "ADULT_AOU",
    formName: "assessment_of_understanding_test_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 14,
    type: "ADULT_18_PLUS",
    name: "ADULT_CONSENT",
    formName: "minor_parent_consent_main_study_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 15,
    type: "ADULT_18_PLUS",
    name: "ADULT_LONG_STORAGE",
    formName: "minor_parent_lstore_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 16,
    type: "ADULT_18_PLUS",
    name: "ADULT_GEN_TEST",
    formName: "minor_parent_gen_test_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
];



