/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ConsentMinorParent from "./ConsentMinorParent";
import { useSelector } from "react-redux";
import LongStorageMinorParent from "./LongStorageMinorParent";
import GenTestMinorParent from "./GenTestMinorParent";

const StepParent = ({ handleNext, handleBack }) => {
  const enrolmentForms = useSelector((state) => state.settings.enrolmentForms);
  const currentFormIndex = useSelector(
    (state) => state.settings.currentFormIndex
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {enrolmentForms &&
        enrolmentForms[currentFormIndex].name === "MINOR_PARENT_CONSENT" && (
        <ConsentMinorParent handleNext={handleNext} handleBack={handleBack} />
      )}
      {enrolmentForms &&
        enrolmentForms[currentFormIndex].name ===
          "MINOR_PARENT_LONG_STORAGE" && (
        <LongStorageMinorParent
          handleNext={handleNext}
          handleBack={handleBack}
        />
      )}
      {enrolmentForms &&
        enrolmentForms[currentFormIndex].name === "MINOR_PARENT_GEN_TEST" && (
        <GenTestMinorParent handleNext={handleNext} handleBack={handleBack} />
      )}
    </>
  );
};

StepParent.propTypes = {
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
};

export default StepParent;
