import { API, graphqlOperation } from "aws-amplify";
import {
  createParticipant,
  updateParticipant,
  updateParticipantStatus,
} from "../../graphql/mutations";
import { findParticipant } from "../../graphql/queries";

export const apiCreateParticipant = async (siteId) => {
  let results = { success: true, body: {}, message: "" };

  const participantData = {
    site_id: siteId,
    participant_country: localStorage.getItem("i18nextLng").split("_")[1],
  };

  try {
    const response = await API.graphql(
      graphqlOperation(createParticipant, {
        Participant: participantData,
      })
    );

    results.body = response.data.createParticipant;
    // console.log("apiCreateParticipant successfull.");
  } catch (err) {
    results.success = false;
    results.message = "Error creating new record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateParticipant = async (participantData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateParticipant, {
        Participant: participantData,
      })
    );

    results.body = response;
  } catch (err) {
    results.success = false;
    results.message = "Error creating new record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateParticipantStatus = async (participantData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateParticipantStatus, {
        ConsentWithLongStorage: participantData,
      })
    );

    results.body = response;
  } catch (err) {
    results.success = false;
    results.message = "Error creating new record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiFindParticipant = async (data) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(findParticipant, {
        input: {
          participant_id: data.participantId,
          participant_first_name: data.participantFirstName,
          participant_last_name: data.participantLastName,
          guardian_first_name: data.guadianFirstName,
          guardian_last_name: data.guadianLastName,
          age_category: data.ageCat,
          site_id: data.siteId,
          participant_country: localStorage.getItem("i18nextLng").split("_")[1],
        },
      })
    );

    results.body = response.data.findParticipant;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};
