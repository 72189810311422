import React, { useState } from "react";
import styles from "./StepReview.module.css";
import PreLoader from "../../../components/common/PreLoader";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ConsentMinorParentView from "./ConsentMinorParentView";
import ConsentImpartialWitnessView from "./ConsentImpartialWitnessView";
import ConsentPersonObtainingView from "./ConsentPersonObtainingView";
import { useSelector, useDispatch } from "react-redux";
import {
  setEnrolmentForms,
  setCurrentFormIndex,
} from "../../../store/slices/settingsSlice";
import {
  setMinorParentWitness,
  setMinorParentPersonObtaining,
  setMinorParticipantWitness,
  setMinorParticipantPersonObtaining,
  setParticipantWitness,
  setParticipantPersonObtaining,
} from "../../../store/slices/enrolmentSlice";
import { resetImpartialWitnessForm } from "../../../store/slices/impartialWitnessFormSlice";
import { resetPersonObtainingForm } from "../../../store/slices/personObtainingFormSlice";
import ConsentMinorParticipantView from "./ConsentMinorParticipantView";
import ConsentAdultParticipantView from "./ConsentAdultParticipantView";

// eslint-disable-next-line no-unused-vars
const StepReview = ({ handleNext, handleBack, goMainPage }) => {
  const [Loading] = useState(false);
  const [activeStep] = useState(1);
  const dispatch = useDispatch();

  const {
    enrolmentForms,
    currentFormIndex,
    parentIlliterate,
    participantIlliterate,
  } = useSelector((state) => state.settings);

  const impartialWitnessObj = useSelector(
    (state) => state.impartialWitnessForm.witnessForm
  );

  const personObtainingObj = useSelector(
    (state) => state.personObtainingForm.formData
  );

  const pageName = enrolmentForms ? enrolmentForms[currentFormIndex].name : "";
  const pageType = enrolmentForms ? enrolmentForms[currentFormIndex].type : "";

  const saveFormAndContinue = () => {
    let _enrolmentForms = JSON.parse(JSON.stringify(enrolmentForms));
    _enrolmentForms[currentFormIndex].isDone = true;
    _enrolmentForms[currentFormIndex].isCurrent = true;

    if (
      (_enrolmentForms.length - 1 !== currentFormIndex &&
        _enrolmentForms[currentFormIndex].type ===
          _enrolmentForms[currentFormIndex + 1].type) ||
      (_enrolmentForms.length - 1 !== currentFormIndex &&
        pageName === "MINOR_PARENT_CONSENT")
    ) {
      _enrolmentForms[currentFormIndex + 1].isCurrent = true;
      dispatch(setCurrentFormIndex(currentFormIndex + 1));
    }

    dispatch(setEnrolmentForms(_enrolmentForms));

    if (pageName === "MINOR_PARENT_CONSENT") {
      dispatch(setMinorParentWitness(impartialWitnessObj));
      dispatch(setMinorParentPersonObtaining(personObtainingObj));
    }

    if (pageName === "MINOR_PARTICIPANT_CONSENT") {
      dispatch(setMinorParticipantWitness(impartialWitnessObj));
      dispatch(setMinorParticipantPersonObtaining(personObtainingObj));
    }

    if (
      pageName === "EMANCIPATED_MINOR_CONSENT" ||
      pageName === "ADULT_CONSENT" ||
      pageName === "PARENT_PARTICIPANT_CONSENT" ||
      pageName === "COMMUNITY_MEMBER_CONSENT"
    ) {
      dispatch(setParticipantWitness(impartialWitnessObj));
      dispatch(setParticipantPersonObtaining(personObtainingObj));
    }

    dispatch(resetImpartialWitnessForm());
    dispatch(resetPersonObtainingForm());
    goMainPage();
  };

  return (
    <>
      <div className={styles.pageBox}>
        {pageName === "MINOR_PARENT_CONSENT" && <ConsentMinorParentView />}

        {pageName === "MINOR_PARTICIPANT_CONSENT" && (
          <ConsentMinorParticipantView />
        )}

        {(pageName === "EMANCIPATED_MINOR_CONSENT" ||
          pageName === "ADULT_CONSENT" ||
          pageName === "PARENT_PARTICIPANT_CONSENT" ||
          pageName === "COMMUNITY_MEMBER_CONSENT") && (
          <ConsentAdultParticipantView />
        )}

        {pageType === "MINOR_PARENT" && parentIlliterate && (
          <ConsentImpartialWitnessView
            pageName={pageName}
            impartialWitnessObj={impartialWitnessObj}
          />
        )}

        {(pageType === "MINOR_PARTICIPANT" ||
          pageType === "EMANCIPATED_MINOR" ||
          pageType === "ADULT_18_PLUS" ||
          pageName === "PARENT_PARTICIPANT_CONSENT" ||
          pageName === "COMMUNITY_MEMBER_CONSENT") &&
          participantIlliterate && (
          <ConsentImpartialWitnessView
            pageName={pageName}
            impartialWitnessObj={impartialWitnessObj}
          />
        )}

        <ConsentPersonObtainingView personObtainingObj={personObtainingObj} />
      </div>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          variant="contained"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1, width: "100px" }}
        >
          Back
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          variant="contained"
          sx={{ mr: 1, width: "100px" }}
          data-testid="go_next"
          onClick={saveFormAndContinue}
        >
          Continue
        </Button>
      </Box>
      <PreLoader show={Loading} />
    </>
  );
};

StepReview.propTypes = {
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  goMainPage: PropTypes.func,
};

export default StepReview;
