const assessmentOUData = {
  questions: [
    {
      id: 1,
      question: "aou_q1",
      answer: true,
      answeredCorrectly: null,
      isCurrent: true,
      isDone: false,
      correctAnswerText: "aou_q1_help_info",
    },
    {
      id: 2,
      question: "aou_q2",
      answer: true,
      answeredCorrectly: null,
      isCurrent: false,
      isDone: false,
      correctAnswerText: "aou_q2_help_info",
    },
    {
      id: 3,
      question: "aou_q3",
      answer: true,
      answeredCorrectly: null,
      isCurrent: false,
      isDone: false,
      correctAnswerText: "aou_q3_help_info",
    },
    {
      id: 4,
      question: "aou_q4",
      answer: false,
      answeredCorrectly: null,
      isCurrent: false,
      isDone: false,
      correctAnswerText: "aou_q4_help_info",
    },
    {
      id: 5,
      question: "aou_q5",
      answer: true,
      answeredCorrectly: null,
      isCurrent: false,
      isDone: false,
      correctAnswerText: "aou_q5_help_info",
    },
    {
      id: 6,
      question: "aou_q6",
      answer: true,
      answeredCorrectly: null,
      isCurrent: false,
      isDone: false,
      correctAnswerText: "aou_q6_help_info",
    },
    {
      id: 7,
      question: "aou_q7",
      answer: false,
      answeredCorrectly: null,
      isCurrent: false,
      isDone: false,
      correctAnswerText: "aou_q7_help_info",
    },
    {
      id: 8,
      question: "aou_q8",
      answer: true,
      answeredCorrectly: null,
      isCurrent: false,
      isDone: false,
      correctAnswerText: "aou_q8_help_info",
    },
    {
      id: 9,
      question: "aou_q9",
      answer: true,
      answeredCorrectly: null,
      isCurrent: false,
      isDone: false,
      correctAnswerText: "aou_q9_help_info",
    },
    {
      id: 10,
      question: "aou_q10",
      answer: false,
      answeredCorrectly: null,
      isCurrent: false,
      isDone: false,
      correctAnswerText: "aou_q10_help_info",
    },
  ],
};

export default assessmentOUData;
