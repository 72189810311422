import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { format } from "date-fns";

const TodayTimeBox = ({ label, setTime }) => {
  const [todayTime, setTodayTime] = useState("");

  useEffect(() => {
    const _time = format(new Date(), "H:mm");
    setTodayTime(_time);
    setTime(_time);
  }, []);

  return (
    <TextField
      disabled
      margin="normal"
      fullWidth
      id={label}
      name={label}
      value={todayTime}
      placeholder=""
      onChange={()=>{
        setTime(todayTime);
      }}
    />
  );
};

TodayTimeBox.propTypes = {
  label: PropTypes.string,
  setTime: PropTypes.func,
};

export default TodayTimeBox;
