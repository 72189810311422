import React, { useState } from "react";
import styles from "./StepIntroduction.module.css";
import PreLoader from "../../../components/common/PreLoader";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ConsentMinorParentIntro from "./ConsentMinorParentIntro";
import LongStorageMinorParentIntro from "./LongStorageMinorParentIntro";
import { useSelector, useDispatch } from "react-redux";
import ConsentMinorParticipantIntro from "./ConsentMinorParticipantIntro";
import LongStorageMinorParticipantIntro from "./LongStorageMinorParticipantIntro";
import AdultParticipantIntro from "./ConsentAdultParticipantIntro";
import LongStorageAdultParticipantIntro from "./LongStorageAdultParticipantIntro";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTranslation } from "react-i18next";
import GenTestMinorParentIntro from "./GenTestMinorParentIntro";
import GenTestMinorParticipantIntro from "./GenTestMinorParticipantIntro";
import GenTestAdultParticipantIntro from "./GenTestAdultParticipantIntro";
import { setAuditInfo } from "../../../store/slices/settingsSlice";

const StepIntroduction = ({
  changeEnrolementStep,
  handleNext,
  pageType = "",
}) => {
  const [t] = useTranslation("common");
  const [Loading] = useState(false);
  const [activeStep] = useState(1);
  const [readAndAccepted, setReadAndAccepted] = useState(false);
  const enrolmentForms = useSelector((state) => state.settings.enrolmentForms);
  const { currentFormIndex, auditInfo } = useSelector(
    (state) => state.settings
  );
  const dispatch = useDispatch();
  const pageName = enrolmentForms ? enrolmentForms[currentFormIndex].name : "";

  const handleBack = () => {
    changeEnrolementStep(5);
  };

  const onReadAndAccept = (event) => {
    const _auditInfo = [...auditInfo];
    let _info = event.target.checked ? "Acknowledged" : "Unacknowledged";
    let _user = "";

    if (pageName === "MINOR_PARENT_AOU" && pageType === "MAIN_STUDY") {
      _user = "Minor parent";
      _info = `${_info} main study consent introduction text.`;
    }

    if (pageName === "MINOR_PARENT_AOU" && pageType === "LONG_STORAGE") {
      _user = "Minor parent";
      _info = `${_info} long storage consent introduction text.`;
    }

    if (pageName === "MINOR_PARENT_AOU" && pageType === "GEN_TEST") {
      _user = "Minor parent";
      _info = `${_info} genetic testing consent introduction text.`;
    }

    if (pageName === "MINOR_PARTICIPANT_AOU" && pageType === "MAIN_STUDY") {
      _user = "Minor participant";
      _info = `${_info} main study consent introduction text.`;
    }

    if (pageName === "MINOR_PARTICIPANT_AOU" && pageType === "LONG_STORAGE") {
      _user = "Minor participant";
      _info = `${_info} long storage consent introduction text.`;
    }

    if (pageName === "MINOR_PARTICIPANT_AOU" && pageType === "GEN_TEST") {
      _user = "Minor participant";
      _info = `${_info} genetic testing consent introduction text.`;
    }

    if (
      (pageName === "EMANCIPATED_MINOR_AOU" || pageName === "ADULT_AOU") &&
      pageType === "MAIN_STUDY"
    ) {
      _user = "Emancipated minor participant";
      _info = `${_info} main study consent introduction text.`;
    }

    if (
      (pageName === "EMANCIPATED_MINOR_AOU" || pageName === "ADULT_AOU") &&
      pageType === "LONG_STORAGE"
    ) {
      _user = "Emancipated minor participant";
      _info = `${_info} long storage consent introduction text.`;
    }

    if (
      (pageName === "EMANCIPATED_MINOR_AOU" || pageName === "ADULT_AOU") &&
      pageType === "GEN_TEST"
    ) {
      _user = "Emancipated minor participant";
      _info = `${_info} genetic testing consent introduction text.`;
    }

    _auditInfo.push({
      user: _user,
      dateTime: new Date().toISOString(),
      info: _info,
    });
    dispatch(setAuditInfo(_auditInfo));

    setReadAndAccepted(event.target.checked);
  };

  return (
    <>
      <div className={styles.pageBox}>
        {pageName === "MINOR_PARENT_AOU" && pageType === "MAIN_STUDY" && (
          <ConsentMinorParentIntro />
        )}
        {pageName === "MINOR_PARENT_AOU" && pageType === "LONG_STORAGE" && (
          <LongStorageMinorParentIntro />
        )}
        {pageName === "MINOR_PARENT_AOU" && pageType === "GEN_TEST" && (
          <GenTestMinorParentIntro />
        )}

        {pageName === "MINOR_PARTICIPANT_AOU" && pageType === "MAIN_STUDY" && (
          <ConsentMinorParticipantIntro />
        )}
        {pageName === "MINOR_PARTICIPANT_AOU" &&
          pageType === "LONG_STORAGE" && <LongStorageMinorParticipantIntro />}
        {pageName === "MINOR_PARTICIPANT_AOU" && pageType === "GEN_TEST" && (
          <GenTestMinorParticipantIntro />
        )}

        {(pageName === "EMANCIPATED_MINOR_AOU" || pageName === "ADULT_AOU") &&
          pageType === "MAIN_STUDY" && <AdultParticipantIntro />}
        {(pageName === "EMANCIPATED_MINOR_AOU" || pageName === "ADULT_AOU") &&
          pageType === "LONG_STORAGE" && <LongStorageAdultParticipantIntro />}
        {(pageName === "EMANCIPATED_MINOR_AOU" || pageName === "ADULT_AOU") &&
          pageType === "GEN_TEST" && <GenTestAdultParticipantIntro />}

        <br />
        <FormControlLabel
          style={{ color: "var(--accepted-green-color)" }}
          control={
            <Checkbox checked={readAndAccepted} onChange={onReadAndAccept} />
          }
          label={t("intro_acknowledge_read_and_understood")}
        />
        <br />
      </div>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          variant="contained"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1, width: "140px" }}
        >
          {t("back")}
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          disabled={!readAndAccepted}
          variant="contained"
          sx={{ mr: 1, width: "140px" }}
          data-testid="go_next"
          onClick={handleNext}
        >
          {t("Continue")}
        </Button>
      </Box>
      <PreLoader show={Loading} />
    </>
  );
};

StepIntroduction.propTypes = {
  changeEnrolementStep: PropTypes.func,
  handleNext: PropTypes.func,
  pageType: PropTypes.string,
};

export default StepIntroduction;
