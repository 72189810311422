import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { format } from "date-fns";

const TodayDateBox = ({ label, setDate }) => {
  const [todayDate, setTodayDate] = useState("");

  useEffect(() => {
    const _date = format(new Date(), "dd/MM/yyyy");
    
    setTodayDate(_date);
    setDate(_date);
  }, []);

  return (
    <TextField
      disabled
      margin="normal"
      fullWidth
      id={label}
      name={label}
      value={todayDate}
      placeholder=""
      onChange={()=>{
        setDate(todayDate);
      }}
    />
  );
};

TodayDateBox.propTypes = {
  label: PropTypes.string,
  setDate: PropTypes.func,
};

export default TodayDateBox;
