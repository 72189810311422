/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ConsentAdultParticipant from "./ConsentAdultParticipant";

const StepParticipant = ({ handleNext, handleBack }) => {
  const enrolmentForms = useSelector((state) => state.settings.enrolmentForms);
  const currentFormIndex = useSelector(
    (state) => state.settings.currentFormIndex
  );
  const pageName = enrolmentForms ? enrolmentForms[currentFormIndex].name : "";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {(pageName === "KEY_INFORMANT_CONSENT") && (
        <ConsentAdultParticipant
          handleNext={handleNext}
          handleBack={handleBack}
        />
      )}
    </>
  );
};

StepParticipant.propTypes = {
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
};

export default StepParticipant;
