import React, { useEffect, useState } from "react";
import SignaturePad from "react-signature-canvas";
import styles from "./SignatureBox.module.css";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";

const SignatureBox = ({
  sigData,
  setSigData,
  literate = true,
  switchable = true,
  isRequired = false,
}) => {
  const [t] = useTranslation("common");
  const [trimmedDataURL, setTrimmedDataURL] = useState(sigData);
  const [ableToSign, setAbleSign] = useState(literate);

  let sigPad = null;

  const clear = () => {
    setSigData(null);
    setTrimmedDataURL(null);
    if (sigPad !== null) {
      sigPad.clear();
    }
  };

  const trim = () => {
    setTimeout(() => {
      setTrimmedDataURL(sigPad.toDataURL());
      setSigData(sigPad.toDataURL());
    }, 200);
  };

  useEffect(() => {
    if (sigPad !== null) {
      clear();
    }
  }, []);

  useEffect(() => {
    setAbleSign(true);
  }, [literate]);

  return (
    <>
      <div
        className={styles.sigBox}
        style={{ border: isRequired && ableToSign ? "1px solid #d32f2f" : "" }}
      >
        {ableToSign ? (
          <>
            <SignaturePad
              fromData={trimmedDataURL}
              onEnd={trim}
              canvasProps={{
                width: 350,
                height: 150,
                className: styles.sigPad,
              }}
              ref={(ref) => {
                sigPad = ref;
              }}
            />
          </>
        ) : (
          <div className={styles.unableToSign}>
            <ClearIcon />
            <p>{t("unable_to_sign_label")}</p>
          </div>
        )}
      </div>
      {isRequired && ableToSign && (
        <div className={styles.sigError}>
          {literate
            ? t("label_signature_is_required")
            : t("label_acknowledgement_is_required")}
        </div>
      )}
      <Grid container spacing={1}>
        {ableToSign && (
          <Grid item xs={switchable && ableToSign ? 12 : 12}>
            <Button
              fullWidth
              className={styles.signSwitchBtn}
              variant="outlined"
              onClick={clear}
            >
              {literate
                ? t("label_clear_signature")
                : t("label_clear_acknowledgement_mark")}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

SignatureBox.propTypes = {
  sigData: PropTypes.string,
  setSigData: PropTypes.any,
  literate: PropTypes.bool,
  switchable: PropTypes.bool,
  isRequired: PropTypes.bool,
  userMode: PropTypes.string,
};

export default SignatureBox;
