export const fgda1FormsData = [
  {
    id: 1,
    type: "MINOR_PARENT",
    name: "MINOR_PARENT_CONSENT",
    formName: "fgda1_parent_consent_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 2,
    type: "MINOR_PARTICIPANT",
    name: "MINOR_PARTICIPANT_CONSENT",
    formName: "fgda1_assent_form_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 3,
    type: "EMANCIPATED_MINOR",
    name: "EMANCIPATED_MINOR_CONSENT",
    formName: "fgda1_emancipated_minor_consent_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 4,
    type: "ADULT_18_PLUS",
    name: "ADULT_CONSENT",
    formName: "fgda1_adult_consent_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 5,
    type: "PARENT_PARTICIPANT",
    name: "PARENT_PARTICIPANT_CONSENT",
    formName: "fgda1_parent_participant_consent_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 6,
    type: "COMMUNITY_MEMBERS",
    name: "COMMUNITY_MEMBER_CONSENT",
    formName: "fgda1_community_member_consent_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
];
