import React, { useState } from "react";
import styles from "./SelectLanguage.module.css";
import PreLoader from "../../../components/common/PreLoader";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import LanguageList from "../../../data/languages";
import { useSelector } from "react-redux";

const SelectLanguage = ({ changeEnrolementStep }) => {
  const history = useHistory();
  const [Loading] = useState(false);
  const [t, i18n] = useTranslation("common");
  const { userLocale } = useSelector((state) => state.settings);
  const goBack = () => {
    history.push("/home");
  };

  const SelectLang = (lang) => {
    i18n.changeLanguage(lang);
    changeEnrolementStep(2);
  };

  let _languages = [];

  if (userLocale !== null) {
    let _countryCode = userLocale.split("_")[1];
    _languages = LanguageList.filter(
      (lang) => lang.countryCode === _countryCode
    );
  }

  return (
    <>
      <div className={styles.pageBody}>
        <div>
          <h2>{t("what_language_do_you_prefer_question")}</h2>
        </div>

        {_languages.map((lang) => (
          <div key={`language_${lang.languageCode}`}>
            <Button
              id={`language_${lang.languageCode}`}
              onClick={() => {
                SelectLang(lang.languageCode);
              }}
              variant="contained"
              sx={{ mt: 3, mb: 2, minWidth: 300 }}
            >
              {t(lang.languageCode)}
            </Button>
          </div>
        ))}

        <div>
          <Button
            variant="outlined"
            sx={{ mt: 3, mb: 2, minWidth: 300 }}
            onClick={goBack}
          >
            {t("cancel")}
          </Button>
        </div>
      </div>
      <PreLoader show={Loading} />
    </>
  );
};

SelectLanguage.propTypes = {
  changeEnrolementStep: PropTypes.func,
};

export default SelectLanguage;
