import React from "react";
import styles from "./AouIncorrectAnswerBox.module.css";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

const AouIncorrectAnswerBox = ({ correctAnswerText, continueNext }) => {
  const [t] = useTranslation("common");
  return (
    <div className={styles.incorrectAlertBox}>
      <h2 className={styles.aouIncorrectAnswerTitle}>
        {t("label_help_information")}
      </h2>
      <p className={styles.aouQuestion}>{t(correctAnswerText)}</p>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={continueNext}
          sx={{
            mr: 1,
            width: "130px",
            fontWeight: "bold",
          }}
        >
          {t("form.continue")}
        </Button>
      </Box>
    </div>
  );
};

AouIncorrectAnswerBox.propTypes = {
  correctAnswerText: PropTypes.string,
  continueNext: PropTypes.func,
};

export default AouIncorrectAnswerBox;
