import { API, graphqlOperation } from "aws-amplify";
import {
  createSubStudyFGDA1,
  updateSubStudyFGDA1Participant,
  updateSubStudyFGDA1Guardian,
  updateEnrolmentStatus,
} from "../../graphql/mutations";
import { getSubStudyFGDA1 } from "../../graphql/queries";

export const apiCreateFGDA1 = async (participantId) => {
  let results = { success: true, body: {}, message: "" };

  const enrolmentData = {
    PK: `PARTICIPANT#${participantId}`,
    consent_country: localStorage.getItem("i18nextLng").split("_")[1],
  };

  try {
    const response = await API.graphql(
      graphqlOperation(createSubStudyFGDA1, {
        ConsentWithAudioTaping: enrolmentData,
      })
    );

    results.body = response.data.createSubStudyFGDA1;
  } catch (err) {
    results.success = false;
    results.message = "Error creating new record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateFGDA1Status = async (
  participantId,
  enrolementId,
  recordStatus
) => {
  let results = { success: true, body: {}, message: "" };

  const enrolmentData = {
    PK: `PARTICIPANT#${participantId}`,
    SK: `FGDA1#${enrolementId}`,
    record_status: recordStatus,
    consent_country: localStorage.getItem("i18nextLng").split("_")[1],
  };

  try {
    const response = await API.graphql(
      graphqlOperation(updateEnrolmentStatus, {
        ConsentWithLongStorage: enrolmentData,
      })
    );

    results.body = response.data.updateEnrolmentStatus;
  } catch (err) {
    results.success = false;
    results.message = "Error saving record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateFGDA1Participant = async (participantData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateSubStudyFGDA1Participant, {
        ConsentWithAudioTaping: participantData,
      })
    );

    results.body = response.data.updateSubStudyFGDA1Participant;
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateFGDA1Guardian = async (guardianData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateSubStudyFGDA1Guardian, {
        ConsentWithAudioTaping: guardianData,
      })
    );

    results.body = response.data.updateSubStudyFGDA1Guardian;
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiGetFGDA1 = async (partcipantId, enrolmentId) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyFGDA1, {
        PK: `PARTICIPANT#${partcipantId}`,
        SK: `ENROLMENT#${enrolmentId}`,
        consent_country: localStorage.getItem("i18nextLng").split("_")[1],
      })
    );

    results.body = response.data.getSubStudyFGDA1;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};
