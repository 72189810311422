import React, { useState, useEffect } from "react";
import styles from "./ConsentAdultParticipantView.module.css";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import SignatureImageView from "../../../../components/SignatureImageView";
import { useTranslation } from "react-i18next";

const ConsentAdultParticipantView = () => {
  const [t] = useTranslation("common");
  const { participantIlliterate, currentCountry } = useSelector(
    (state) => state.settings
  );
  const enrolmentState = useSelector((state) => state.enrolment);
  const [participantConsentObj, setParticipantConsentObj] = useState({});

  useEffect(() => {
    setParticipantConsentObj({
      participantFirstName: enrolmentState.consent_participant_first_name,
      participantLastName: enrolmentState.consent_participant_last_name,
      consentDate: enrolmentState.consent_participant_date,
      consentTime: enrolmentState.consent_participant_time,
      signature: enrolmentState.consent_participant_signature,
    });
  }, [enrolmentState]);

  const getSouthAfricaContent = () => (
    <>
      <h2 className={styles.intro}>{t("IL_adult_consent_title")}</h2>
      
    </>
  );

  const getZambiaContent = () => (
    <>
      <h2 className={styles.intro}>{t("IL_adult_consent_title")}</h2>

      <div className={styles.consentText}>
        <p>
          <strong>{t("IL_adult_giving_consent")}</strong>
        </p>
        <ol>
          <li>
            {t("IL_adult_giving_consent_1")}{" "}
            <strong>({enrolmentState.participant_consents.consent1})</strong>
          </li>
        </ol>

        <p>{t("IL_adult_giving_consent_p1")}</p>
      </div>
    </>
  );

  return (
    <div className={styles.pageViewBox}>
      {currentCountry === "ZA" && getSouthAfricaContent()}
      {currentCountry === "ZM" && getZambiaContent()}

      <div className={styles.consentForm}>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_participant_first_name")} </label>
                <div className={styles.fieldText}>
                  {participantConsentObj.participantFirstName}
                </div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_participant_last_name")} </label>
                <div className={styles.fieldText}>
                  {participantConsentObj.participantLastName}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>
                  {t("label_participant")}{" "}
                  {participantIlliterate
                    ? t("label_acknowledgement")
                    : t("label_signature")}{" "}
                </label>
                <SignatureImageView
                  signature={participantConsentObj.signature}
                />
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_date")}</label>
                <div className={styles.fieldText}>
                  {participantConsentObj.consentDate}
                </div>
              </FormControl>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_time")}</label>
                <div className={styles.fieldText}>
                  {participantConsentObj.consentTime}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default ConsentAdultParticipantView;
