/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import styles from "./Reconsent.module.css";
import PreLoader from "../../components/common/PreLoader";
import { useTranslation } from "react-i18next";
import { setEnrolmentForms } from "../../store/slices/settingsSlice";
import { mainStudyFormsData } from "../../data/main_study_consent_forms";
import { useDispatch } from "react-redux";
import { setAppTitle, setIsReconsent } from "../../store/slices/settingsSlice";
import { format } from "date-fns";
import { setEnrolmentDateTime } from "../../store/slices/enrolmentSlice";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import * as yup from "yup";
import { useFormik } from "formik";
import { findParticipant } from "../../services/participant.service";
import { useSnackbar } from "notistack";
import { setParticipantId } from "../../store/slices/enrolmentSlice";

const Reconsent = () => {
  const [t] = useTranslation("common");
  const [Loading, setLoading] = useState(false);
  const { loggedInUser } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = yup.object({
    participantId: yup
      .string("Enter participant ID")
      .min(6, "Invalid length of participant ID.")
      .required("Participant ID is required"),
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    const _value = values.participantId
      .toString()
      .toUpperCase()
      .replace("X101", "");
    const _participantId = values.participantId;
    const _siteId = Math.abs(_value.toString().substring(0, 2));

    const _results = await findParticipant({
      participantId: values.participantId,
      participantFirstName: "",
      participantLastName: "",
      guadianFirstName: "",
      guadianLastName: "",
      ageCat: "",
      siteId: _siteId,
    });
    setLoading(false);
    if (
      _results.success &&
      _results.body[0].participant_status === "RECONSENT"
    ) {
      console.log("_results--", _results);

      dispatch(setParticipantId(_results.body[0].participant_id));

      enqueueSnackbar(
        "Participant ID found. Please continue with the reconsenting.",
        {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
      history.push({ pathname: "/reconsent-process" });
    } else {
      enqueueSnackbar("Participant ID is not found!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useMemo(() => {
    if (loggedInUser === null) {
      history.push({ pathname: "/login" });
    }
  }, []);

  useEffect(() => {
    dispatch(setEnrolmentForms(mainStudyFormsData));
    dispatch(setAppTitle(t("reconsent")));
    dispatch(setIsReconsent(true));
    dispatch(
      setEnrolmentDateTime({
        created_date: format(new Date(), "dd/MM/yyyy"),
        created_time: format(new Date(), "H:mm"),
      })
    );
  }, []);

  return (
    <>
      <AppHeader isGuestMode={false} heading={t("new_enrolment")} />
      <>
        <div className={styles.reconsentBox}>
          <div className={styles.pageTitleBox}>
            <h3>Welcome to the reconsent process.</h3>
            <p>
              Please enter the participant ID below. The participant ID must
              begin with X101 and the site ID. E.g: X10123xxxx.
            </p>
          </div>
          <div className={styles.loginBody}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mt: 1 }}>
                <div className="form-field">
                  <label>Participant ID</label>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="participantId"
                    name="participantId"
                    autoFocus
                    placeholder={"Participant ID"}
                    value={formik.values.participantId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.participantId &&
                      Boolean(formik.errors.participantId)
                    }
                    helperText={
                      formik.touched.participantId &&
                      formik.errors.participantId
                    }
                  />
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  fullWidth
                  disabled={!formik.dirty}
                >
                  {t("form.continue")}
                </Button>
              </Box>
            </form>
          </div>
        </div>
      </>

      <PreLoader show={Loading} />
    </>
  );
};

export default Reconsent;
