import React, { useEffect, useState } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import styles from "./HomePage.module.css";
import PreLoader from "../../components/common/PreLoader";
import icoNewEnrolment from "../../assets/icon_new_enrolment.png";
import icoOfflineStorage from "../../assets/icon_offline_storage.png";
import icoReconsent from "../../assets/icon_reconsent.png";
import BigIconButton from "../../components/common/BigIconButton";
import { useTranslation } from "react-i18next";
import {
  resetSettings,
  setTriggerEvent,
} from "../../store/slices/settingsSlice";
import { useDispatch, useSelector } from "react-redux";
import { resetImpartialWitnessForm } from "../../store/slices/impartialWitnessFormSlice";
import { resetPersonObtainingForm } from "../../store/slices/personObtainingFormSlice";
import { resetAou } from "../../store/slices/aouSlice";
import { resetEnrolment } from "../../store/slices/enrolmentSlice";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  setLoggedInUser,
  setUserLocale,
} from "../../store/slices/settingsSlice";
import { Auth } from "aws-amplify";
import CryptoJS from "crypto-js";
import { signOut } from "../../services/users.service";
import { useHistory } from "react-router-dom";

const HomePage = () => {
  const [Loading] = useState(false);
  const [t] = useTranslation("common");
  const { isDeviceOnline, loggedInUser } = useSelector(
    (state) => state.settings
  );
  const history = useHistory();
  const { offlineEnrolments } = useSelector((state) => state.offlineData);
  const dispatch = useDispatch();

  const setCurrentUserDetails = async () => {
    let user = null;
    try {
      user = await Auth.currentAuthenticatedUser();

      const firstName = user.attributes["custom:first_name"];
      const lastName = user.attributes["custom:last_name"];
      const group =
        user.signInUserSession.accessToken.payload["cognito:groups"][0];
      const siteId = user.attributes["custom:site_id"];
      const locale = user.attributes["locale"];
      const currentUserObj = {
        firstName,
        lastName,
        group,
        siteId,
        locale,
      };

      const localeSplit = locale.split("_");
      console.log("localeSplit-", localeSplit);
      dispatch(setLoggedInUser(currentUserObj));
      dispatch(
        setUserLocale({
          userLocale: locale,
          currentLanguage: localeSplit[0],
          currentCountry: localeSplit[1],
        })
      );
    } catch (ex) {
      console.log(ex);
    }
  };

  const checkAppLocalSettings = () => {
    const settings = localStorage.getItem("MAGY.LOCAL");

    if (settings !== null) {
      const bytes = CryptoJS.AES.decrypt(
        settings,
        process.env.REACT_APP_CLIENT_KEY
      );
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      if (data !== "MFA_SUCCESS") {
        signOut();
        history.push({ pathname: "/login" });
      }
    } else {
      signOut();
      history.push({ pathname: "/login" });
    }
  };

  const init = async () => {
    if (process.env.REACT_APP_OWN_SMS_MFA == "true") {
      checkAppLocalSettings();
    }
    dispatch(resetSettings());
    // dispatch(setEnrolmentForms(enrolmentFormsData));
    dispatch(resetImpartialWitnessForm());
    dispatch(resetPersonObtainingForm());
    dispatch(resetAou());
    dispatch(resetEnrolment());
    dispatch(setTriggerEvent(""));

    if (isDeviceOnline === true && loggedInUser === null) {
      await setCurrentUserDetails();
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <AppHeader isGuestMode={false} />
      <Container maxWidth="md">
        <div className={styles.mainMenu}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs key="g_button_new_consent">
                <BigIconButton
                  icon={icoNewEnrolment}
                  url="/new-enrolment"
                  label={t("new_enrolment")}
                  id="button_new_consent"
                  studyName="MAIN_STUDY"
                />
              </Grid>
              <Grid item xs key="g_button_reconsent">
                <BigIconButton
                  icon={icoReconsent}
                  url="/reconsent"
                  label={t("reconsent")}
                  id="button_reconsent"
                  studyName="MAIN_STUDY_RECONSENT"
                />
              </Grid>
              <Grid item xs key="g_button_fgda1_consent">
                <BigIconButton
                  icon={icoNewEnrolment}
                  url="/focus-discussions-group-aim-1"
                  label="Focus Group Discussions - Aim 1"
                  id="button_fgda1_consent"
                  studyName="FGDA1"
                />
              </Grid>
              <Grid item xs key="g_button_fgda3_consent">
                <BigIconButton
                  icon={icoNewEnrolment}
                  url="/focus-discussions-group-aim-3"
                  label="Focus Group Discussions - Aim 3"
                  id="button_fgda3_consent"
                  studyName="FGDA3"
                />
              </Grid>
              <Grid item xs key="g_button_idia1_consent">
                <BigIconButton
                  icon={icoNewEnrolment}
                  url="/in-depth-interview-aim-1"
                  label="In-Depth Interview - Aim 1"
                  id="button_idia1_consent"
                  studyName="IDIA1"
                />
              </Grid>
              <Grid item xs key="g_button_idia3_consent">
                <BigIconButton
                  icon={icoNewEnrolment}
                  url="/in-depth-interview-aim-3"
                  label="In-Depth Interview - Aim 3"
                  id="button_idia3_consent"
                  studyName="IDIA3"
                />
              </Grid>
              <Grid item xs key="g_button_kiia1_consent">
                <BigIconButton
                  icon={icoNewEnrolment}
                  url="/key-informant-interview-aim-1"
                  label="Key-Informant Interview - Aim 1"
                  id="button_kiia1_consent"
                  studyName="KIIA1"
                />
              </Grid>
              <Grid item xs key="g_button_kiia3_consent">
                <BigIconButton
                  icon={icoNewEnrolment}
                  url="/key-informant-interview-aim-3"
                  label="Key-Informant Interview - Aim 3"
                  id="button_kiia3_consent"
                  studyName="KIIA3"
                />
              </Grid>
              <Grid item xs key="g_button_ic_consent">
                <BigIconButton
                  icon={icoNewEnrolment}
                  url="/immune-cell-sub-study"
                  label="Immune Cell Sub Study"
                  id="button_ic_consent"
                  studyName="IC"
                />
              </Grid>
              <Grid item xs key="g_button_il_consent">
                <BigIconButton
                  icon={icoNewEnrolment}
                  url="/intensive-longitudinal-sub-study"
                  label="Intensive Longitudinal Sub Study"
                  id="button_il_consent"
                  studyName="IC"
                />
              </Grid>
              {isDeviceOnline &&
                offlineEnrolments &&
                offlineEnrolments.length > 0 && (
                <Grid item xs key="g_button_manage_offline_data">
                  <BigIconButton
                    icon={icoOfflineStorage}
                    url="/manage-offline-data"
                    label={`Manage Offline Data (${offlineEnrolments.length})`}
                    id="button_manage_offline_data"
                    studyName="OFFLINE"
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </div>
      </Container>
      <PreLoader show={Loading} />
    </>
  );
};

export default HomePage;
