export const kiia3FormsData = [
  {
    id: 1,
    type: "KEY_INFORMANT",
    name: "KEY_INFORMANT_CONSENT",
    formName: "kiia3_key_informant_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
];
