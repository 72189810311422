import React, { useState, useEffect, useMemo } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import styles from "./KIIA3.module.css";
import PreLoader from "../../components/common/PreLoader";
import SelectAgeCategory from "../NewEnrolment/SelectAgeCategory";
import ConsentForms from "./ConsentForms";
import { useTranslation } from "react-i18next";
import SelectLanguage from "../NewEnrolment/SelectLanguage";
import CheckParentLiteracy from "../NewEnrolment/CheckParentLiteracy";
import ParticipantLiteracy from "../NewEnrolment/ParticipantLiteracy";
import FormControlPage from "./FormControlPage";
import { setEnrolmentForms } from "../../store/slices/settingsSlice";
import { kiia3FormsData } from "../../data/kiia3_consent_forms";
import { useDispatch } from "react-redux";
import {
  setAppTitle,
  setAgeCatsList,
  setStudyName,
} from "../../store/slices/settingsSlice";
import { format } from "date-fns";
import { setEnrolmentDateTime } from "../../store/slices/enrolmentSlice";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { KIIA3AgeCatsList } from "../../data/age_cats_data";

const KIIA3 = () => {
  const [t] = useTranslation("common");
  const [Loading] = useState(false);
  const { loggedInUser } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const history = useHistory();
  /**
   * 1 - Enrolment - Select Age Category
   */
  const [enrolmentStep, setEnrolmentStep] = useState(1);

  const changeEnrolementStep = (step) => {
    setEnrolmentStep(step);
  };

  useMemo(() => {
    if (loggedInUser === null) {
      history.push({ pathname: "/login" });
    }
  }, []);

  useEffect(() => {
    dispatch(setEnrolmentForms(kiia3FormsData));
    dispatch(setAgeCatsList(KIIA3AgeCatsList));
    dispatch(setStudyName("KIIA3"));
    dispatch(
      setAppTitle("Key-Informant Interview - Aim 3")
    );
    dispatch(
      setEnrolmentDateTime({
        created_date: format(new Date(), "dd/MM/yyyy"),
        created_time: format(new Date(), "H:mm"),
      })
    );
  }, []);

  return (
    <>
      <AppHeader isGuestMode={false} heading={t("new_enrolment")} />
      {enrolmentStep === 1 && (
        <div className={styles.mainMenu}>
          <SelectLanguage changeEnrolementStep={changeEnrolementStep} />
        </div>
      )}

      {(enrolmentStep === 2 || enrolmentStep === 2) && (
        <div className={styles.mainMenu}>
          <SelectAgeCategory changeEnrolementStep={changeEnrolementStep} />
        </div>
      )}

      {enrolmentStep === 3 && (
        <CheckParentLiteracy changeEnrolementStep={changeEnrolementStep} />
      )}

      {enrolmentStep === 4 && (
        <ParticipantLiteracy changeEnrolementStep={changeEnrolementStep} />
      )}

      {enrolmentStep === 5 && (
        <FormControlPage changeEnrolementStep={changeEnrolementStep} />
      )}

      {enrolmentStep === 6 && (
        <ConsentForms changeEnrolementStep={changeEnrolementStep} />
      )}

      <PreLoader show={Loading} />
    </>
  );
};

export default KIIA3;
