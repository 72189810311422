import React from "react";
import styles from "./SignatureImageView.module.css";
import PropTypes from "prop-types";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";

const SignatureImageView = ({ signature = "" }) => {
  const [t] = useTranslation("common");
  return (
    <div>
      {signature === null && (
        <div className={styles.unableToSign}>
          <ClearIcon />
          <p>{t("unable_to_sign_label")}</p>
        </div>
      )}
      {signature !== null && (
        <div className={styles.signatureImageBox}>
          <img width="100%" src={signature} alt="signature box" />
        </div>
      )}
    </div>
  );
};

SignatureImageView.propTypes = {
  signature: PropTypes.string,
};

export default SignatureImageView;
