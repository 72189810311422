import React, { useEffect, useState } from "react";
import styles from "./ConsentMinorParticipantIntro.module.css";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import DisplayIntroSection from "../../../../components/common/DisplayIntroSection";

const ConsentMinorParticipantIntro = ({ printMode = false }) => {
  const [userFullName, setUserFullName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [t] = useTranslation("common");

  const getCurrentUser = async () => {
    let user = null;
    try {
      user = await Auth.currentAuthenticatedUser();
      const firstName = user.attributes["custom:first_name"];
      const lastName = user.attributes["custom:last_name"];
      setUserRole(
        user.signInUserSession.accessToken.payload["cognito:groups"][0]
      );
      setUserFullName(`${firstName} ${lastName}`);
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <>
      <h2 className={styles.intro}>{t("idia3_assent_form_title")}</h2>
      <p>
        {t("FGDA_IDI_A1_A3_minor_participant_intro_p1")
          .replace("#userFullName#", userFullName)
          .replace("#userRole#", userRole)
          .replace("SiteUsers", "Counsellor")}
      </p>

      <div>
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("FGDA_IDI_A1_A3_minor_participant_purpose_title")}
            </h2>
          }
          contentElements={
            <>
              {t("FGDA_IDI_A1_A3_minor_participant_purpose_p1")}
              <ul>
                <li>{t("FGDA_IDI_A1_A3_minor_participant_purpose_p1_b1")}</li>
                <li>{t("FGDA_IDI_A1_A3_minor_participant_purpose_p1_b2")}</li>
              </ul>
              {t("FGDA_IDI_A1_A3_minor_participant_purpose_p2")}
              <ul>
                <li>{t("FGDA_IDI_A1_A3_minor_participant_purpose_p2_b1")}</li>
                <li>{t("FGDA_IDI_A1_A3_minor_participant_purpose_p2_b2")}</li>
              </ul>
              {t("FGDA_IDI_A1_A3_minor_participant_purpose_p3")}
              <ul>
                <li>{t("FGDA_IDI_A1_A3_minor_participant_purpose_p3_b1")}</li>
                <li>{t("FGDA_IDI_A1_A3_minor_participant_purpose_p3_b2")}</li>
              </ul>
              {t("FGDA_IDI_A1_A3_minor_participant_purpose_p4")}
            </>
          }
        />
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("FGDA_IDI_A1_A3_minor_participant_procedure_title")}
            </h2>
          }
          contentElements={
            <>
              {t("FGDA_IDI_A1_A3_minor_participant_procedure_p1")}
              <p>{t("FGDA_IDI_A1_A3_minor_participant_procedure_p2")}</p>
            </>
          }
        />
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("FGDA_IDI_A1_A3_minor_participant_Interview_procedure_title")}
            </h2>
          }
          contentElements={
            <>{t("FGDA_IDI_A1_A3_minor_participant_Interview_procedure_p1")}</>
          }
        />
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("FGDA_IDI_A1_A3_minor_participant_have_to_participate_title")}
            </h2>
          }
          contentElements={
            <>{t("FGDA_IDI_A1_A3_minor_participant_have_to_participate_p1")}</>
          }
        />
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("FGDA_IDI_A1_A3_minor_participant_benefits_risks_title")}
            </h2>
          }
          contentElements={
            <>{t("FGDA_IDI_A1_A3_minor_participant_benefits_risks_p1")}</>
          }
        />
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("FGDA_IDI_A1_A3_minor_participant_about_privacy_title")}
            </h2>
          }
          contentElements={
            <>{t("FGDA_IDI_A1_A3_minor_participant_about_privacy_p1")}</>
          }
        />
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t(
                "FGDA_IDI_A1_A3_minor_participant_responsible_processing_title"
              )}
            </h2>
          }
          contentElements={
            <>
              {t("FGDA_IDI_A1_A3_minor_participant_responsible_processing_p1")}
            </>
          }
        />
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("FGDA_IDI_A1_A3_minor_participant_purpose_legal_basis_title")}
            </h2>
          }
          contentElements={
            <>
              {t("FGDA_IDI_A1_A3_minor_participant_purpose_legal_basis_p1")}
              <p>
                {t("FGDA_IDI_A1_A3_minor_participant_purpose_legal_basis_p2")}
              </p>
            </>
          }
        />
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("FGDA_IDI_A1_A3_minor_participant_share_my_data_title")}
            </h2>
          }
          contentElements={
            <>
              {t("FGDA_IDI_A1_A3_minor_participant_share_my_data_p1")}
              <p>{t("FGDA_IDI_A1_A3_minor_participant_share_my_data_p2")}</p>
              <p>{t("FGDA_IDI_A1_A3_minor_participant_share_my_data_p3")}</p>
              <p>{t("FGDA_IDI_A1_A3_minor_participant_share_my_data_p4")}</p>
            </>
          }
        />
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("FGDA_IDI_A1_A3_minor_participant_what_happens_data_title")}
            </h2>
          }
          contentElements={
            <>
              {t("FGDA_IDI_A1_A3_minor_participant_what_happens_data_p1")}
              <p>
                {t("FGDA_IDI_A1_A3_minor_participant_what_happens_data_p2")}
              </p>
            </>
          }
        />
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("FGDA_IDI_A1_A3_minor_participant_data_kept_secure_title")}
            </h2>
          }
          contentElements={
            <>{t("FGDA_IDI_A1_A3_minor_participant_data_kept_secure_p1")}</>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("FGDA_IDI_A1_A3_minor_participant_rights_under_zambia_title")}
            </h2>
          }
          contentElements={
            <>{t("FGDA_IDI_A1_A3_minor_participant_rights_under_zambia_p1")}</>
          }
        />
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("FGDA_IDI_A1_A3_minor_participant_how_data_handled_title")}
            </h2>
          }
          contentElements={
            <>
              {t("FGDA_IDI_A1_A3_minor_participant_how_data_handled_p1")}
              <p>
                {t("FGDA_IDI_A1_A3_minor_participant_how_data_handled_p2")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_how_data_handled_p3")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_how_data_handled_p4")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_how_data_handled_p5")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_how_data_handled_p6")}
              </p>
            </>
          }
        />
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("FGDA_IDI_A1_A3_minor_participant_reimbursement_title")}
            </h2>
          }
          contentElements={
            <>{t("FGDA_IDI_A1_A3_minor_participant_reimbursement_p1")}</>
          }
        />
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("FGDA_IDI_A1_A3_minor_participant_contact_title")}
            </h2>
          }
          contentElements={
            <>
              {t("FGDA_IDI_A1_A3_minor_participant_contact_p1")}
              <p>
                {t("FGDA_IDI_A1_A3_minor_participant_contact_p2")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_contact_p3")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_contact_p4")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_contact_p5")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_contact_p6")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_contact_p7")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_contact_p8")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_contact_p9")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_contact_p10")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_contact_p11")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_contact_p12")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_contact_p13")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_contact_p14")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_contact_p14")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_contact_p15")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_contact_p16")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_contact_p17")}
                <br />
                {t("FGDA_IDI_A1_A3_minor_participant_contact_p18")}
              </p>
            </>
          }
        />
      </div>
    </>
  );
};

ConsentMinorParticipantIntro.propTypes = {
  printMode: PropTypes.bool,
};

export default ConsentMinorParticipantIntro;
