import { API, graphqlOperation } from "aws-amplify";
import { uploadSignature } from "../../graphql/mutations";
import axios from "axios";

export const apiGetUploadPreSignDetails = async (params) => {
  const requestsApiResult = await API.graphql(
    graphqlOperation(uploadSignature, params)
  );
  let data = requestsApiResult.data.uploadSignature;
  return data;
};

export const uploadS3File = async (participantId, fileBase64, fileName) => {
  try {
    const presign = await apiGetUploadPreSignDetails({
      input: {
        participant_id: participantId,
        file_name: fileName,
      },
    });

    const url = presign.presign_url;
    let fields = JSON.parse(presign.presign_fields);

    let formData = new FormData();
    Object.entries(fields).forEach(([field, value]) => {
      formData.append(field, value);
    });

    formData.append("file", fileBase64);

    let config = {
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        // console.log("percentCompleted-", percentCompleted);
        // setUploadProgress(percentCompleted);

        if (percentCompleted === 100) {
          // let _files = [...uploadedFiles];
          // _files[fileIndex].isUploaded = true;
          // setUploadedFiles(_files);
        }
      },
    };

    axios
      .post(url, formData, config)
      .then(function () {
        // onUploadSuccess();
        // variant could be success, error, warning, info, or default
        // enqueueSnackbar("File uploaded successfully.", {
        //   variant: "success",
        // });
      })
      .catch(function () {
        // enqueueSnackbar("Failed to upload.", { variant: "error" });
      });
  } catch (error) {
    console.log("Error uploading file: ", error);
  }
};

// export const apiDownloadFilePresign = async (params) => {
//   const requestsApiResult = await API.graphql(
//     graphqlOperation(downloadEvidence, params)
//   );
//   let data = requestsApiResult.data.downloadEvidence;
//   return data;
// };

// export const apiDeleteFile = async (params) => {
//   const requestsApiResult = await API.graphql(
//     graphqlOperation(deleteEvidence, params)
//   );
//   let data = requestsApiResult.data.deleteEvidence;
//   console.log("requestsApiResult-", requestsApiResult);
//   return data;
// };

// export const apiDownloadTemplatePresign = async (params) => {
//   const requestsApiResult = await API.graphql(
//     graphqlOperation(downloadTemplate, params)
//   );
//   let data = requestsApiResult.data.downloadTemplate;
//   return data;
// };