import React, { useEffect, useState } from "react";
// import styles from "./ConsentForms.module.css";
import PreLoader from "../../../components/common/PreLoader";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Container from "@mui/material/Container";
import StepButton from "@mui/material/StepButton";
// import StepIntroduction from "../StepIntroduction";
import StepParent from "../StepParent";
import StepParticipant from "../StepParticipant";
import StepPersonObtaining from "../StepPersonObtaining";
import StepImpartialWitness from "../StepImpartialWitness";
import StepReview from "../StepReview";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ConsentForms = ({ changeEnrolementStep }) => {
  const [Loading] = useState(false);
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [completed] = useState({});
  const [t] = useTranslation("common");

  const {
    parentIlliterate,
    participantIlliterate,
    ageCat,
    currentFormIndex,
    enrolmentForms,
  } = useSelector((state) => state.settings);

  const [pageType] = useState(
    enrolmentForms ? enrolmentForms[currentFormIndex].type : null
  );

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 0);
  };

  const handleBack = () => {
    let step = activeStep - 1;
    if (step < 0) {
      changeEnrolementStep(5);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const goMainPage = () => {
    changeEnrolementStep(5);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const setupFormSteps = () => {
    let _steps = [];

    if (pageType === "MINOR_PARENT") {
      _steps = [
        t("step_parent_or_legal_guardian"),
        t("step_impartial_witness"),
        t("step_person_obtaining_consent"),
        t("step_review"),
      ];

      if (parentIlliterate === false) {
        _steps.splice(1, 1);
      }
    } else if (pageType === "MINOR_PARTICIPANT") {
      _steps = [
        t("step_participant_assent"),
        t("step_impartial_witness"),
        t("step_person_obtaining_assent"),
        t("step_review"),
      ];

      if (participantIlliterate === false) {
        _steps.splice(1, 1);
      }
    } else if (pageType === "EMANCIPATED_MINOR") {
      _steps = [
        "Participant Consent",
        t("step_impartial_witness"),
        t("step_person_obtaining_consent"),
        t("step_review"),
      ];

      if (participantIlliterate === false) {
        _steps.splice(1, 1);
      }
    } else if (pageType === "ADULT_18_PLUS") {
      _steps = [
        "Participant Consent",
        t("step_impartial_witness"),
        t("step_person_obtaining_consent"),
        t("step_review"),
      ];

      if (participantIlliterate === false) {
        _steps.splice(1, 1);
      }
    }

    setSteps([..._steps]);
  };

  useEffect(() => {
    setupFormSteps();
  }, [
    parentIlliterate,
    participantIlliterate,
    enrolmentForms,
    currentFormIndex,
    t("step_introduction"),
  ]);

  useEffect(() => {
    setupFormSteps();
  }, []);

  const getMinorParentSteps = () => {
    return (
      <>
        {activeStep === 1 && parentIlliterate && ageCat === 1 && (
          <StepImpartialWitness
            handleBack={handleBack}
            handleNext={handleNext}
          />
        )}

        {activeStep === 1 && !parentIlliterate && ageCat === 1 && (
          <StepPersonObtaining
            handleBack={handleBack}
            handleNext={handleNext}
          />
        )}

        {activeStep === 2 && parentIlliterate && ageCat === 1 && (
          <StepPersonObtaining
            handleBack={handleBack}
            handleNext={handleNext}
          />
        )}

        {activeStep === 3 && ageCat === 1 && parentIlliterate === true && (
          <StepReview
            handleBack={handleBack}
            handleNext={handleNext}
            goMainPage={goMainPage}
          />
        )}

        {activeStep === 2 && ageCat === 1 && !parentIlliterate && (
          <StepReview
            handleBack={handleBack}
            handleNext={handleNext}
            goMainPage={goMainPage}
          />
        )}
      </>
    );
  };

  const getParticipantSteps = () => {
    return (
      <>
        {activeStep === 1 && participantIlliterate && (
          <StepImpartialWitness
            handleBack={handleBack}
            handleNext={handleNext}
          />
        )}

        {activeStep === 1 && !participantIlliterate && (
          <StepPersonObtaining
            handleBack={handleBack}
            handleNext={handleNext}
          />
        )}

        {activeStep === 2 && participantIlliterate && (
          <StepPersonObtaining
            handleBack={handleBack}
            handleNext={handleNext}
          />
        )}

        {activeStep === 3 && participantIlliterate === true && (
          <StepReview
            handleBack={handleBack}
            handleNext={handleNext}
            goMainPage={goMainPage}
          />
        )}

        {activeStep === 2 && !participantIlliterate && (
          <StepReview
            handleBack={handleBack}
            handleNext={handleNext}
            goMainPage={goMainPage}
          />
        )}
      </>
    );
  };
  return (
    <>
      <Container maxWidth="md">
        <div>
          <Box sx={{ width: "100%" }}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{ margin: "0 0rem 0 0rem" }}
            >
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <div>
              {/* {activeStep === 0 && (
                <StepIntroduction
                  changeEnrolementStep={changeEnrolementStep}
                  handleNext={handleNext}
                />
              )} */}

              {activeStep === 0 && (
                <StepParent handleBack={handleBack} handleNext={handleNext} />
              )}

              {pageType === "MINOR_PARENT" && getMinorParentSteps()}
              {pageType === "MINOR_PARTICIPANT" && getParticipantSteps()}
              {pageType === "EMANCIPATED_MINOR" && getParticipantSteps()}
              {pageType === "ADULT_18_PLUS" && getParticipantSteps()}

              {activeStep === 0 && pageType === "MINOR_PARTICIPANT" && (
                <StepParticipant
                  handleBack={handleBack}
                  handleNext={handleNext}
                />
              )}

              {activeStep === 0 &&
                (pageType === "EMANCIPATED_MINOR" ||
                  pageType === "ADULT_18_PLUS") && (
                <StepParticipant
                  handleBack={handleBack}
                  handleNext={handleNext}
                />
              )}
            </div>
          </Box>
        </div>
      </Container>
      <PreLoader show={Loading} />
    </>
  );
};

ConsentForms.propTypes = {
  changeEnrolementStep: PropTypes.func,
};

export default ConsentForms;
