/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styles from "./GenTestMinorParticipant.module.css";
import PreLoader from "../../../../components/common/PreLoader";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import SignatureBox from "../../../../components/SignatureBox";
import TodayDateBox from "../../../../components/common/TodayDateBox";
import TodayTimeBox from "../../../../components/common/TodayTimeBox";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { setMinorParticipantGenTest } from "../../../../store/slices/enrolmentSlice";
import OptionButtons from "../../../../components/common/OptionButtons";
import { useTranslation } from "react-i18next";
import { setAuditInfo } from "../../../../store/slices/settingsSlice";

const GenTestMinorParticipant = ({ handleNext, handleBack }) => {
  const [t] = useTranslation("common");
  const [Loading] = useState(false);
  const [activeStep] = useState(1);
  const [todayDate, setTodayDate] = useState("");
  const [todayTime, setTodayTime] = useState("");
  const [isSigned, setIsSigned] = useState(false);

  const { auditInfo, participantIlliterate } = useSelector(
    (state) => state.settings
  );

  const enrolmentState = useSelector((state) => state.enrolment);
  const [participantConsentObj, setParticipantConsentObj] = useState({});

  const [participantSignature, setParticipantSignature] = useState(
    participantConsentObj.participantSignature
  );
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    participantFirstName: yup
      .string(t("label_enter_participant_first_name"))
      .required(t("label_participant_first_name_required")),
    participantLastName: yup
      .string(t("label_enter_participant_last_name"))
      .required(t("label_participant_last_name_required")),
  });

  const agreeOptions = () => {
    return JSON.stringify([
      {
        id: 1,
        label: t("label_yes"),
        isSelected: false,
      },
      {
        id: 2,
        label: t("label_no"),
        isSelected: false,
      },
    ]);
  };

  const [agreed1, setAgreed1] = useState(JSON.parse(agreeOptions()));
  const [agreed2, setAgreed2] = useState(JSON.parse(agreeOptions()));

  useEffect(() => {
    setAgreed1(JSON.parse(agreeOptions()));
    setAgreed2(JSON.parse(agreeOptions()));
  }, [t("label_yes")]);

  const getSelectedOptionValue = (options) => {
    const isSelected = options.filter((item) => item.isSelected);
    if (isSelected.length > 0) {
      return isSelected[0].label;
    } else {
      return null;
    }
  };

  const validateSelectionOptions = (options) => {
    const isSelected = options.filter((item) => item.isSelected);
    return isSelected.length > 0;
  };

  const continueForm = (values) => {
    const participantConsents = {
      consent1: getSelectedOptionValue(agreed1),
      consent2: getSelectedOptionValue(agreed2),
    };

    dispatch(
      setMinorParticipantGenTest({
        consents: participantConsents,
        participantFirstName: values.participantFirstName,
        participantLastName: values.participantLastName,
        participantSignature: participantSignature,
        isSigned: isSigned,
        consentDate: todayDate,
        consentTime: todayTime,
      })
    );

    const _auditInfo = [...auditInfo];
    _auditInfo.push({
      user: `Minor Participant (${values.participantFirstName} ${values.participantLastName})`,
      dateTime: new Date().toISOString(),
      info: "Completed and signed genetic testing consent form.",
    });
    dispatch(setAuditInfo(_auditInfo));

    handleNext();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      participantFirstName: participantConsentObj.participantFirstName
        ? participantConsentObj.participantFirstName
        : "",
      participantLastName: participantConsentObj.participantLastName
        ? participantConsentObj.participantLastName
        : "",
    },
    validationSchema: validationSchema,
    validate: () => {
      let errors = {};

      if (!isSigned && participantSignature === null) {
        errors.signatureRequired = true;
      }

      if (!validateSelectionOptions(agreed1)) {
        errors.agreed1 = true;
      }

      if (!validateSelectionOptions(agreed2)) {
        errors.agreed2 = true;
      }

      return errors;
    },
    onSubmit: (values) => {
      continueForm(values);
    },
  });

  useEffect(() => {
    setParticipantSignature(participantConsentObj.participantSignature);
  }, [participantConsentObj]);

  useEffect(() => {
    setParticipantConsentObj({
      participantFirstName:
        enrolmentState.gen_test_participant_first_name === null
          ? enrolmentState.consent_guardian_child_first_name
          : enrolmentState.gen_test_participant_first_name,
      participantLastName:
        enrolmentState.gen_test_participant_last_name === null
          ? enrolmentState.consent_guardian_child_last_name
          : enrolmentState.gen_test_participant_last_name,
    });
  }, [enrolmentState]);

  useEffect(() => {
    setTimeout(() => {
      setParticipantSignature(null);
      setIsSigned(false);
    }, 100);
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.pageBox}>
          <h2 className={styles.intro}>ASSENT FORM FOR GENETIC TESTING</h2>

          <div className={styles.consentText}>
            <p>
              <strong>{t("GT_minor_participant_giving_consent")}</strong>
            </p>
            <FormControl>
              <label>
                {t("GT_minor_participant_giving_consent_1")}
                <span className="redStar">*</span>
              </label>
              {formik.errors.agreed1 && (
                <>
                  <br />
                  <span className="redStar">
                    {t("please_complete_this_label")}
                  </span>
                </>
              )}
              <OptionButtons
                buttons={agreed1}
                onClicked={(val) => {
                  setAgreed1(val);
                  formik.validateForm();
                }}
              />
            </FormControl>

            <FormControl>
              <label>
                {t("GT_minor_participant_giving_consent_2")}
                <span className="redStar">*</span>
              </label>
              {formik.errors.agreed2 && (
                <>
                  <br />
                  <span className="redStar">
                    {t("please_complete_this_label")}
                  </span>
                </>
              )}
              <OptionButtons
                buttons={agreed2}
                onClicked={(val) => {
                  setAgreed2(val);
                  formik.validateForm();
                }}
              />
            </FormControl>

            <p>{t("minor_parent_lstore_consent_p1")}</p>
          </div>

          <div className={styles.consentForm}>
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid xs={6}>
                  <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                    <label>
                      {t("label_participant_first_name")}{" "}
                      <span className="redStar">*</span>
                    </label>
                    <TextField
                      margin="normal"
                      disabled
                      fullWidth
                      id="participantFirstName"
                      name="participantFirstName"
                      placeholder=""
                      value={formik.values.participantFirstName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.participantFirstName &&
                        Boolean(formik.errors.participantFirstName)
                      }
                      helperText={
                        formik.touched.participantFirstName &&
                        formik.errors.participantFirstName
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid xs={6}>
                  <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                    <label>
                      {t("label_participant_last_name")}{" "}
                      <span className="redStar">*</span>
                    </label>
                    <TextField
                      margin="normal"
                      disabled
                      fullWidth
                      id="participantLastName"
                      name="participantLastName"
                      placeholder=""
                      value={formik.values.participantLastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.participantLastName &&
                        Boolean(formik.errors.participantLastName)
                      }
                      helperText={
                        formik.touched.participantLastName &&
                        formik.errors.participantLastName
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid xs={6}>
                  <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                    <label>
                      {t("label_participant")}{" "}
                      {participantIlliterate
                        ? t("label_acknowledgement")
                        : t("label_signature")}{" "}
                      <span className="redStar">*</span>
                    </label>
                    <SignatureBox
                      userMode="PARTICIPANT"
                      literate={!participantIlliterate}
                      sigData={participantSignature}
                      setSigData={(sig) => {
                        if (sig !== null) {
                          setParticipantSignature(sig);
                          setIsSigned(true);
                        } else {
                          setParticipantSignature(null);
                          setIsSigned(false);
                        }
                      }}
                      isRequired={formik.errors.signatureRequired}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={6}>
                  <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                    <label>{t("label_date")}</label>
                    <TodayDateBox label="" setDate={setTodayDate} />
                  </FormControl>
                  <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                    <label>{t("label_time")}</label>
                    <TodayTimeBox label="" setTime={setTodayTime} />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            variant="contained"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1, width: "140px" }}
          >
            {t("back")}
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            type="submit"
            variant="contained"
            sx={{ mr: 1, width: "140px" }}
            data-testid="go_next"
          >
            {t("Continue")}
          </Button>
        </Box>
      </form>
      <PreLoader show={Loading} />
    </>
  );
};

GenTestMinorParticipant.propTypes = {
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
};

export default GenTestMinorParticipant;
