export const ageCatsArray = [
  "",
  "age_15_17",
  "age_emancipated_minor",
  "age_18",
  "parent_participant_cat",
  "community_members_cat",
  "key_informat_cat",
];

export const ageCatsList = [
  {
    id: 1,
    title: "age_15_17",
  },
  {
    id: 2,
    title: "age_emancipated_minor",
  },
  {
    id: 3,
    title: "age_18",
  },
  {
    id: 4,
    title: "parent_participant_cat",
  },
  {
    id: 5,
    title: "community_members_cat",
  },
  {
    id: 6,
    title: "key_informat_cat",
  },
];

export const mainStudyAgeCatsList = [
  {
    id: 1,
    title: "age_15_17",
  },
  {
    id: 2,
    title: "age_emancipated_minor",
  },
  {
    id: 3,
    title: "age_18",
  },
];

export const FGDA1AgeCatsList = [
  {
    id: 1,
    title: "age_15_17",
  },
  {
    id: 2,
    title: "age_emancipated_minor",
  },
  {
    id: 3,
    title: "age_18",
  },
  {
    id: 4,
    title: "parent_participant_cat",
  },
  {
    id: 5,
    title: "community_members_cat",
  },
];

export const FGDA3AgeCatsList = [
  {
    id: 1,
    title: "age_15_17",
  },
  {
    id: 2,
    title: "age_emancipated_minor",
  },
  {
    id: 3,
    title: "age_18",
  },
  {
    id: 4,
    title: "parent_participant_cat",
  },
  {
    id: 5,
    title: "community_members_cat",
  },
];

export const IDIA1AgeCatsList = [
  {
    id: 1,
    title: "age_15_17",
  },
  {
    id: 2,
    title: "age_emancipated_minor",
  },
  {
    id: 3,
    title: "age_18",
  },
  {
    id: 4,
    title: "parent_participant_cat",
  },
  {
    id: 5,
    title: "community_members_cat",
  },
];

export const IDIA3AgeCatsList = [
  {
    id: 1,
    title: "age_15_17",
  },
  {
    id: 2,
    title: "age_emancipated_minor",
  },
  {
    id: 3,
    title: "age_18",
  },
  {
    id: 4,
    title: "parent_participant_cat",
  },
  {
    id: 5,
    title: "community_members_cat",
  },
];

export const KIIA1AgeCatsList = [
  {
    id: 6,
    title: "key_informat_cat",
  },
];

export const KIIA3AgeCatsList = [
  {
    id: 6,
    title: "key_informat_cat",
  },
];

export const ICAgeCatsList = [
  {
    id: 1,
    title: "age_15_17",
  },
  {
    id: 2,
    title: "age_emancipated_minor",
  },
  {
    id: 3,
    title: "age_18",
  },
];

export const ILAgeCatsList = [
  {
    id: 1,
    title: "age_15_17",
  },
  {
    id: 2,
    title: "age_emancipated_minor",
  },
  {
    id: 3,
    title: "age_18",
  },
];
