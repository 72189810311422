/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styles from "./ConsentMinorParent.module.css";
import PreLoader from "../../../../components/common/PreLoader";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import SignatureBox from "../../../../components/SignatureBox";
import TodayDateBox from "../../../../components/common/TodayDateBox";
import TodayTimeBox from "../../../../components/common/TodayTimeBox";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import {
  setMinorParentConsent,
  setMinorParentLongStorage,
  setMinorParentGenTest,
  setGuardianConsents,
} from "../../../../store/slices/enrolmentSlice";
import { useTranslation } from "react-i18next";
import OptionButtons from "../../../../components/common/OptionButtons";
import { setAuditInfo } from "../../../../store/slices/settingsSlice";

const ConsentMinorParent = ({ handleNext, handleBack }) => {
  const [t] = useTranslation("common");
  const [Loading] = useState(false);
  const [activeStep] = useState(1);
  const [todayDate, setTodayDate] = useState("");
  const [todayTime, setTodayTime] = useState("");
  const [isSigned, setIsSigned] = useState(false);
  const { parentIlliterate, currentCountry, auditInfo } = useSelector(
    (state) => state.settings
  );
  const enrolmentState = useSelector((state) => state.enrolment);
  const [parentConsentObj, setParentConsentObj] = useState({});
  const [parentSignature, setParentSignature] = useState(
    parentConsentObj.parentSignature
  );
  const agreeOptions = () => {
    return JSON.stringify([
      {
        id: 1,
        label: t("label_yes"),
        isSelected: false,
      },
      {
        id: 2,
        label: t("label_no"),
        isSelected: false,
      },
    ]);
  };

  const [agreed1, setAgreed1] = useState(JSON.parse(agreeOptions()));

  const dispatch = useDispatch();

  useEffect(() => {
    setAgreed1(JSON.parse(agreeOptions()));
  }, [t("label_yes")]);

  const validationSchema = yup.object({
    childFirstName: yup
      .string(t("label_enter_child_first_name"))
      .required(t("label_child_first_name_required")),
    childLastName: yup
      .string(t("label_enter_child_last_name"))
      .required(t("label_child_last_name_required")),
    parentFirstName: yup
      .string(t("label_enter_parent_first_name"))
      .required(t("label_parent_first_name_required")),
    parentLastName: yup
      .string(t("label_enter_parent_last_name"))
      .required(t("label_parent_last_name_required")),
  });

  const continueForm = (values) => {
    dispatch(
      setMinorParentConsent({
        childFirstName: values.childFirstName,
        childLastName: values.childLastName,
        parentFirstName: values.parentFirstName,
        parentLastName: values.parentLastName,
        parentSignature: parentSignature,
        isSigned: isSigned,
        consentDate: todayDate,
        consentTime: todayTime,
      })
    );

    dispatch(
      setMinorParentLongStorage({
        consent1: null,
        consent2: null,
        consent3: null,
        childFirstName: values.childFirstName,
        childLastName: values.childLastName,
        parentFirstName: values.parentFirstName,
        parentLastName: values.parentLastName,
        parentSignature: null,
        isSigned: null,
        consentDate: null,
        consentTime: null,
      })
    );

    dispatch(
      setMinorParentGenTest({
        consent1: null,
        consent2: null,
        consent3: null,
        childFirstName: values.childFirstName,
        childLastName: values.childLastName,
        parentFirstName: values.parentFirstName,
        parentLastName: values.parentLastName,
        parentSignature: null,
        isSigned: null,
        consentDate: null,
        consentTime: null,
      })
    );

    if (currentCountry === "ZM") {
      const guardianConsents = {
        consent1: getSelectedOptionValue(agreed1),
      };
      dispatch(setGuardianConsents(guardianConsents));
    }

    const _auditInfo = [...auditInfo];
    _auditInfo.push({
      user: `Minor Parent (${values.parentFirstName} ${values.parentLastName})`,
      dateTime: new Date().toISOString(),
      info: "Completed and signed intensive logitudinal sub study consent form.",
    });
    dispatch(setAuditInfo(_auditInfo));

    handleNext();
  };

  const validateSelectionOptions = (options) => {
    const isSelected = options.filter((item) => item.isSelected);
    return isSelected.length > 0;
  };

  const getSelectedOptionValue = (options) => {
    const isSelected = options.filter((item) => item.isSelected);
    if (isSelected.length > 0) {
      return isSelected[0].label;
    } else {
      return null;
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      childFirstName: parentConsentObj.childFirstName
        ? parentConsentObj.childFirstName
        : "",
      childLastName: parentConsentObj.childLastName
        ? parentConsentObj.childLastName
        : "",
      parentFirstName: parentConsentObj.parentFirstName
        ? parentConsentObj.parentFirstName
        : "",
      parentLastName: parentConsentObj.parentLastName
        ? parentConsentObj.parentLastName
        : "",
    },
    validationSchema: validationSchema,
    validate: () => {
      let errors = {};

      if (!isSigned && parentSignature === null) {
        errors.signatureRequired = true;
      }

      if (currentCountry === "ZM") {
        if (!validateSelectionOptions(agreed1)) {
          errors.agreed1 = true;
        }
      }

      return errors;
    },
    onSubmit: (values) => {
      continueForm(values);
    },
  });

  useEffect(() => {
    setParentSignature(parentConsentObj.parentSignature);
  }, [parentConsentObj]);

  useEffect(() => {
    setParentConsentObj({
      childFirstName: enrolmentState.consent_guardian_child_first_name,
      childLastName: enrolmentState.consent_guardian_child_last_name,
      parentFirstName: enrolmentState.consent_guardian_first_name,
      parentLastName: enrolmentState.consent_guardian_last_name,
    });
  }, [enrolmentState]);

  useEffect(() => {
    setTimeout(() => {
      setParentSignature(null);
      setIsSigned(false);
    }, 100);
  }, []);

  const getSouthAfricaContent = () => (
    <>
      <h2 className={styles.intro}>{t("IL_parent_consent_title")}</h2>
    </>
  );

  const getZambiaContent = () => (
    <>
      <h2 className={styles.intro}>{t("IL_parent_consent_title")}</h2>

      <div className={styles.consentText}>
        <p>{t("IL_minor_parent_giving_consent")}</p>

        <FormControl>
          <label>
            {t("IL_minor_parent_giving_consent_1")}
            <span className="redStar">*</span>
          </label>
          {formik.errors.agreed1 && (
            <>
              <br />
              <span className="redStar">
                {t("please_complete_this_label")}.
              </span>
            </>
          )}
          <OptionButtons
            buttons={agreed1}
            onClicked={(val) => {
              setAgreed1(val);
              formik.validateForm();
            }}
          />
        </FormControl>

        <p>{t("IL_minor_parent_giving_consent_p1")}</p>
      </div>
    </>
  );

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.pageBox}>
          {currentCountry === "ZA" && getSouthAfricaContent()}
          {currentCountry === "ZM" && getZambiaContent()}

          <div className={styles.consentForm}>
            <Box sx={{ mt: 4 }}>
              <Grid container spacing={2}>
                <Grid xs={6}>
                  <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                    <label>
                      {t("label_child_first_name")}{" "}
                      <span className="redStar">*</span>
                    </label>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="childFirstName"
                      name="childFirstName"
                      autoFocus
                      placeholder=""
                      value={formik.values.childFirstName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.childFirstName &&
                        Boolean(formik.errors.childFirstName)
                      }
                      helperText={
                        formik.touched.childFirstName &&
                        formik.errors.childFirstName
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid xs={6}>
                  <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                    <label>
                      {t("label_child_last_name")}{" "}
                      <span className="redStar">*</span>
                    </label>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="childLastName"
                      name="childLastName"
                      placeholder=""
                      value={formik.values.childLastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.childLastName &&
                        Boolean(formik.errors.childLastName)
                      }
                      helperText={
                        formik.touched.childLastName &&
                        formik.errors.childLastName
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid xs={6}>
                  <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                    <label>
                      {t("label_parent_legal_guardian_first_name")}{" "}
                      <span className="redStar">*</span>
                    </label>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="parentFirstName"
                      name="parentFirstName"
                      placeholder=""
                      value={formik.values.parentFirstName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.parentFirstName &&
                        Boolean(formik.errors.parentFirstName)
                      }
                      helperText={
                        formik.touched.parentFirstName &&
                        formik.errors.parentFirstName
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid xs={6}>
                  <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                    <label>
                      {t("label_parent_legal_guardian_last_name")}{" "}
                      <span className="redStar">*</span>
                    </label>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="parentLastName"
                      name="parentLastName"
                      placeholder=""
                      value={formik.values.parentLastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.parentLastName &&
                        Boolean(formik.errors.parentLastName)
                      }
                      helperText={
                        formik.touched.parentLastName &&
                        formik.errors.parentLastName
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid xs={6}>
                  <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                    <label>
                      {t("label_parent_legal_guardian_signature")}{" "}
                      {parentIlliterate ? "acknowledgement" : "Signature"}{" "}
                      <span className="redStar">*</span>
                    </label>
                    <SignatureBox
                      userMode="PARENT"
                      literate={!parentIlliterate}
                      sigData={parentSignature}
                      setSigData={(sig) => {
                        if (sig !== null) {
                          setParentSignature(sig);
                          setIsSigned(true);
                        } else {
                          setParentSignature(null);
                          setIsSigned(false);
                        }
                      }}
                      isRequired={formik.errors.signatureRequired}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={6}>
                  <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                    <label>{t("label_date")}</label>
                    <TodayDateBox label="" setDate={setTodayDate} />
                  </FormControl>
                  <FormControl sx={{ m: 2, width: "90%" }} variant="filled">
                    <label>{t("label_time")}</label>
                    <TodayTimeBox label="" setTime={setTodayTime} />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            variant="contained"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1, minWidth: "100px" }}
          >
            {t("back")}
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            type="submit"
            variant="contained"
            sx={{ mr: 1, minWidth: "100px" }}
            data-testid="go_next"
          >
            {t("Continue")}
          </Button>
        </Box>
      </form>
      <PreLoader show={Loading} />
    </>
  );
};

ConsentMinorParent.propTypes = {
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
};

export default ConsentMinorParent;
