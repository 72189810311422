import React, { useMemo, useState } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import styles from "./EndOfEnrolment.module.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import PreLoader from "../../components/common/PreLoader";
import {
  setRecordStatus,
  setTriggerEvent,
} from "../../store/slices/settingsSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const EndOfEnrolment = () => {
  const [t] = useTranslation("common");
  const history = useHistory();
  const [Loading] = useState(false);
  const dispatch = useDispatch();
  const { loggedInUser } = useSelector((state) => state.settings);

  const handleClose = () => {
    dispatch(setRecordStatus("FAILED - WITHDREW"));
    dispatch(setTriggerEvent("SAVE_ENROLMENT_FAILED"));
  };

  useMemo(() => {
    if (loggedInUser === null) {
      history.push({ pathname: "/login" });
    }
  }, []);

  return (
    <>
      <AppHeader isGuestMode={true} />
      <div className={styles.pageBox}>
        <div className={styles.pageTitleBox}>
          <h1>{t("magy_econsent_title")}</h1>
        </div>
        <div className={styles.pageBoxBody}>
          <Box sx={{ mt: 1 }}>
            <p>{t("end_of_consent_text")}</p>
            <center>
              <Button
                onClick={handleClose}
                variant="contained"
                sx={{ mt: 3, mb: 2, width: "140px" }}
              >
                {t("close")}
              </Button>
            </center>
          </Box>
        </div>
      </div>
      <PreLoader show={Loading} />
    </>
  );
};

export default EndOfEnrolment;
