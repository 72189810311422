/* eslint-disable quotes */
import React, { useEffect, useState } from "react";
import styles from "./AppHeader.module.css";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { Auth } from "aws-amplify";
import Logout from "@mui/icons-material/Logout";
import { useHistory } from "react-router-dom";
import { signOut } from "../../services/users.service";
import MenuIcon from "@mui/icons-material/Menu";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import HandleSaveEnrolment from "../Renderless/HandleSaveEnrolment";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import WifiIcon from "@mui/icons-material/Wifi";
import {
  setLoggedInUser,
  setUserLocale,
  resetSettings,
  setTriggerEvent,
} from "../../store/slices/settingsSlice";
import { resetImpartialWitnessForm } from "../../store/slices/impartialWitnessFormSlice";
import { resetPersonObtainingForm } from "../../store/slices/personObtainingFormSlice";
import { resetAou } from "../../store/slices/aouSlice";
import { resetEnrolment } from "../../store/slices/enrolmentSlice";
// import { IAVILogoBase64 } from "../../data/image_assets";
import TranslateIcon from "@mui/icons-material/Translate";
import LanguageList from "../../data/languages";
import { Link } from "@mui/material";
import Slide from "@mui/material/Slide";
import { QRCodeSVG } from "qrcode.react";
import MagyLogo from "../../assets/magy_logo.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppHeader = ({
  isGuestMode,
  heading = "",
  isPrintPreviewMode = false,
}) => {
  const [profileMenu, setProfileMenu] = React.useState(null);
  const openProfileMenu = Boolean(profileMenu);
  const [langMenu, setLangMenu] = React.useState(null);
  const openLangMenu = Boolean(langMenu);
  const history = useHistory();
  const [userFullName, setUserFullName] = useState("");
  const [userInitials, setUserInitials] = useState("");
  const [siteId, setSiteId] = useState("");
  const [t, i18n] = useTranslation("common");
  const [open, setOpen] = React.useState(false);

  const [qrOpen, setQrOpen] = React.useState(false);
  const handleQRClickOpen = () => {
    setQrOpen(true);
  };

  const handleQRClose = () => {
    setQrOpen(false);
  };

  const [languages, setLanguages] = useState([
    {
      country: "Zambia",
      countryCode: "ZM",
      languageName: "English",
      languageCode: "en_ZM",
    },
    {
      country: "South Africa",
      countryCode: "ZA",
      languageName: "English",
      languageCode: "en_ZA",
    },
    {
      country: "South Africa",
      countryCode: "ZA",
      languageName: "Setswana",
      languageCode: "tn_ZA",
    },
    {
      country: "South Africa",
      countryCode: "ZA",
      languageName: "Xhosa",
      languageCode: "xh_ZA",
    },
    {
      country: "Zambia",
      countryCode: "ZM",
      languageName: "Bemba",
      languageCode: "bem_ZM",
    },
    {
      country: "Zambia",
      countryCode: "ZM",
      languageName: "Nyanja",
      languageCode: "ny_ZM",
    },
    {
      country: "Uganda",
      countryCode: "UG",
      languageName: "English",
      languageCode: "en_UG",
    },
    {
      country: "Uganda",
      countryCode: "UG",
      languageName: "Luganda",
      languageCode: "lg_UG",
    },
  ]);

  const dispatch = useDispatch();
  const { isDeviceOnline, loggedInUser, appTitle, userLocale } = useSelector(
    (state) => state.settings
  );
  const { participant_id, enrolment_id } = useSelector(
    (state) => state.enrolment
  );

  const goBackToLogin = () => {
    history.push({ pathname: "/login" });
  };

  const handleClickOpen = () => {
    const url = window.location.pathname;
    if (url === "/new-enrolment" || url === "/reconsent-process") {
      setOpen(true);
    } else {
      history.push({ pathname: "/home" });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProfileMenuClick = (event) => {
    setProfileMenu(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setProfileMenu(null);
  };

  const handleLangMenuClick = (event) => {
    setLangMenu(event.currentTarget);
  };
  const handleLangMenuClose = () => {
    setLangMenu(null);
  };

  const logout = async () => {
    await signOut();
    dispatch(resetSettings());
    dispatch(resetImpartialWitnessForm());
    dispatch(resetPersonObtainingForm());
    dispatch(resetAou());
    dispatch(resetEnrolment());
    dispatch(setTriggerEvent(""));
    dispatch(setLoggedInUser(null));
    dispatch(
      setUserLocale({
        userLocale: null,
        currentLanguage: null,
        currentCountry: null,
      })
    );
    localStorage.removeItem("MAGY.LOCAL");
    localStorage.removeItem("i18nextLng");
    localStorage.removeItem("persist:root");

    setTimeout(() => {
      goBackToLogin();
    }, 500);
  };

  const goHome = () => {
    history.push({ pathname: "/end-of-enrolment" });
  };

  const handlePrint = () => {
    window.print();
  };

  const setUserDetails = () => {
    setSiteId(loggedInUser.siteId);
    setUserFullName(`${loggedInUser.firstName} ${loggedInUser.lastName}`);
    setUserInitials(`${loggedInUser.firstName[0]}${loggedInUser.lastName[0]}`);
  };

  const displayParticipantId = () => {
    if (participant_id) {
      return participant_id;
    } else {
      return "";
    }
  };

  useEffect(() => {
    try {
      if (userLocale !== undefined && userLocale !== null) {
        i18n.changeLanguage(userLocale);
        const _countryCode = userLocale.split("_")[1];
        const _languages = LanguageList.filter(
          (lang) => lang.countryCode === _countryCode
        );
        setLanguages(_languages);
      }
    } catch (ex) {
      goBackToLogin();
    }
  }, [userLocale]);

  useEffect(() => {
    if (loggedInUser !== null && loggedInUser !== undefined) {
      Auth.currentSession()
        .then(() => {
          setUserDetails();
        })
        .catch(() => {
          goBackToLogin();
        });
    }
  }, [loggedInUser]);

  return (
    <>
      <AppBar
        component="nav"
        sx={{ background: "white" }}
        className={styles.appBarNoPrint}
      >
        <header className={styles.appHeader}>
          <Grid container>
            <Grid item xs={3}>
              {isGuestMode === false && (
                <div className={styles.menuContainer}>
                  <IconButton
                    onClick={handleClickOpen}
                    color="primary"
                    aria-label="Home"
                    component="span"
                  >
                    <MenuIcon
                      fontSize="large"
                      style={{ color: "var(--primary-color)" }}
                    />
                  </IconButton>
                </div>
              )}
            </Grid>
            <Grid item xs={6}>
              <div className={styles.iaviLogoContainer}>
                {heading === "" ? (
                  <img
                    className={styles.iaviLogo}
                    src={MagyLogo}
                    alt={
                      "IAVI - Tramslating science into global health impact."
                    }
                  />
                ) : (
                  <div className={styles.participantId}>
                    <div>
                      <h1
                        style={{
                          marginTop: participant_id !== null ? "15px" : "28px",
                        }}
                      >
                        {appTitle === "New Consent"
                          ? t("new_enrolment")
                          : appTitle}
                      </h1>
                    </div>
                    {participant_id !== null && (
                      <div>
                        <Link
                          onClick={handleQRClickOpen}
                          style={{ color: "var(--primary-color)" }}
                        >
                          {t("participant_id")}: {displayParticipantId()}
                        </Link>{" "}
                        {enrolment_id !== null && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp; {t("consent_id")}:{" "}
                            {enrolment_id}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={3}>
              {isGuestMode === false && (
                <div className={styles.profileAvatarContainer}>
                  <Tooltip title="Account settings">
                    <IconButton
                      className={styles.profileButton}
                      onClick={handleProfileMenuClick}
                      size="small"
                      sx={{ ml: 2 }}
                    >
                      <Avatar
                        className={styles.profileAvatar}
                        sx={{ width: 32, height: 32 }}
                      >
                        {userInitials}
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={profileMenu}
                    open={openProfileMenu}
                    onClose={handleProfileMenuClose}
                    onClick={handleProfileMenuClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem>
                      <Avatar />
                      {userFullName} ({siteId})
                    </MenuItem>
                    <Divider />
                    <MenuItem>
                      <ListItemIcon>
                        {isDeviceOnline ? (
                          <WifiIcon color="success" fontSize="small" />
                        ) : (
                          <WifiOffIcon color="error" fontSize="small" />
                        )}
                      </ListItemIcon>
                      Device Online
                    </MenuItem>
                    {/* <Divider />
                    <MenuItem onClick={logout}>
                      <ListItemIcon>
                        <TranslateIcon fontSize="small" />
                      </ListItemIcon>
                      English 
                    </MenuItem> */}
                    <Divider />
                    <MenuItem onClick={logout}>
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      {t("logout")}
                    </MenuItem>
                  </Menu>
                </div>
              )}

              <div className={styles.profileAvatarContainer}>
                {isPrintPreviewMode && (
                  <Button
                    variant="contained"
                    className={styles.langButton}
                    onClick={handlePrint}
                    size="small"
                    sx={{ ml: 2 }}
                    color="primary"
                  >
                    <LocalPrintshopOutlinedIcon />
                    <span style={{ marginLeft: "8px", marginRight: "8px" }}>
                      {t("label_print")}
                    </span>
                  </Button>
                )}
                {/* <div className={styles.showOnlineStatus}>
                  {isDeviceOnline ? (
                    <WifiIcon color="success" />
                  ) : (
                    <WifiOffIcon color="error" />
                  )}
                </div> */}
                {userLocale !== undefined && userLocale !== null && (
                  <Tooltip title={t("change_language")}>
                    <Button
                      variant="outlined"
                      className={styles.langButton}
                      onClick={handleLangMenuClick}
                      size="small"
                      sx={{ ml: 2 }}
                      color="primary"
                    >
                      <TranslateIcon />
                      <span style={{ marginLeft: "8px", marginRight: "8px" }}>
                        {t(i18n.language)}
                      </span>
                    </Button>
                  </Tooltip>
                )}

                <Menu
                  anchorEl={langMenu}
                  open={openLangMenu}
                  onClose={handleLangMenuClose}
                  onClick={handleLangMenuClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  {languages.map((lng) => (
                    <MenuItem
                      key={`lang_${lng.languageCode}`}
                      onClick={() => i18n.changeLanguage(lng.languageCode)}
                    >
                      {lng.languageName}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </Grid>
          </Grid>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("magy_econsent_title")}
            </DialogTitle>
            <DialogContent>{t("ending_consent_procedure")}</DialogContent>
            <DialogActions>
              <center>
                <Button
                  variant="contained"
                  sx={{
                    mr: 1,
                  }}
                  onClick={goHome}
                >
                  {t("label_yes")}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    mr: 1,
                  }}
                  onClick={handleClose}
                  autoFocus
                >
                  {t("label_no")}
                </Button>
              </center>
            </DialogActions>
          </Dialog>
        </header>
        <HandleSaveEnrolment />
      </AppBar>
      <AppBar
        component="nav"
        sx={{ background: "white" }}
        className={styles.appBarPrint}
      >
        <div style={{ margin: "0", textAlign: "center" }}>
          <div className={styles.participantId}>
            <div>
              <h1
                style={{
                  marginTop: participant_id !== null ? "15px" : "28px",
                }}
              >
                {appTitle}
              </h1>
            </div>
            {participant_id !== null && (
              <div>
                {t("participant_id")}: {displayParticipantId()}{" "}
                &nbsp;&nbsp;&nbsp;&nbsp; {t("consent_id")}: {enrolment_id}
              </div>
            )}
          </div>
        </div>
      </AppBar>
      <Dialog
        open={qrOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleQRClose}
        maxWidth={"md"}
      >
        <DialogTitle>QR Code: Participant ID</DialogTitle>
        <DialogContent>
          <div className={styles.AppFooterContent}>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <QRCodeSVG value={displayParticipantId()} />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ textAlign: "center", width: "100%" }}>
            <Button variant="contained" onClick={handleQRClose}>
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

AppHeader.propTypes = {
  isGuestMode: PropTypes.bool.isRequired,
  heading: PropTypes.string,
  isPrintPreviewMode: PropTypes.bool,
};

export default AppHeader;
