import React, { useEffect, useState, useMemo } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import styles from "./ManageOfflineData.module.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import PreLoader from "../../components/common/PreLoader";
import { useDispatch } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/material";
import { setAppTitle } from "../../store/slices/settingsSlice";
import ProgressBar from "../../components/common/ProgressBar";
import { useSelector } from "react-redux";
import {
  saveEnrolmentGuardianData,
  saveEnrolmentParticipantData,
  saveParticipantUserData,
  saveEnrolmentStatus,
} from "../../services/enrolment.service";
import { apiCreateEnrolment } from "../../services/api/enrolmentApi.service";
import { apiCreateParticipant } from "../../services/api/participantApi.service";
import { setOfflineEnrolments } from "../../store/slices/offlineDataSlice";

const ManageOfflineData = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [Loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [syncInProgress, setSyncInProgress] = useState(false);
  const [progressValue, setProgressValue] = useState(1);
  const { isDeviceOnline, loggedInUser } = useSelector(
    (state) => state.settings
  );
  const { offlineEnrolments } = useSelector((state) => state.offlineData);
  const [rows, setRows] = useState([]);
  const columns = [
    { field: "id", headerName: "ID", width: 50, hide: true },
    {
      field: "enrolment_date",
      headerName: "Enrolment Date",
      sortable: false,
      width: 130,
    },
    { field: "age_cat", headerName: "Age Cat", width: 150 },
    { field: "guardian", headerName: "Parent / Guardian", width: 160 },
    { field: "participant", headerName: "Participant", width: 160 },
    { field: "record_status", headerName: "Status", width: 200 },
  ];

  useMemo(() => {
    if (loggedInUser === null) {
      history.push({ pathname: "/login" });
    }
  }, []);

  const showSnackBar = (data) => {
    enqueueSnackbar(data.message, {
      variant: data.variant,
    });
  };

  const _showSuccessMessage = (success) => {
    showSnackBar({
      message: success
        ? "Enrolments synchronised successfully."
        : "Some enrolments failed to synchronise. Please try again.",
      variant: "success",
    });
  };

  const createNewEnrolment = async (participantId) => {
    const results = await apiCreateEnrolment(participantId);
    if (results.success) {
      const enrolementId = results.body;
      return enrolementId;
    } else {
      return null;
    }
  };

  const createNewParticipant = async (siteId) => {
    const results = await apiCreateParticipant(siteId);

    if (results.success) {
      const participantId = results.body;
      return participantId;
    } else {
      return null;
    }
  };

  const saveEnrolment = async (
    enrolmentState,
    settingsState,
    guardianResults,
    participantResults
  ) => {
    let _enrolmentState = JSON.parse(JSON.stringify(enrolmentState));
    setSyncInProgress(true);

    const _pageType =
      settingsState.currentFormIndex === 0
        ? settingsState.enrolmentForms[settingsState.currentFormIndex].type
        : settingsState.enrolmentForms[settingsState.currentFormIndex - 1].type;

    setLoading(true);

    const _participantId = await createNewParticipant(
      settingsState.loggedInUser.siteId
    );
    const _enrolmentId = await createNewEnrolment(_participantId);

    _enrolmentState.participant_id = _participantId;
    _enrolmentState.enrolment_id = _enrolmentId;

    const participantUpdateResults = await saveParticipantUserData(
      _enrolmentState,
      settingsState,
      isDeviceOnline
    );

    const saveEnrolmentStatusSuccess = await saveEnrolmentStatus(
      _enrolmentState.participant_id,
      _enrolmentState.enrolment_id,
      settingsState.recordStatus
    );

    if (_pageType === "MINOR_PARENT" || _pageType === "MINOR_PARTICIPANT") {
      const updateGuardianSuccess = await saveEnrolmentGuardianData(
        _enrolmentState,
        settingsState,
        guardianResults,
        isDeviceOnline
      );
      const updateParticipantSuccess = await saveEnrolmentParticipantData(
        _enrolmentState,
        settingsState,
        participantResults,
        isDeviceOnline
      );
      setLoading(false);
      const result =
        updateGuardianSuccess &&
        updateParticipantSuccess &&
        participantUpdateResults &&
        saveEnrolmentStatusSuccess;

      return result;
    }

    if (_pageType === "EMANCIPATED_MINOR" || _pageType === "ADULT_18_PLUS") {
      const updateParticipantSuccess = await saveEnrolmentParticipantData(
        _enrolmentState,
        settingsState,
        participantResults,
        isDeviceOnline
      );
      setLoading(false);

      const result =
        updateParticipantSuccess &&
        participantUpdateResults &&
        saveEnrolmentStatusSuccess;
      _showSuccessMessage(result);

      return result;
    }
  };

  const syncData = async () => {
    const progressIncrementValue = 100 / offlineEnrolments.length;
    let totalProgress = progressIncrementValue;
    let failedRecords = [];

    for (const data of offlineEnrolments) {
      const result = await saveEnrolment(
        data.enrolment,
        data.settings,
        data.aouGuardianResults,
        data.aouParticipantResults
      );

      if (result === false) {
        failedRecords.push(data);
      }

      setProgressValue(totalProgress);
      totalProgress = totalProgress + progressIncrementValue;
    }
    dispatch(setOfflineEnrolments(failedRecords));
    _showSuccessMessage(failedRecords.length === 0);
  };

  useEffect(() => {
    if (!isDeviceOnline) {
      history.push("/home");
    }
    dispatch(setAppTitle("MANAGE OFFLINE DATA"));
  }, []);

  useEffect(() => {
    let _rows = [];

    const ageCats = [
      "",
      "Age 15 To 17",
      "Age 15 - 17 Emancipated Minor",
      "Age 18+",
    ];
    offlineEnrolments.forEach((data, index) => {
      _rows.push({
        id: index + 1,
        age_cat: ageCats[data.settings.ageCat],
        guardian:
          data.enrolment.consent_guardian_first_name !== null
            ? `${data.enrolment.consent_guardian_first_name} ${data.enrolment.consent_guardian_first_name}`
            : "-",
        participant:
          data.enrolment.consent_participant_first_name !== null
            ? `${data.enrolment.consent_participant_first_name} ${data.enrolment.consent_participant_last_name}`
            : "-",
        enrolment_date: "23/02/2023",
        record_status: data.settings.recordStatus,
      });
    });

    setRows(_rows);
  }, [offlineEnrolments]);

  return (
    <>
      <AppHeader isGuestMode={false} heading={"MANAGE OFFLINE DATA"} />
      <Container maxWidth="md">
        <div className={styles.pageBoxBody}>
          <Box sx={{ mt: 1 }}>
            <center>
              <Button
                variant="contained"
                disabled={syncInProgress}
                onClick={syncData}
              >
                Synchronise Offline Data ({offlineEnrolments.length})
              </Button>
            </center>
          </Box>
          <ProgressBar value={progressValue} />
        </div>
        <div style={{ height: 600, width: "100%", marginTop: "20px" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
          />
        </div>
      </Container>

      <PreLoader show={Loading} />
    </>
  );
};

export default ManageOfflineData;
