export const ilFormsData = [
  {
    id: 1,
    type: "MINOR_PARENT",
    name: "MINOR_PARENT_CONSENT",
    formName: "IL_parent_consent_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 2,
    type: "MINOR_PARTICIPANT",
    name: "MINOR_PARTICIPANT_CONSENT",
    formName: "IL_assent_form_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 3,
    type: "EMANCIPATED_MINOR",
    name: "EMANCIPATED_MINOR_CONSENT",
    formName: "IL_emancipated_minor_consent_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 4,
    type: "ADULT_18_PLUS",
    name: "ADULT_CONSENT",
    formName: "IL_adult_consent_title",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
];
