import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import south_africa_en from "./translations/en_ZA/common.json";
import south_africa_tn from "./translations/tn_ZA/common.json";
import south_africa_xh from "./translations/xh_ZA/common.json";
import zambia_bem from "./translations/bem_ZM/common.json";
import zambia_ny from "./translations/ny_ZM/common.json";
import zambia_en from "./translations/en_ZM/common.json";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  //   .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en_ZM",
    debug: true,
    lng: "en_ZA",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en_ZA: {
        common: south_africa_en, 
      },
      tn_ZA: {
        common: south_africa_tn, 
      },
      xh_ZA: {
        common: south_africa_xh, 
      },
      en_ZM: {
        common: zambia_en,
      },
      bem_ZM: {
        common: zambia_bem,
      },
      ny_ZM: {
        common: zambia_ny,
      },
    },
  });

export default i18n;
