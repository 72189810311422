/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";

const settingsInitialData = {
  ageCat: null,
  parentIlliterate: null,
  participantIlliterate: null,
  enrolmentForms: null,
  currentFormIndex: null,
  appTitle: "",
  participantType: null,
  isDeviceOnline: true,
  triggerEvent: null,
  recordStatus: null,
  loggedInUser: null,
  userLocale: null,
  currentLanguage: null,
  currentCountry: null,
  isReconsent: false,
  studyName: null,
  ageCatsList: [],
  auditInfo: [],
  participantConsents: [],
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: { ...settingsInitialData },
  reducers: {
    setAgeCat: (state, action) => {
      state.ageCat = action.payload;
    },
    setAgeCatsList: (state, action) => {
      state.ageCatsList = action.payload;
    },
    setStudyName: (state, action) => {
      state.studyName = action.payload;
    },
    setParentIlliterate: (state, action) => {
      state.parentIlliterate = action.payload;
    },
    setParticipantIlliterate: (state, action) => {
      state.participantIlliterate = action.payload;
    },
    setEnrolmentForms: (state, action) => {
      state.enrolmentForms = action.payload;
    },
    setCurrentFormIndex: (state, action) => {
      state.currentFormIndex = action.payload;
    },
    setAppTitle: (state, action) => {
      state.appTitle = action.payload;
    },
    setParticipantType: (state, action) => {
      state.participantType = action.payload;
    },
    setIsDeviceOnline: (state, action) => {
      state.isDeviceOnline = action.payload;
    },
    resetSettings: (state) => {
      const newState = { ...settingsInitialData };
      state.ageCat = newState.ageCat;
      state.parentIlliterate = newState.parentIlliterate;
      state.participantIlliterate = newState.participantIlliterate;
      state.enrolmentForms = newState.enrolmentForms;
      state.currentFormIndex = newState.currentFormIndex;
      state.appTitle = newState.appTitle;
      state.recordStatus = newState.recordStatus;
      state.isReconsent = newState.isReconsent;
      state.studyName = null;
      state.auditInfo = [];
    },
    setTriggerEvent: (state, action) => {
      state.triggerEvent = action.payload;
    },
    setRecordStatus: (state, action) => {
      state.recordStatus = action.payload;
    },
    setLoggedInUser: (state, action) => {
      state.loggedInUser = action.payload;
    },
    setUserLocale: (state, action) => {
      state.userLocale = action.payload.userLocale;
      state.currentLanguage = action.payload.currentLanguage;
      state.currentCountry = action.payload.currentCountry;
    },
    setIsReconsent: (state, action) => {
      state.isReconsent = action.payload;
    },
    setAuditInfo: (state, action) => {
      state.auditInfo = action.payload;
    },
    setParticipantConsents: (state, action) => {
      state.participantConsents = action.payload;
    },
  },
});

export const {
  setAgeCat,
  setStudyName,
  setParentIlliterate,
  setParticipantIlliterate,
  setEnrolmentForms,
  setCurrentFormIndex,
  setAppTitle,
  resetSettings,
  setParticipantType,
  setIsDeviceOnline,
  setTriggerEvent,
  setRecordStatus,
  setLoggedInUser,
  setUserLocale,
  setIsReconsent,
  setAgeCatsList,
  setAuditInfo,
  setParticipantConsents,
} = settingsSlice.actions;

export default settingsSlice.reducer;
